import instance, { AUTH_API_URL } from './instance';
import {
  Auction,
  Bid,
  BidCommonInfo,
  BidStatusEnum,
  MyBidsResponse,
  MyWonAuctionsResponse,
} from '../models/Auction';
import { ResponsePagination } from '../models/Response';

export function getAuctions(
  status?: BidStatusEnum,
  page?: Number,
  my_auctions?: boolean,
  page_size?: Number
): Promise<ResponsePagination<Auction>> {
  return instance()
    .get(`${AUTH_API_URL}/public/auction/v2/auctions/`, {
      params: { status, page, my_auctions, page_size },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getAuctionsV3(page?: Number): Promise<ResponsePagination<Auction>> {
  return instance()
    .get(`${AUTH_API_URL}/public/auction/v3/auctions/`, {
      params: { page },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getMyAuctionsV3(
  page?: Number
): Promise<ResponsePagination<Auction>> {
  return instance()
    .get(`${AUTH_API_URL}/private/auction/v3/my-auctions/`, {
      params: { page },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getMyBids(): Promise<MyBidsResponse> {
  return instance()
    .get(`${AUTH_API_URL}/private/auction/v3/my-bids/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getBidsCommonInfo(): Promise<Record<string, BidCommonInfo>> {
  return instance()
    .get(`${AUTH_API_URL}/public/auction/v3/bids-common-info/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getAuctionById(id: number): Promise<Auction> {
  return instance()
    .get(`${AUTH_API_URL}/public/auction/v3/auctions/${id}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getIsRewardClaimed(id: number): Promise<boolean> {
  return instance()
    .get(`${AUTH_API_URL}/private/auction/v3/is-reward-claimed/${id}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getBidsV3(auctionId: number, page: number = 1): Promise<Bid[]> {
  return instance()
    .get(`${AUTH_API_URL}/public/auction/v3/bids/${auctionId}/`, {
      params: { page },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function placeBid(id: number, amount: string): Promise<void> {
  return instance()
    .post(`${AUTH_API_URL}/private/auction/v3/place-bid/`, {
      bid_amount: amount,
      auction: id,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function winnerClaimCreate(
  id: number,
  email: string,
  phone_number: string
): Promise<void> {
  return instance()
    .post(`${AUTH_API_URL}/private/auction/v3/claim-prize/`, {
      email,
      phone_number,
      auction: id,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}
