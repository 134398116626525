import React from 'react';
import { CoursePreviewVideo } from './course-preview-video';
import { CoursePreviewDescription } from './course-preview-description';

export const CoursePreviewContent: React.FC = () => {
  return (
    <div className="flex flex-col gap-2 lg:gap-5">
      <CoursePreviewVideo />
      <CoursePreviewDescription className="lg:rounded-[30px] lg:px-10 lg:py-6 lg:shadow-md lg:shadow-shadow-700" />
    </div>
  );
};
