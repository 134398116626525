import React from 'react';
import { CoursePreviewContent } from './course-preview-content/course-preview-content';
import { CoursePreviewDitail } from './course-preview-detail';

interface Props {
  isDetaile?: boolean;
}

export const CoursePreviewBody: React.FC<Props> = ({ isDetaile }) => {
  return !isDetaile ? <CoursePreviewContent /> : <CoursePreviewDitail />;
};
