import { StarRating } from '../../StarRating';
import { ProgressBar } from '../../../horizon-components/ProgressBar';
import { useTranslation } from 'react-i18next';
import { TRatingsBreakdown } from '../../../models/Review';
import { useEffect, useState } from 'react';
import './CourseRating.scss';

type TRatings = TRatingsBreakdown & {
  total: number;
};

interface CourseRatingProps {
  totalRating?: number;
  ratingsBreakdown?: TRatingsBreakdown[];
  showFull?: boolean;
}

const CourseRating = ({
  totalRating = 0,
  ratingsBreakdown,
  showFull = true,
}: CourseRatingProps) => {
  const { t } = useTranslation(['translation']);
  const [data, setData] = useState<TRatings[]>([
    { rating: 5, count: 0, total: 0 },
    { rating: 4, count: 0, total: 0 },
    { rating: 3, count: 0, total: 0 },
    { rating: 2, count: 0, total: 0 },
    { rating: 1, count: 0, total: 0 },
  ]);

  useEffect(() => {
    !!ratingsBreakdown?.length && getTotal();
  }, [ratingsBreakdown]);

  const getTotal = () => {
    let totalVotes = 0;

    let newData: TRatings[] =
      ratingsBreakdown
        ?.sort((rating) => -rating.rating)
        ?.map((rate) => {
          return { ...rate, total: 0 };
        }) || [];

    // Находим общее количество проголосовавших
    newData?.forEach((item) => {
      totalVotes += item.count;
    });

    // Вычисляем процент голосов от общего числа и добавляем его в каждый элемент массива
    newData?.forEach((item) => {
      item.total = Math.round((item.count / totalVotes) * 100) || 0;
    });

    setData(newData.reverse());
  };

  return (
    <div className={'CourseRating flex-col lg:flex-row'}>
      <div className={'CourseRating__total !flex !flex-row lg:!hidden'}>
        <div>{totalRating}</div>
        <div
          className={
            'flex flex-col !items-end !justify-end self-center md-max:gap-1'
          }
        >
          <StarRating rating={totalRating} />
          <span className="text-nowrap">{t('course.courseRating')}</span>
        </div>
      </div>
      <div className="CourseRating__total !hidden lg:!flex">
        {totalRating}
        <StarRating rating={totalRating} />
        <span className="text-nowrap">{t('course.courseRating')}</span>
      </div>
      {showFull && (
        <div className={'flex w-full gap-x-4'}>
          <div className="CourseRating__bar">
            {data.map((rating) => (
              <ProgressBar key={rating.rating} rating={rating.total} size="md" />
            ))}
          </div>
          <div className="CourseRating__stars">
            {data.map((rating, idx) => (
              <div className="CourseRating__stars-star" key={rating.rating}>
                <StarRating percent={idx + 1} /> <span>{rating.total}%</span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CourseRating;
