import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Bread, Breadcrumbs } from '../../../../components/Breadcrumbs';
import {
  AuctionRoutes,
  MainRoutes,
} from '../../../../horizon-layout/MainLayout/Routes/types/routes';
import { BidStatusEnum } from '../../../../models/Auction';
import { useTitleBackContext } from '../../../../providers';
import { useAuctionContext } from '../../../../providers/AuctionProvider/AuctionProvider';

export const AuctionViewBreadcrumbs: React.FC = () => {
  const { t } = useTranslation(['translation']);
  const { id } = useParams();
  const { auction, isAuctionLoading, auctionStatus } = useAuctionContext();
  const { useTitleBack } = useTitleBackContext();

  useTitleBack(
    !isAuctionLoading ? auction?.title : undefined,
    MainRoutes.bid + AuctionRoutes.actual
  );

  const breads: Bread[] = useMemo(
    () => [
      { title: t('bid.Bid'), url: MainRoutes.bid + AuctionRoutes.actual },
      {
        title:
          auctionStatus === BidStatusEnum.COMPLETED && auction?.number_winners
            ? t('menu.Winnings')
            : t('menu.Actual'),
        url:
          MainRoutes.bid +
          (auctionStatus === BidStatusEnum.COMPLETED && auction?.number_winners
            ? AuctionRoutes.winnings
            : AuctionRoutes.actual),
      },
      {
        title: t('bid.Auction'),
        url: MainRoutes.bid + AuctionRoutes.auctionView + `/${id}`,
      },
    ],
    [id, t, auction]
  );

  return <Breadcrumbs breads={breads} />;
};
