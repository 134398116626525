import { TaskValidator, ValidationException } from './base';
import { TTaskType } from '../../../../models/Tasks';
import getPlatform from '../../../../utils/getPlatform';
import { PlatformsEnum } from '../../../../entities/Platforms/platforms';
import i18n from '../../../../i18n';

export class DownloadMobileAppValidator extends TaskValidator {
  validate(task: TTaskType): void {
    const platform = getPlatform();
    if ([PlatformsEnum.IOS, PlatformsEnum.ANDROID].includes(platform)) {
      return;
    }
    throw new ValidationException(i18n.t('You should claim reward from mobile app'));
  }
}
