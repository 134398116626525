import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getMyAuctionsV3 } from '../../../api/bid';
import { BidTabs } from '../../../widgets/BidTabs';
import useFetch from '../../../hooks/useFetch';
import { Auction, BidStatusEnum } from '../../../models/Auction';
import useInfiniteScroll from '../../../hooks/useInfiniteScroll';
import { DEFAULT_LIMIT } from '../../../shared/lib/const/limit';
import { useBidServiceContext } from '../../../providers/BidServiceProvider/BidServiceProvider';
import { AuctionCard } from '../AuctionCard/AuctionCard';
import { Loader } from '../../../components/Loader';

export const BidWinnings: React.FC = () => {
  const { t } = useTranslation(['translation']);
  const { myBidsData } = useBidServiceContext();
  const [page, setPage] = useState(1);
  const [auctions, setAuctions] = useState<Auction[]>([]);
  const targetRef = useRef<HTMLDivElement>(null);
  const hasFetched = useRef(false);
  const {
    data,
    isLoading: isLoadingAuctions,
    fetchData: fetchAuctions,
  } = useFetch(getMyAuctionsV3);

  const loadComments = useCallback(() => {
    if (data?.count && data?.count > page) {
      fetchAuctions(page + 1).then((res) => {
        setAuctions([...(auctions || []), ...(res?.results || [])]);
        res?.results.length === DEFAULT_LIMIT && setPage(page + 1);
      });
    }
  }, [auctions, data?.count, fetchAuctions, page]);

  useInfiniteScroll(targetRef, loadComments);

  useEffect(() => {
    hasFetched.current = false;
    fetchAuctions(page).then((res) =>
      setAuctions([...(auctions || []), ...(res?.results || [])])
    );
  }, [t]);

  return (
    <div className={'px-[8px] lg:px-0'}>
      <h1 className={'g-main-title mb-4 block lg:mb-6 lg-max:hidden'}>
        {t('menu.Winnings')}
      </h1>
      <BidTabs />
      {!!auctions?.length && (
        <div className={'grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3'}>
          {auctions
            ?.filter((item) => Object.keys(myBidsData).includes(`${item?.id}`))
            .map((item) => (
              <AuctionCard
                key={item.id}
                data={item}
                status={BidStatusEnum.COMPLETED}
              />
            ))}
        </div>
      )}
      {auctions?.length < (data?.count || 1) && (
        <>
          <div ref={targetRef} className="mt-3" />
          {isLoadingAuctions && <Loader />}
        </>
      )}
    </div>
  );
};
