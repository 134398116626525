function getErrorMessages(errorData: any): string {
  console.log('errorData: ' , errorData);
  if (typeof errorData === 'string') {
    return errorData.replace(/<[^>]*>/g, '');
  } else if (Array.isArray(errorData)) {
    return errorData.map(getErrorMessages).join('\n');
  } else if (typeof errorData === 'object') {
    const values = Object.values(errorData);
    if (values.length > 0) {
      return getErrorMessages(values);
    }
  } else if (typeof errorData === 'undefined'){
    console.error('errorData undefined')
  }
  
  return 'An error occurred while processing the request.';
}

export default getErrorMessages;
