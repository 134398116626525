import instance, { AUTH_API_URL } from './instance';
import { ResponsePagination } from '../models/Response';
import { StatusTaskEnum, TaskType, TCondition, TTaskType } from '../models/Tasks';

export function getPrivateTasks(data: {
  execution_frequency?: string;
  task_type?: TaskType;
  status_task?: StatusTaskEnum;
  ordering?: string;
  page?: number;
}): Promise<ResponsePagination<TTaskType>> {
  const { execution_frequency, ordering, page, status_task, task_type } = data;
  return instance()
    .get(`${AUTH_API_URL}/private/tasks/`, {
      params: { execution_frequency, task_type, status_task, ordering, page },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function setPrivateStartTask(
  task_id: number
): Promise<{ error?: string; status?: string }> {
  return instance()
    .post(`${AUTH_API_URL}/private/start-task/${task_id}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function setPrivateClaimedTaskReward(
  task_id: number
): Promise<{ error?: string; status?: string }> {
  return instance()
    .post(`${AUTH_API_URL}/private/claimed/task-reward/${task_id}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getPrivateRetrieveTaskCondition(
  task_id: number
): Promise<TCondition> {
  return instance()
    .get(`${AUTH_API_URL}/private/retrieve/task-condition/${task_id}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}
