import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  getPrivateEnrolledCoursesV3,
  getPrivateFavouriteCoursesV3,
  getPublicCoursesV3,
} from '../../../api/courseV3';
import { Loader } from '../../../components/Loader';
import { NotFound } from '../../../components/NotFound/NotFound';
import useFetch from '../../../hooks/useFetch';
import { Pagination } from '../../../horizon-components/Pagination';
import { CourseCardStatus } from '../../../models/Course';
import { useUserContext } from '../../../providers';
import { CourseCard } from '../../../widgets/CourseCard';
import CoursesLdJson from '../Main/CoursesLdJson';

type Props = {
  selectedUnits?: number[];
  selectedDPId?: number;
  selectedLevel?: string;
  tags?: string[];
};

export const IncludedCourses: React.FC<Props> = ({
  selectedUnits,
  selectedDPId,
  selectedLevel,
  tags,
}) => {
  const { id } = useParams();
  const { t } = useTranslation(['translation']);
  const { user } = useUserContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = searchParams.get('page') || 1;

  const { data, isLoading, fetchData: fetchCourses } = useFetch(getPublicCoursesV3);
  const { data: enrolled, fetchData: fetchEnrolled } = useFetch(
    getPrivateEnrolledCoursesV3
  );
  const { data: favorites, fetchData: fetchFavourite } = useFetch(
    getPrivateFavouriteCoursesV3
  );

  useEffect(() => {
    if (user?.guid) {
      fetchEnrolled();
      fetchFavourite();
    }
  }, [t]);

  useEffect(() => {
    fetchCourses({
      digital_pass: Number(selectedDPId) || undefined,
      digital_pass_category: Number(id),
      subject_level: Number(selectedLevel) || undefined,
      subject_level_units: selectedUnits,
      page: Number(pageParam),
      tags_or_title: tags,
    });
  }, [id, pageParam, selectedDPId, selectedLevel, selectedUnits, tags, t]);

  const handlePageChange = (newPage: number) => {
    setSearchParams({ page: newPage.toString() });
  };

  return (
    <>
      {data?.results.length && <CoursesLdJson items={data.results} />}
      <div className="flex w-full flex-col gap-[20px]">
        <h4
          className={
            'self-stretch text-base font-semibold text-secondary-grey-800 md:text-2xl md:font-bold'
          }
        >
          {data?.count} {t('course.includedCourses')}
        </h4>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {!!data?.results.length ? (
              <div
                className={
                  'grid grid-cols-1 gap-x-6 gap-y-5 md:grid-cols-3 lg:grid-cols-4'
                }
              >
                {data?.results.map((course) => (
                  <CourseCard
                    course={course}
                    status={
                      !!enrolled?.find((enroll) => enroll === course.id)
                        ? CourseCardStatus.ENROLLED
                        : CourseCardStatus.CAN_ENROLL
                    }
                    favorite={!!favorites?.includes(course?.id)}
                    onFavorite={fetchFavourite}
                    key={course.id}
                  />
                ))}
              </div>
            ) : (
              <NotFound text={t('course.Coming_soon')} />
            )}
            {!!data?.count && (
              <div className={'flex w-full items-center justify-center'}>
                <Pagination
                  totalPages={Math.ceil(data?.count / 12)}
                  currentPage={Number(pageParam)}
                  showPrevNext={true}
                  onPageChange={handlePageChange}
                />
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};
