import { FC, useState } from 'react';
import ChevronIcon from '../../../assets/icons/chevron.right.purple.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setIsOpenSubjectModal } from '../../../stores/subject';
import { RootState } from '../../../stores';
import { useTranslation } from 'react-i18next';
import { useUserContext } from '../../../providers';
import { saveCurrentUrl } from '../../../api/sessions';
import { useNavigate } from '../../../hooks/useNavigate';
import {
  AuthRoutes,
  MainRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';
import { Loader } from '../../../components/Loader';

const SubjectSwitcher: FC = () => {
  const { i18n } = useTranslation(['translation']);
  const language = i18n.language;
  const dispatch = useDispatch();
  const currentSubjectId = useSelector(
    (state: RootState) => state.start.currentSubjectId
  );
  const { user } = useUserContext();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const subjects = useSelector((state: RootState) => state.start.subjects);
  const subjectData = subjects?.[language]?.find(
    (data) => data.id === currentSubjectId
  );

  const handleClick = () => {
    if (!subjectData) {
      return;
    }
    if (user?.guid === undefined) {
      if (!user) {
        saveCurrentUrl(window.location.pathname);
        navigate(MainRoutes.auth + AuthRoutes.signin);
        return;
      }
    }
    dispatch(setIsOpenSubjectModal(true));
  };
  return (
    <div
      onClick={handleClick}
      className="mr-2 flex h-[60px] items-center gap-[8px] rounded-[40px] bg-[#E9E3FF] pl-[4px] pr-[16px]"
    >
      <div className="flex h-[52px] w-[52px] items-center rounded-full bg-white">
        {isLoading && <Loader className="!h-5 !w-5" />}{' '}
        <img
          className="h-[52px] w-[52px] rounded-full"
          src={subjectData?.image}
          alt="subject category"
          onLoad={() => setIsLoading(false)}
          onError={() => setIsLoading(false)}
          style={{ display: isLoading ? 'none' : 'block' }}
        />
      </div>

      <p className=" font-semibold text-purple-500">
        {subjectData?.title || <Loader className="!h-5 !w-5" />}
      </p>
      <img
        src={ChevronIcon}
        alt="Loading"
        className="h-[20px] w-[20px] rotate-90 transform"
      />
    </div>
  );
};

export default SubjectSwitcher;
