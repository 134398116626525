import React, { useEffect, useState } from 'react';
import { CoursePerfomanceHeader } from '../CoursePerfomance/CoursePerfomanceHeader/CoursePerfomanceHeader';
import { Course, Rejection } from '../../../models/Course';
import { getCourse, getCourseRejectComment } from '../../../api/course';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

export const CourseRejected: React.FC = () => {
  const { id: courseId } = useParams();
  const [course, setCourse] = useState<Course | null>(null);
  const [rejection, setRejection] = useState<Rejection[]>([]);
  const { t } = useTranslation(['translation']);

  useEffect(() => {
    getCourse(courseId || '').then((data) => {
      setCourse(data);
    });
    getCourseRejectComment(courseId || '').then((data) => {
      setRejection(data.rejections);
    });
  }, [courseId]);

  return (
    <div className="flex w-full flex-col gap-[8px] md:gap-6">
      <CoursePerfomanceHeader course={course} />
      <div
        className={
          'flex flex-col items-start gap-2 self-stretch rounded-[20px] bg-white p-2 shadow-md shadow-shadow-700 md:gap-3 md:rounded-[30px] md:p-6'
        }
      >
        <div>
          <p className={'text-base font-semibold text-purple-500 lg:text-xl'}>
            {t('course.unverified')}
          </p>
          <p
            className={'text-xs font-medium text-secondary-dark-grey-700 lg:text-sm'}
          >
            {t('course.unverifiedDesc')}
          </p>
        </div>
        {rejection.map((data) => (
          <div
            key={data.id}
            className={
              'flex flex-col items-start gap-1 self-stretch rounded-xl bg-secondary-grey-200 p-2 md:rounded-2xl md:p-3'
            }
          >
            <div className={'flex w-full items-end justify-between'}>
              <h4
                className={
                  'flex-[1_0_0] overflow-hidden overflow-ellipsis text-sm font-semibold text-secondary-grey-900 md:text-lg'
                }
              >
                {t('course.Recommendation')}
              </h4>
              <p
                className={
                  'overflow-hidden overflow-ellipsis text-xs font-medium text-secondary-dark-grey-500 md:text-base'
                }
              >
                {dayjs(data.created_at).format('DD.MM.YY')}
              </p>
            </div>
            <div
              className={
                'self-stretch text-sm tracking-[0px] text-secondary-grey-900 md:text-lg'
              }
              dangerouslySetInnerHTML={{ __html: data.text }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
