import { useParams } from 'react-router-dom';
import { AuctionProvider } from '../../../providers/AuctionProvider/AuctionProvider';
import { AuctionView } from './ui/auction-view';
import { useNavigate } from '../../../hooks/useNavigate';
import {
  AuctionRoutes,
  MainRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';
import { useBidServiceContext } from '../../../providers/BidServiceProvider/BidServiceProvider';
import { useTranslation } from 'react-i18next';
import { useTitleBackContext } from '../../../providers';
import { AuctionViewBidsAll } from './ui/auction-view-bids-all';

export const AuctionViewPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { getBidsPagesCount } = useBidServiceContext();

  if (!id) {
    navigate(MainRoutes.bid);
    return null;
  }

  const auctionId = parseInt(id);
  const pagesCount = getBidsPagesCount(auctionId);

  return (
    <AuctionProvider auctionId={auctionId} bidsPagesCount={pagesCount}>
      <AuctionView auctionId={auctionId} />
    </AuctionProvider>
  );
};

export const AuctionViewBidsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation(['translation']);
  const { useTitleBack } = useTitleBackContext();
  const { getBidsPagesCount } = useBidServiceContext();

  useTitleBack(
    t('bid.All_Bids'),
    MainRoutes.bid + AuctionRoutes.auctionView + `/${id}`
  );

  if (!id) {
    navigate(MainRoutes.bid);
    return null;
  }

  const auctionId = parseInt(id);
  const pagesCount = getBidsPagesCount(auctionId);

  return (
    <AuctionProvider auctionId={auctionId} bidsPagesCount={pagesCount}>
      <AuctionViewBidsAll fullHeight={true} />
    </AuctionProvider>
  );
};
