import { Stream } from '@cloudflare/stream-react';
import { useCourseCreation } from '../../../../../providers/course-provider/course-creation-provider';
import { Loader } from '../../../../../components/Loader';
import { MediaViewer } from '../../../../../components/MediaViewer/MediaViewer';

export const CoursePreviewVideo = () => {
  const { course } = useCourseCreation();
  const introductionFile = course?.introduction_files?.[0];
  const cfVideoGuid =
    course?.cf_video_stream?.cf_signed_video_guid ?? course?.cf_video_stream?.cf_uid;

  return (
    <>
      {cfVideoGuid ? (
        <div className="relative overflow-hidden">
          <Stream
            src={cfVideoGuid}
            className="stream z-10 !pt-[380px] md:!pt-[450px] lg:!pt-[530px]"
            width="100%"
            height="100%"
            controls
          />
          <Loader className="absolute top-[50%] z-0" />
        </div>
      ) : (
        <MediaViewer link={introductionFile?.file || ''} bg={course?.cover_image} />
      )}
    </>
  );
};
