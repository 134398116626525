import { FC, useEffect, useRef } from 'react';
import { useImagesContext } from '../../../../../providers/StartProvider/ImagesProvider';
import { movementTilesToDraw } from '../constants';
import BlockObject from '../../BlockObject';

type BgMainObjMidSchoolProps = {
  className?: string;
};

const BgMainObjMidSchool: FC<BgMainObjMidSchoolProps> = ({ className }) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const drawBackground = (ctx: CanvasRenderingContext2D) => {
    ctx.fillStyle = '#2D396B';
  };
  const { images, isLoaded } = useImagesContext();

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext('2d');

      if (ctx && isLoaded) {
        ctx.imageSmoothingEnabled = false;
        canvas.width = 960;
        canvas.height = 960;
        drawBackground(ctx);
        BlockObject({
          ctx,
          objects: movementTilesToDraw,
          offsetX: 0,
          offsetY: -124,
          images,
        });
        //drawObjects(ctx, movementTilesToDraw, images);
      }
    }
  }, [isLoaded, images]);

  return (
    <div className={className}>
      <canvas
        className="scale-100 overflow-hidden"
        ref={canvasRef}
        width={960}
        height={960}
      ></canvas>
    </div>
  );
};

export default BgMainObjMidSchool;
