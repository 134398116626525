import dayjs from 'dayjs';
import classNames from 'classnames';
import React, { memo, useEffect, useState } from 'react';
import { ReactComponent as StarFull } from '../../../assets/icons/rating/star-full.svg';
import { ReactComponent as StarEmpty } from '../../../assets/icons/rating/star-empty.svg';
import { MButton } from '../../../horizon-components/MButton/ui/MButton';
import { useTranslation } from 'react-i18next';
import { LikeCourseComment } from '../../../features/LikeCourseComment';
import { User } from '../../../models';
import { getUserData } from '../../../utils/getUserData';
import { NoAvatar } from '../../../widgets/NoAvatar';
import './CourseComment.scss';

interface CourseCommentProps {
  children: React.ReactNode;
  user: User;
  userReaction?: boolean;
  reviewLikes?: number;
  reviewRating?: number;
  reviewDate?: string;
  reviewId: number;
  mine?: boolean;
  showLike?: boolean;
}

export const CourseComment = memo(function (props: CourseCommentProps) {
  const {
    children,
    reviewLikes = 0,
    reviewRating = 0,
    user,
    userReaction = false,
    reviewDate,
    reviewId,
    mine,
    showLike = true,
  } = props;
  const { t } = useTranslation(['translation']);
  const [showFullText, setShowFullText] = useState(false);
  const [showBtn, setShowBtn] = useState(false);

  useEffect(() => {
    const show =
      children && children.toString().split(' ').length > 63 ? false : true;
    setShowFullText(show);
    setShowBtn(!show);
  }, [children]);

  const userData = getUserData(user);

  return (
    <div className={classNames('CourseComment', { mine })}>
      <div className="flex items-start justify-between gap-5">
        <div className="CourseComment__user">
          <div className="CourseComment__img">
            <NoAvatar src={userData.image} alt={userData.username} />
          </div>
          <div>
            <h4>{userData.username}</h4>
            <span>{dayjs(reviewDate).format('DD.MM.YYYY')}</span>
          </div>
        </div>
        <div className="CourseComment__rating">
          {reviewRating > 0 && <span>{reviewRating}</span>}{' '}
          {reviewRating ? (
            <StarFull className="h-5 w-5" />
          ) : (
            <StarEmpty className="h-5 w-5" />
          )}
        </div>
      </div>
      <div
        className={classNames('CourseComment__comment', { expanded: showFullText })}
      >
        {children}
      </div>
      <div className="flex justify-between gap-5">
        {showLike && (
          <LikeCourseComment
            reviewId={reviewId}
            likesCount={reviewLikes}
            userReaction={userReaction}
          />
        )}
        {showBtn && (
          <MButton
            variant="transparent"
            color="primary"
            className="px-0 py-0"
            onClick={() => setShowFullText(!showFullText)}
          >
            {t(showFullText ? 'btnsText.hide' : 'btnsText.showMore')}
          </MButton>
        )}
      </div>
    </div>
  );
});
