import classNames from 'classnames';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getPrivateGetCoursebyAccordingProgramV3 } from '../../../api/courseV3';
import { ReactComponent as PlayIcon } from '../../../assets/icons/play.svg';
import useFetch from '../../../hooks/useFetch';
import { useNavigate } from '../../../hooks/useNavigate';
import {
  AuthRoutes,
  EduRoutes,
  MainRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';
import { useUserContext } from '../../../providers';
import './style.scss';
import { toast } from 'react-toastify';
import { Loader } from '../../../components/Loader';

interface StartLearnProps {
  className?: string;
  subjectId?: number;
}

export const StartLearn = ({ className, subjectId }: StartLearnProps) => {
  const navigate = useNavigate();
  const {
    data,
    isLoading,
    fetchData: fetchCourse,
  } = useFetch((subject_id: number) =>
    getPrivateGetCoursebyAccordingProgramV3(subject_id)
  );
  const { t } = useTranslation(['translation']);
  const { user } = useUserContext();

  useEffect(() => {
    subjectId && fetchCourse(Number(subjectId));
  }, [subjectId, t]);

  const handleCourse = () => {
    if (isLoading) return;
    if (!user) {
      navigate(MainRoutes.auth + AuthRoutes.signin);
      return;
    }
    if (!data?.id) {
      toast(t('course.You_have_already_completed'), { type: 'info' });
      return;
    }
    navigate(MainRoutes.edu + EduRoutes.course + '/' + data?.id);
  };

  return (
    <div className={classNames('StartLearn', className)} onClick={handleCourse}>
      <div>{t('course.Start_the_training_program')}</div>
      {isLoading ? (
        <div>
          <Loader className="!h-7 !w-7" />
        </div>
      ) : (
        <PlayIcon />
      )}
    </div>
  );
};
