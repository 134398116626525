import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { checkEnrolledUserCourse } from '../../api/course';
import { getCourseReview } from '../../api/courseReview';
import {
  getPrivateCourseReviewLikedV3,
  getPrivateMyCourseReviewsV3,
  getPublicCourseReviewV3,
} from '../../api/courseReviewV3';
import useFetch from '../../hooks/useFetch';
import { TCourseReview, TRating, TReview } from '../../models/Review';
import { useUserContext } from '../UserProvider';

interface CourseReviewContextProps {
  report: TReview | null;
  othersReviews: any;
  isLoadingMine: boolean;
  isLoadingOthers: boolean;
  isEnrolled: boolean;
  refetchOthers: (params: TCourseReview) => void;
  checkEnroll: (courseId: number) => void;
  getRating: (courseId: number) => void;
  myComments: TRating[];
  comments: TRating[];
  liked: number[];
}

const CourseReviewContext = createContext<CourseReviewContextProps | undefined>(
  undefined
);

export const CourseReviewProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { user } = useUserContext();
  const [myReviews, setMyReviews] = useState<number[]>([]);
  const [liked, setLiked] = useState<number[]>([]);
  const [isEnrolled, setIsEnrolled] = useState(false);
  const [isLoadingMine, setIsLoadingMine] = useState<boolean>(false);
  const { data: report, fetchData: fetchReports } = useFetch(getCourseReview);
  const {
    data: othersReviews,
    isLoading: isLoadingOthers,
    fetchData: refetchOthers,
  } = useFetch(getPublicCourseReviewV3);

  const fetchMine = useCallback((courseId: number) => {
    setIsLoadingMine(true);
    getPrivateMyCourseReviewsV3(courseId)
      .then((res) => setMyReviews(res))
      .finally(() => setIsLoadingMine(false));
  }, []);

  const fetchLiked = useCallback((courseId: number) => {
    getPrivateCourseReviewLikedV3(courseId).then((res) => setLiked(res));
  }, []);

  const checkEnroll = useCallback((courseId: number) => {
    checkEnrolledUserCourse(courseId)
      .then((data) => {
        setIsEnrolled(data.is_enrolled);
      })
      .catch(() => {
        setIsEnrolled(false);
      });
  }, []);

  const getRating = useCallback(
    (courseId: number) => {
      fetchReports(courseId);
      if (user?.guid) {
        fetchMine(courseId);
        fetchLiked(courseId);
      }
    },
    [fetchReports, fetchMine, user?.guid, fetchLiked]
  );

  const comments = useMemo(() => {
    return (
      othersReviews?.results?.filter(
        (comment: { id: number }) => !myReviews.includes(comment.id)
      ) || []
    );
  }, [myReviews, othersReviews?.results]);

  const myComments = useMemo(() => {
    return (
      othersReviews?.results?.filter((comment: { id: number }) =>
        myReviews.includes(comment.id)
      ) || []
    );
  }, [myReviews, othersReviews?.results]);

  return (
    <CourseReviewContext.Provider
      value={{
        report,
        othersReviews,
        isLoadingMine,
        isLoadingOthers,
        isEnrolled,
        refetchOthers,
        checkEnroll,
        getRating,
        myComments,
        comments,
        liked,
      }}
    >
      {children}
    </CourseReviewContext.Provider>
  );
};

export const useCourseReview = () => {
  const context = useContext(CourseReviewContext);
  if (!context) {
    throw new Error('useCourseReview must be used within a CourseReviewProvider');
  }
  return context;
};
