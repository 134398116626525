import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../stores';
import ProgressLevelBar from './ProgressBar';
import SubjectInfoCard from './SubjectInfoCard';
import { fetchSubjectDetails } from '../../../stores/subject';
import { getPublicCourseV3 } from '../../../api/subjectV3';
import { useTranslation } from 'react-i18next';
import { Loader } from '../../../components/Loader';
import useFetch from '../../../hooks/useFetch';
interface LevelModalProps {
  title: string;
}

const LevelModal: FC<LevelModalProps> = ({ title }) => {
  const dispatch: AppDispatch = useDispatch();
  const subjectDetails = useSelector(
    (state: RootState) => state.start.subjectDetails
  );

  const { t, i18n } = useTranslation(['translation']);

  const language = i18n.language;
  useEffect(() => {
    dispatch(fetchSubjectDetails(language));
  }, [dispatch]);

  const currentSubjectId = useSelector(
    (state: RootState) => state.start.currentSubjectId
  );

  const [progressValue, setProgressValue] = useState(0);

  const {
    data: coursesData,
    isLoading,
    error,
    fetchData,
  } = useFetch(getPublicCourseV3);

  const findSubjectData = () => {
    return subjectDetails?.[language]?.find(
      (data) => data.subjectId === currentSubjectId
    );
  };

  const subjectData = findSubjectData();

  useEffect(() => {
    if (currentSubjectId ?? subjectData) {
      const subjectData = findSubjectData();
      if (subjectData) {
        setProgressValue(parseFloat((subjectData.levelProgress * 100).toFixed(0)));
        const params = {
          subject_level_units: subjectData.unitId,
          language,
        };
        fetchData(params);
      }
    }
  }, [currentSubjectId, subjectDetails]);

  const coursesCount = coursesData?.count;

  return (
    <div className="flex flex-col">
      <p className="mb-[12px] text-[18px] font-bold text-[#2B3674] md:hidden">
        {`${title} ${subjectData?.subjectLevel || ''}`}
      </p>
      <ProgressLevelBar className="mb-[8px]" progress={progressValue} />
      <div className="mb-[8px] flex items-center justify-between md:mb-[16px]">
        <p className="text-[16px] font-semibold  text-purple-500">
          {subjectData?.unitTitle}
        </p>
        {coursesCount !== undefined && (
          <p className="whitespace-nowrap text-[14px] font-semibold text-[#8F9BBA]">
            {coursesCount > 1
              ? `${coursesCount} ${t('map.courses')} `
              : `${coursesCount} ${t('map.course')}`}
          </p>
        )}
      </div>
      <div className="max-h-[600px] min-h-[216px] overflow-auto">
        {!coursesData && <Loader />}
        {coursesData?.results.map((course) => (
          <div key={course.id} className="mb-[8px] md:mb-[16px]">
            <SubjectInfoCard
              subject={course.subject.title}
              grade={course.level.toString()}
              name={course.title}
              rating={course.average_rating}
              language={course.language}
              courseId={course.id}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default LevelModal;
