export const isMobile = (): boolean => {
  const userAgent = navigator.userAgent || navigator.vendor;

  const isMobileUserAgent =
    /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);

  const screenWidth = window.innerWidth;

  const maxMobileWidth = 1024;

  return isMobileUserAgent || screenWidth <= maxMobileWidth;
};
