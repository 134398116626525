import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ERIcon } from '../../assets/icons/new-er.svg';
import BgImg from '../../assets/images/pages/profile/wallet-bg.webp';
import { formatNumber } from '../../shared/utils/formatNumber';
import { MButton } from '../../horizon-components/MButton';
import './style.scss';

interface ProfileWalletProps {
  className?: string;
  wallet?: string;
  onClick?: () => void;
}

export const ProfileWallet = ({
  className,
  wallet,
  onClick,
}: ProfileWalletProps) => {
  const { t, i18n } = useTranslation(['translation']);
  return (
    <div
      className={classNames('ProfileWallet', className)}
      style={{ backgroundImage: `url(${BgImg})` }}
      onClick={onClick}
    >
      <div className="ProfileWallet__content">
        <p>{t('profile.Current_balance')}</p>
        <h4 className="flex items-center gap-2 tabular-nums md:gap-4">
          {formatNumber(wallet || '0', i18n.language)}{' '}
          <ERIcon className="fill-white md:h-8 md:w-8" />
        </h4>
      </div>
      <MButton variant="secondary" color="primary" className="ml-auto">
        {t('buttons.Details')}
      </MButton>
    </div>
  );
};
