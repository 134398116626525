import { FC } from 'react';
import { MButton } from '../../../horizon-components/MButton';
import { ReactComponent as Icon } from '../../../assets/icons/share/chat.svg';
import { ButtonsProps } from '../types';
import { useTranslation } from 'react-i18next';
import { shareTelegram } from '../utils';

const ShareTelegramChatButton: FC<ButtonsProps> = ({ loading, onSocialClick }) => {
  const { t } = useTranslation();


  return (
    <MButton
      size="sm"
      variant="secondary"
      color="tab"
      onClick={onSocialClick('Telegram', shareTelegram)}
      loading={loading}
      disabled={loading}
    >
      {t('main.share.sendChat')} {!loading && <Icon />}
    </MButton>
  );
};

export default ShareTelegramChatButton;
