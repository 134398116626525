import { FC } from 'react';
import BgSchoolMapLayer from '../BgSchoolMapLayer';
import BgMainObjHighSchool from './layers/BgMainObjHighSchool';
import MainObjHighSchoolMap from './layers/MainObjHighSchoolMap';
import BgObjSchoolMap from '../layers/BgObjSchoolMap';

type HighSchoolMapProps = {
  className?: string;
};

const HighSchoolMap: FC<HighSchoolMapProps> = ({ className }) => {
  return (
    <>
      <div className={className}>
        <div className={`map-container`}>
          <BgSchoolMapLayer className="absolute-custom" />
          <div className="opacity-60">
            <BgObjSchoolMap className="absolute-custom" />
          </div>
          <BgMainObjHighSchool className="absolute-custom" />
          <MainObjHighSchoolMap className="absolute-custom" />
        </div>
      </div>
    </>
  );
};

export default HighSchoolMap;
