import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CheckIcon } from '../../assets/icons/nft/check.svg';
import { ReactComponent as StarIcon } from '../../assets/icons/star-full.svg';
import { CoinIcon } from '../../components/CoinIcon';
import { Badge } from '../../horizon-components/Badge';
import { MButton } from '../../horizon-components/MButton';
import {
  TaskProgressStatus,
  TaskRewardTypeEnum,
  TaskType,
} from '../../models/Tasks';
import { formatNumber } from '../../shared/utils/formatNumber';
import { MultiplierIcon } from '../../components/FireIcon';
import DailyRewardIcon from '../../assets/icons/tasks/reward.svg';
import InviteFriendsIcon from '../../assets/icons/tasks/invite.svg';
import BasketIcon from '../../assets/icons/tasks/basket.svg';
import CourseIcon from '../../assets/icons/tasks/education.svg';
import TelegramIcon from '../../assets/icons/tasks/telegram.svg';

interface QuestCardProps {
  title?: string;
  status?: TaskProgressStatus;
  award?: string;
  starsCount?: number;
  taskRewardType?: TaskRewardTypeEnum;
  taskType?: TaskType;
  onTaskCondition?: () => void;
  onTaskReward?: () => void;
  onTaskStart?: () => void;
  onClick?: () => void;
  image?: string;
  multiplier?: string;
}

export const QuestCard = ({
  title,
  status = 'RUNNING',
  award,
  taskType,
  starsCount,
  onTaskCondition,
  onTaskReward,
  onTaskStart,
  onClick,
  image,
  multiplier,
}: QuestCardProps) => {
  const { t, i18n } = useTranslation(['translation']);

  const determineImage = (taskType: string, image?: string): string | null => {
    if (image) return image;

    switch (taskType) {
      case 'SUBSCRIBE_TO_TELEGRAM':
        return TelegramIcon;
      case 'LOGIN_TO_APP':
        return DailyRewardIcon;
      case 'MAKE_PURCHASE':
        return BasketIcon;
      case 'INVITE_FRIEND':
        return InviteFriendsIcon;
      case 'VISIT_TELEGRAM_CHANNEL':
        return TelegramIcon;
      case 'COMPLETE_COURSE':
        return CourseIcon;
      case 'COMPLETE_COURSE_IN_ALL_SUBJECTS':
        return CourseIcon;
      case 'INVITE_N_FRIENDS':
        return InviteFriendsIcon;
      default:
        return DailyRewardIcon;
    }
  };

  const resolvedImage = determineImage(taskType || '', image);

  return (
    <div
      className={classNames(
        'inline-flex w-full items-center justify-start gap-2',
        'rounded-2xl p-2 lg:gap-4 lg:rounded-[20px] lg:p-4',
        'bg-[#e9e3ff]',
        {
          'bg-[#e9e3ff]': status === 'RUNNING',
          'bg-white shadow-md shadow-shadow-700': status === 'REWARD_RECEIVED',
        }
      )}
    >
      {/* {image ? (
        <div className="flex items-center justify-center gap-2 rounded-full border-2 border-[#d456ff] p-3 lg:rounded-2xl">
          <div className="relative h-[18px] w-[18px]">
            <img
              className="absolute left-0 top-0 h-[18px] w-[18px]"
              src={image}
              alt={title}
            />
          </div>
        </div>
      ) : null} */}

      {resolvedImage ? (
        <div>
          <div className="relative h-[42px] w-[42px]">
            <img
              className="absolute left-0 top-0 h-[42px] w-[42px]"
              src={resolvedImage}
              alt={title}
            />
          </div>
        </div>
      ) : null}
      <div className="inline-flex shrink grow basis-0 flex-col items-start justify-center gap-2">
        <h5 className="self-stretch text-sm font-semibold leading-[18px] text-[#2b3674]">
          {title}
        </h5>
        <div className="inline-flex items-start justify-start gap-1">
          {award ? (
            <Badge variant="gradient" size="md">
              +{formatNumber(award, i18n.language)}{' '}
              <CoinIcon className="relative !h-4 !w-4" />
            </Badge>
          ) : null}
          {starsCount ? (
            <Badge variant="gradient" size="md">
              +{starsCount}
              <StarIcon className="relative !h-3.5 !w-3.5 !fill-primary-white" />
            </Badge>
          ) : null}
          {multiplier ? (
            <Badge variant="gradient" size="md">
              {multiplier}X
              <MultiplierIcon className="relative !h-4 !w-4 !fill-primary-white" />
            </Badge>
          ) : null}
        </div>
      </div>
      {status === 'PENDING' ? (
        <MButton
          variant="highlighted"
          color="white"
          onClick={(e) => {
            e.stopPropagation();
            onTaskStart?.();
            onClick?.();
          }}
          className="!bottom-0"
        >
          {t('buttons.Start')}
        </MButton>
      ) : null}

      {status === 'COMPLETED' || status === 'RUNNING' ? (
        <MButton
          variant="highlighted"
          color="primary"
          onClick={(e) => {
            e.stopPropagation();
            onTaskCondition?.();
            onClick?.();
          }}
        >
          {t('buttons.Pick_up')}
        </MButton>
      ) : null}
      {status === 'REWARD_RECEIVED' ? (
        <div className="inline-flex h-[42px] items-center justify-center gap-2 rounded-3xl bg-[#c9fbd5] p-3">
          <CheckIcon className="relative h-[18px] w-[18px] fill-primary-green-500" />
        </div>
      ) : null}
    </div>
  );
};
