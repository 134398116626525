import i18n from '../i18n';
import { ICourse, ISubject, ISubjectProgress, IUnit } from '../models/Subjects';
import instance, { API_URL } from './instance';

const language = i18n.language;

let savedSubjectsPromises: Record<string, Promise<ISubject[]> | null> = {};
let savedUnitsPromises: Record<string, Promise<IUnit[]> | null> = {};
export function getPublicSubjectsV3(language: string): Promise<ISubject[]> {
  if (savedSubjectsPromises[language]) {
    return savedSubjectsPromises[language]!;
  }

  const promise = fetch(`${API_URL}/v3/public/subjects/`, {
    method: 'GET',
    headers: {
      'Accept-Language': language,
    },
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });

  savedSubjectsPromises[language] = promise;
  return promise;
}

export function getPublicUnitsV3(language: string): Promise<IUnit[]> {
  if (savedUnitsPromises[language]) {
    return savedUnitsPromises[language]!;
  }

  const promise = fetch(`${API_URL}/v3/public/unit/units/`, {
    method: 'GET',
    headers: {
      'Accept-Language': language,
    },
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });

  savedUnitsPromises[language] = promise;
  return promise;
}

export function getPublicCourseV3(params: Record<string, any>): Promise<ICourse> {
  const queryParams = new URLSearchParams(params).toString();
  const languageParam = params.language || language;
  return fetch(`${API_URL}/v3/public/course/course/?${queryParams}`, {
    method: 'GET',
    headers: {
      'Accept-Language': languageParam,
      accept: 'application/json',
    },
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}

export function getPrivateSubjectsProgress(): Promise<ISubjectProgress[]> {
  return instance()
    .get('/v3/private/get-subjects-progress')
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}
