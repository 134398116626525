import { FC } from 'react';
import EllipseImg from '../../../assets/images/map/progress/ellipse.svg';
import { Character2 } from '../LevelMap/items/winterItems';

const AvatarProgress: FC = () => {
  return (
    <>
      <div className="relative h-[60px] w-[60px] rounded-full bg-[#E9E3FF]">
        <div className="relative left-[4px] top-[4px] h-[52px] w-[52px] overflow-hidden rounded-full bg-[#BEB1FF]">
          <img
            className="absolute top-[-15px] h-[90px] w-[90px] scale-x-[-1] transform object-cover"
            src={Character2}
            alt=""
          />
        </div>
        <img
          className="object-fit absolute right-1 top-1 h-[12px] w-[12px] rounded-full"
          src={EllipseImg}
          alt=""
        />
      </div>
    </>
  );
};

export default AvatarProgress;
