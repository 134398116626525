import React, {
  FocusEventHandler,
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import CreatableSelect from 'react-select/creatable';
import { components, MenuListProps, OptionProps } from 'react-select';
import './styles.scss';
import { Loader } from '../../components/Loader';

export type SelectOptions = {
  label: string;
  value: string;
  count?: string;
};

export type MultiSelectProps = {
  id?: string;
  placeholder?: string;
  wrapperClassName?: string;
  label?: string;
  labelClassName?: string;
  value?: SelectOptions[];
  name?: string;
  onChange?: (value: SelectOptions[], name?: string) => void;
  options: SelectOptions[];
  styles?: Record<string, any>;
  state?: 'error' | 'success' | 'gray' | 'default';
  errorText?: string;
  bottomText?: string;
  bottomTextClassName?: string;
  inputClassName?: string;
  disabled?: boolean;
  size?: 'sm' | 'md';
  onLoadMore?: () => void;
  isLoading?: boolean;
};

const createOption = (label: string) => ({
  label,
  value: label,
});

const MenuList: React.FC<MenuListProps<SelectOptions>> = (props) => {
  const menuRef = useRef<HTMLDivElement>(null);

  const handleScroll = useCallback(
    (event: Event) => {
      if (!menuRef.current) return;

      const { scrollTop, scrollHeight, clientHeight } = menuRef.current;

      if (
        scrollHeight - scrollTop <= clientHeight + 1 &&
        props.selectProps.onMenuScrollToBottom
      ) {
        props.selectProps.onMenuScrollToBottom(event as WheelEvent | TouchEvent);
      }
    },
    [props.selectProps.onMenuScrollToBottom]
  );

  useEffect(() => {
    const menuElement = menuRef.current;
    if (!menuElement) return;

    menuElement.addEventListener('scroll', handleScroll);
    menuElement.addEventListener('touchmove', handleScroll);

    return () => {
      menuElement.removeEventListener('scroll', handleScroll);
      menuElement.removeEventListener('touchmove', handleScroll);
    };
  }, [handleScroll]);

  return (
    <components.MenuList {...props} innerRef={menuRef}>
      {props.children}
      {props.selectProps.isLoading ? <Loader /> : null}
    </components.MenuList>
  );
};

export const MMultiSelect: React.FC<MultiSelectProps> = ({
  id,
  placeholder,
  wrapperClassName,
  label,
  labelClassName,
  name,
  value,
  onChange,
  options,
  styles = {},
  state,
  errorText,
  bottomText,
  bottomTextClassName,
  inputClassName,
  disabled,
  size = 'md',
  onLoadMore,
  isLoading,
}) => {
  const [inputValue, setInputValue] = React.useState('');
  const [val, setVal] = React.useState<readonly SelectOptions[]>([]);

  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        setVal((prev) => [...prev, createOption(inputValue)]);
        setInputValue('');
        onChange?.([...val, createOption(inputValue)], name);
        event.preventDefault();
    }
  };

  const handleBlur: FocusEventHandler<HTMLInputElement> = (event) => {
    if (!inputValue) return;
    setVal((prev) => [...prev, createOption(inputValue)]);
    setInputValue('');
    onChange?.([...val, createOption(inputValue)], name);
    event.preventDefault();
  };

  useEffect(() => {
    value && setVal(value);
  }, [value]);

  const Option = (props: OptionProps<SelectOptions>) => (
    <components.Option {...props}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {props.data.label}
        {props?.data?.count && (
          <span
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '0 6px',
              borderRadius: '12px',
              background: '#F4F7FE',
              color: '#707EAE',
              fontSize: '12px',
              textTransform: 'lowercase',
            }}
          >
            {props.data.count}
          </span>
        )}
      </div>
    </components.Option>
  );

  return (
    <div className={`${wrapperClassName}`}>
      {label && (
        <label
          htmlFor={id}
          className={`mb-1 ml-4 text-sm font-medium text-gray-900 ${labelClassName} ${
            state === 'gray'
              ? 'text-gray-900'
              : state === 'success'
                ? 'text-green-700'
                : state === 'error'
                  ? 'text-red-700'
                  : ''
          }`}
        >
          {label}
        </label>
      )}
      <CreatableSelect
        inputValue={inputValue}
        isMulti
        value={val}
        options={options}
        className={`b-multi-base flex min-w-[250px] appearance-none items-center rounded-2xl border-2 border-secondaryGrey-500 bg-transparent bg-no-repeat px-1 text-sm font-medium text-gray-700 outline-none placeholder:text-gray-500
          ${inputClassName} ${size === 'md' ? 'py-[3px]' : 'py-[1px]'} ${
            state === 'gray'
              ? '!placeholder:text-purple-700 !border-secondaryGrey-600 !text-purple-900'
              : state === 'success'
                ? '!border-green-500 !text-green-600 placeholder:!text-green-400'
                : state === 'error'
                  ? 'border-2 !border-red-500 !text-red-600 placeholder:!text-red-400'
                  : ''
          } ${
            disabled
              ? 'disabled border-secondaryGrey-300 !bg-secondaryGrey-300 text-secondaryGrey-300 placeholder:!text-secondaryGrey-300'
              : ''
          }`}
        onChange={(data) => {
          onChange?.(data as SelectOptions[], name);
        }}
        onBlur={handleBlur}
        onInputChange={(newValue) => setInputValue(newValue)}
        placeholder={placeholder}
        styles={styles}
        isDisabled={disabled}
        isClearable={false}
        onKeyDown={handleKeyDown}
        menuIsOpen={
          options.findIndex((option) => option.label.includes(inputValue)) > -1
            ? undefined
            : false
        }
        components={{ MenuList, Option }}
        onMenuScrollToBottom={onLoadMore}
        isLoading={isLoading}
      />
      {bottomText && !errorText && (
        <p
          className={`ml-4 mt-1 text-xs font-medium text-gray-500 ${bottomTextClassName} ${
            state === 'success'
              ? 'text-green-500'
              : state === 'error'
                ? 'text-red-500'
                : ''
          }`}
        >
          {bottomText}
        </p>
      )}
      {errorText && (
        <p
          className={`ml-4 mt-1 text-xs font-medium text-gray-500 ${bottomTextClassName} text-red-500`}
        >
          {errorText}
        </p>
      )}
    </div>
  );
};
