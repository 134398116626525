import React, {
  ChangeEvent,
  SyntheticEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { getStorage, removeStorage } from '../../../../api';
import { getChatConnect } from '../../../../api/socket/socket';
import {
  creatChatFile,
  creatChatMessages,
  getChatMessages,
} from '../../../../api/weber/chat';
import { ReactComponent as ChatMenu } from '../../../../assets/icons/chat-menu.svg';
import { ReactComponent as PaperClip } from '../../../../assets/icons/paper-clip.svg';
import { ReactComponent as SendInput } from '../../../../assets/icons/send-input.svg';
import noAvatarSrc from '../../../../assets/images/no-avatar.png';
import { Loader } from '../../../../components/Loader';
import { Card } from '../../../../horizon-components/Card/Card';
import { Link } from '../../../../horizon-components/Link';
import {
  AuthRoutes,
  MainRoutes,
  ProfileRoutes,
  WeberRoutes,
} from '../../../../horizon-layout/MainLayout/Routes/types/routes';
import { TChat, TMessage } from '../../../../models/Chat';
import { useUserContext } from '../../../../providers';
import {
  renderThumbMessages,
  renderTrackMessages,
  renderViewMessages,
} from '../../../../utils/scrollbar';
import MessageItem from './MessageItem';
import dayjs from 'dayjs';
import dayOfYear from "dayjs/plugin/dayOfYear";
dayjs.extend(dayOfYear);

type Props = {
  chat: TChat | null;
  updateChat: (chat: TChat) => void;
  onClose?: React.MouseEventHandler<HTMLSpanElement>;
  variant?: string;
  onRead?: () => void;
};

const MessageList: React.FC<Props> = ({ chat, onClose, updateChat, onRead }) => {
  const [messages, setMessages] = useState<TMessage[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [text, setText] = useState('');
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const { user } = useUserContext();
  const ref = useRef<Scrollbars>(null);
  const { t } = useTranslation(['translation']);

  useEffect(() => {
    const chat_share = getStorage('chat_share');
    if (chat_share) {
      setText(chat_share);
      removeStorage('chat_share');
    }
    return () => removeStorage('chat_share');
  }, []);

  useEffect(() => {
    if (chat) {
      setIsLoading(true);
      getChatMessages(chat.id)
        .then((data) => {
          onRead?.();
          setMessages(
            data?.sort((a, b) =>
              new Date(a.created_at).getTime() > new Date(b.created_at).getTime()
                ? 1
                : -1
            )
          );
          setTimeout(() => {
            ref.current?.scrollToBottom();
          }, 100);
        })
        .finally(() => setIsLoading(false));
    }
    return () => {
      setMessages([]);
    };
  }, [chat]);

  const handleTextChange = (e: ChangeEvent<HTMLInputElement>) =>
    setText(e.target.value);

  const send = () => {
    if (chat && socket && text) {
      creatChatMessages(chat?.id || 0, text);
      setText('');
    }
  };

  useEffect(() => {
    if (chat) {
      const connect = () => getChatConnect(chat.id).then(setSocket);

      connect().catch(() => {
        connect().catch(() => {
          toast('Ошибка подключения', {
            type: 'error',
          });
        });
      });
    }

    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, [chat]);

  useEffect(() => {
    if (socket) {
      socket.onmessage = (message: MessageEvent<string>) => {
        const data = JSON.parse(message.data) as TMessage;
        setMessages((v) =>
          [...v, data]?.sort((a, b) =>
            new Date(a.created_at).getTime() > new Date(b.created_at).getTime()
              ? 1
              : -1
          )
        );
        if (!!chat?.last_message) {
          if (data.file) {
            chat.last_message.file = data.file;
          } else {
            chat.last_message.body = data.body;
            chat.last_message.file = null;
          }
          updateChat(chat);
        }
        setTimeout(() => {
          ref.current?.scrollToBottom();
        }, 100);
      };
    }

    return () => {
      if (socket) {
        socket.onmessage = null;
      }
    };
  }, [chat, socket, updateChat]);

  const handleChange = (e: SyntheticEvent<HTMLInputElement>) => {
    // @ts-ignore
    const imageFile = e.target.files[0];
    creatChatFile(chat?.id || 0, imageFile);
  };

  const isGroup = useMemo(() => {
    return (chat?.participants?.length || 0) > 2;
  }, [chat]);

  const link = useMemo(
    () =>
      chat?.profile.user_guid
        ? MainRoutes.auth +
          AuthRoutes.profile +
          ProfileRoutes.user +
          `/${chat?.profile.user_guid}`
        : chat?.profile.group_id
          ? MainRoutes.weber + WeberRoutes.community + `/${chat?.profile.group_id}`
          : '',
    [chat]
  );

  return (
    <div className={`duration-175 relative flex h-full flex-col transition-all`}>
      <Card
        extra={
          'w-full h-[calc(100vh_-_230px)] p-[8px] lg:p-4 !shadow-[14px_17px_40px_4px_#7090B014]'
        }
      >
        <div className="flex items-center justify-between border-b border-gray-200 pb-2 dark:!border-navy-700 lg:pb-3">
          <div className="flex items-center gap-3">
            <div className="flex items-center justify-center gap-2">
              <div onClick={onClose} className={'block lg:hidden'}>
                <ChatMenu />
              </div>
              <Link
                to={link}
                className="h-[40px] w-[40px] min-w-[40px] rounded-full lg:h-[46px] lg:w-[46px] lg:min-w-[46px]"
              >
                <img
                  src={chat?.profile?.chat_photo || noAvatarSrc}
                  className="h-full w-full rounded-full object-cover"
                  alt=""
                />
              </Link>
            </div>
            <div>
              <Link to={link}>
                <h1 className="text-[18px] font-bold text-navy-700 dark:text-white">
                  {(!chat && t('chat.selectChat')) ||
                    chat?.profile?.chat_name ||
                    t('chat.unnamedChat')}
                </h1>
              </Link>
              {!!chat && isGroup && (
                <p className={'text-[16px] text-[#8F9BBA]'}>
                  {chat?.participants?.length} {t('chat.members')}
                </p>
              )}
            </div>
          </div>
        </div>
        {/* message content */}
        <div className="relative h-[calc(100%-70px)]">
          <div className="flex h-[calc(100%-74px)] w-full">
            {isLoading ? (
              <div className={'flex w-full items-center justify-center'}>
                <Loader />
              </div>
            ) : !!messages?.length ? (
              <Scrollbars
                renderTrackVertical={renderTrackMessages}
                renderThumbVertical={renderThumbMessages}
                renderView={renderViewMessages}
                ref={ref}
              >
                <div className="relative max-h-max overflow-hidden pb-[30px] lg:max-h-[unset] lg:overflow-visible">
                  {messages.map((message, index) => {
                    const isMyMessage = user?.guid === message.author_guid;
                    const isShowDate =
                      index === 0 ||
                      dayjs(message.created_at).dayOfYear() -
                        (dayjs(messages?.[index - 1]?.created_at).dayOfYear() || 0) >
                        0;
                    return (
                      <React.Fragment key={message.id}>
                        {isShowDate && (
                          <div
                            style={{
                              position: 'sticky',
                              top: 0,
                            }}
                            className={
                              'mx-auto my-[16px] w-fit rounded-full bg-purple-100 px-[12px] py-[4px] text-[12px] font-[600] text-purple-500'
                            }
                          >
                            {dayjs(message.created_at).format('DD.MM.yyyy')}
                          </div>
                        )}
                        {!isMyMessage && (
                          <div className={'flex flex-row gap-1'}>
                            <MessageItem
                              message={message.body}
                              file={message.file}
                              time={dayjs(message.created_at).format('HH:mm')}
                              extra="!bg-secondaryGrey-300 w-fit rounded-r-xl rounded-bl-xl"
                              text="text-gray-900"
                              timecolor="text-secondaryGrey-600"
                              isGroup={isGroup}
                              username={message?.author?.user_meta?.username || ''}
                            />
                          </div>
                        )}
                        <div className="flex flex-col items-end">
                          {isMyMessage && (
                            <div className={'flex flex-row-reverse gap-1'}>
                              <MessageItem
                                message={message.body}
                                file={message.file}
                                time={dayjs(message.created_at).format('HH:mm')}
                                extra="bg-horizonPurple-300 w-fit md:w-[380px] rounded-l-xl rounded-br-xl"
                                text="text-color-white"
                                timecolor="text-color-white"
                                isMyMessage
                              />
                            </div>
                          )}
                        </div>
                      </React.Fragment>
                    );
                  })}
                </div>
              </Scrollbars>
            ) : (
              <div className={'flex w-full items-center justify-center'}>
                <p className={'text-lg'}>
                  {!chat ? t('chat.selectWriteTo') : t('chat.emptyChat')}
                </p>
              </div>
            )}
          </div>
          <div className="mt-6 flex items-center gap-2">
            <div className="flex h-[50px] w-full items-center rounded-full bg-secondaryGrey-300 pl-[10px] pr-[45px]">
              <label className={'ml-[10px] cursor-pointer'}>
                <input
                  type={'file'}
                  onChange={handleChange}
                  id={'file'}
                  className={'d-hidden'}
                />
                <PaperClip />
              </label>
              <input
                disabled={!chat}
                placeholder={t('chat.writeMessage')}
                className="h-full w-full rounded-full bg-secondaryGrey-300 px-2 text-sm text-navy-700 outline-none"
                type="text"
                value={text}
                onChange={handleTextChange}
                onKeyDown={(e) => e.key === 'Enter' && send()}
              />
            </div>
            <button
              onClick={send}
              disabled={!chat}
              className="linear flex cursor-pointer items-center justify-center rounded-full bg-purple-500 p-3 text-base transition duration-200"
            >
              <SendInput />
            </button>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default MessageList;
