import classNames from 'classnames';
import { ReactNode } from 'react';
import './NotificationCard.scss';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);
interface NotificationCardProps {
  className?: string;
  title?: string;
  message?: string;
  time?: string;
  icon?: ReactNode;
}

export const NotificationCard = ({
  className,
  title,
  message,
  time,
  icon,
}: NotificationCardProps) => {
  return (
    <div className={classNames('NotificationCard', className)}>
      {icon && <div className="NotificationCard__icon">{icon}</div>}
      <div className="NotificationCard__content">
        {title && <h4>{title}</h4>}
        {message && <p>{message}</p>}
      </div>
      {time && <div className="text-xs max-w-12">{dayjs(time).fromNow()}</div>}
    </div>
  );
};
