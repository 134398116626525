import React, { useCallback, useEffect } from 'react';
import { LANG_KEY, Languages, LANGUAGES } from '../../i18n';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../../providers/LanguageProvider';
import { MButton } from '../../horizon-components/MButton';

export const ChangeLanguage = () => {
  const { i18n } = useTranslation();
  const { language, setLanguage } = useLanguage();

  // Initialize language from localStorage only once when the component mounts
  useEffect(() => {
    const storedLang = localStorage.getItem(LANG_KEY) as Languages;
    if (storedLang && storedLang !== language) {
      i18n.changeLanguage(storedLang);
      setLanguage(storedLang);
    }
  }, [language, i18n, setLanguage]);

  // Toggle language to the next one in LANGUAGES
  const toggleLanguage = useCallback(() => {
    const languageKeys = Object.keys(LANGUAGES) as Languages[];
    const currentIndex = languageKeys.indexOf(language);
    const nextIndex = (currentIndex + 1) % languageKeys.length;
    const newLanguage = languageKeys[nextIndex];

    // Update localStorage, i18n, and state only if the language has changed
    if (newLanguage !== language) {
      localStorage.setItem(LANG_KEY, newLanguage);
      i18n.changeLanguage(newLanguage);
      setLanguage(newLanguage);
    }
  }, [language, i18n, setLanguage]);

  return (
    <div className="flex items-center justify-center">
      <MButton
        className="!border-[0] !px-2.5 !py-1.5"
        variant="secondary"
        onClick={toggleLanguage}
      >
        <span className="text-sm font-semibold md:text-sm">
          {LANGUAGES[language]} {/* Display the current language */}
        </span>
      </MButton>
    </div>
  );
};
