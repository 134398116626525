import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { setPurchaseLife } from '../../api/wiz/wiz';
import { ReactComponent as ERIcon } from '../../assets/icons/new-er.svg';
import ThunderGif from '../../assets/images/wiz/thunder.gif';
import { useNavigate } from '../../hooks/useNavigate';
import { MButton } from '../../horizon-components/MButton';
import {
  EduRoutes,
  MainRoutes,
} from '../../horizon-layout/MainLayout/Routes/types/routes';
import { useUserContext } from '../../providers';
import { useRewardContext } from '../../providers/RewardProvider';
import { useWizContext } from '../../providers/WizProvider/WizProvider';
import { WIZ_LIVE } from '../../shared/const/wiz';
import './style.scss';

interface QuizTestModalProps {
  className?: string;
  onClose?: () => void;
}

export const QuizTestModal = ({ className, onClose }: QuizTestModalProps) => {
  const { user, updateWallet, wallet } = useUserContext();
  const { t } = useTranslation(['translation']);
  const navigate = useNavigate();
  const [isBuyLifeFetching, setIsBuyLifeFetching] = useState(false);
  const { nextLifeRecoveryTime, fetchLivesData, livesCount } = useWizContext();
  const { rewards } = useRewardContext();

  const onBuyLife = async () => {
    setIsBuyLifeFetching(true);
    try {
      const result = await setPurchaseLife();
      if (result.success) {
        fetchLivesData();
        updateWallet();
        toast(t('wiz.You_have_successfully_restored_your_energy'), {
          type: 'success',
        });
      } else {
        toast(t('wiz.An_error_occurred_during_the_purchase_of_a_life'), {
          type: 'error',
        });
      }
    } catch (e) {
      toast(t('wiz.An_error_occurred_during_the_purchase_of_a_life'), {
        type: 'error',
      });
    } finally {
      setIsBuyLifeFetching(false);
      onClose?.();
    }
  };

  const buyLifePrice =
    (rewards &&
      rewards.find((reward) => reward.tokenomic_type === 'wiz_life_purchase')
        ?.value) ||
    0;

  const targetDate = nextLifeRecoveryTime
    ? new Date(nextLifeRecoveryTime).getTime()
    : null;

  const [timeLeft, setTimeLeft] = useState(
    targetDate ? targetDate - new Date().getTime() : 0
  );

  useEffect(() => {
    if (!targetDate) return;

    const timerId = setInterval(() => {
      const now = new Date().getTime();
      const difference = targetDate - now;
      setTimeLeft(difference > 0 ? difference : 0);
    }, 1000);

    return () => clearInterval(timerId);
  }, [targetDate]);

  useEffect(() => {
    if (nextLifeRecoveryTime) {
      const newTargetDate = new Date(nextLifeRecoveryTime).getTime();
      setTimeLeft(newTargetDate - new Date().getTime());
    }
  }, [nextLifeRecoveryTime]);

  const formatTime = (milliseconds: number) => {
    if (milliseconds <= 0) return '0:00:00';

    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const titleText = t('wiz.energy_modal_title', { livesCount: livesCount });

  const descriptionText =
    livesCount !== WIZ_LIVE
      ? t('wiz.energy_modal_description', { timeLeft: formatTime(timeLeft) })
      : t('wiz.energy_modal_description_full_energy');

  return (
    <div className={classNames('QuizTestModal', className)}>
      <img src={ThunderGif} alt="thunder" className={'w-28 lg:w-[150px]'} />
      <div className="flex flex-col gap-3">
        <h3>{titleText}</h3>
        <p>{descriptionText}</p>
      </div>
      <div className="flex gap-2 md:gap-6">
        <MButton
          variant="highlighted"
          color="primary"
          className="!px-5 !py-4 md:!px-8 md:!py-6 md:!text-lg"
          size="lg"
          onClick={() =>
            livesCount === 0
              ? navigate(MainRoutes.edu + EduRoutes.courses)
              : onClose?.()
          }
        >
          {t(livesCount === 0 ? 'buttons.Go_to_courses' : 'buttons.Okay')}
        </MButton>
        {
          <MButton
            variant="secondary"
            color="primary"
            className="!px-5 !py-4 md:!px-8 md:!py-6 md:!text-lg"
            size="lg"
            onClick={onBuyLife}
            disabled={
              isBuyLifeFetching ||
              livesCount === WIZ_LIVE ||
              !user?.guid ||
              !!(
                wallet &&
                buyLifePrice &&
                parseFloat(wallet.value) < parseFloat(buyLifePrice)
              )
            }
          >
            {t('buttons.Speed_up_for')} {Math.floor(Number(buyLifePrice))}{' '}
            <ERIcon className="h-[18px] w-[18px] fill-primary-purple-blue-500" />
          </MButton>
        }
      </div>
    </div>
  );
};
