import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { MButton } from '../../../../horizon-components/MButton';
import { useCourseCreation } from '../../../../providers/course-provider/course-creation-provider';
import { getUserData } from '../../../../utils/getUserData';
import { NoAvatar } from '../../../../widgets/NoAvatar';

interface Props {
  onClick?: () => void;
  isDetaile?: boolean;
}

export const CoursePreviewUserData = ({ onClick, isDetaile }: Props) => {
  const { t } = useTranslation(['translation']);
  const { course } = useCourseCreation();
  const authorData = course?.owner && getUserData(course.owner);

  return (
    <div className="inline-flex items-center justify-start gap-2 rounded-2xl bg-white p-2 shadow-md shadow-shadow-700 md:gap-6 md:rounded-[30px] md:p-4">
      <div className="flex shrink grow basis-0 items-center justify-start gap-3 md:gap-4">
        {authorData?.guid ? (
          <div className="relative h-[34px] w-[34px] overflow-hidden !rounded-[35px] md:h-[46px] md:w-[46px]">
            <img
              src={authorData.image}
              alt={authorData.first_name || authorData.username}
            />
            <NoAvatar
              src={authorData.image}
              alt={authorData.username}
              className="relative !h-[34px] !w-[34px] rounded-[35px]"
            />
          </div>
        ) : null}
        <div className="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-1">
          {course?.title ? (
            <h3 className="hidden self-stretch text-lg font-bold leading-snug text-[#2b3674] md:block">
              {course?.title}
            </h3>
          ) : null}
          {authorData?.first_name || authorData?.username ? (
            <div className="text-sm font-bold leading-[18px] text-[#2b3674] md:hidden">
              {t('course.By')} {authorData.first_name || authorData.username}
            </div>
          ) : null}

          <div className="inline-flex items-center justify-start gap-2 self-stretch">
            {authorData?.first_name || authorData?.username ? (
              <>
                <div className="hidden text-base font-medium leading-tight text-[#8f9bba] md:block">
                  {t('course.By')} {authorData.first_name || authorData.username}
                </div>
                <div className="hidden h-1 w-1 rounded-full bg-[#8f9bba] md:block" />
              </>
            ) : null}
            {course?.created_at ? (
              <p className="text-xs font-medium leading-tight text-[#8f9bba] md:text-base">
                {dayjs(course?.created_at).format('DD.MM.YY')}
              </p>
            ) : null}
          </div>
        </div>
      </div>
      <MButton variant={isDetaile ? 'transparent' : 'highlighted'} onClick={onClick}>
        {isDetaile ? t('buttons.Back_to_Details') : t('buttons.Open_course')}
      </MButton>
    </div>
  );
};
