import { FC } from 'react';
import BgSchoolMapLayer from '../BgSchoolMapLayer';
import BgMainObjMidSchool from './layers/BgMainObjMidSchool';
import MainObjMidSchoolMap from './layers/MainObjMidSchoolMap';
import BgObjMiddleSchoolMap from './layers/BgObjMiddleSchoolMap';

type MiddleSchoolMapProps = {
  className?: string;
};

const MiddleSchoolMap: FC<MiddleSchoolMapProps> = ({ className }) => {
  return (
    <div className={className}>
      <div
        className={`map-container absolute`}
        // style={{ height: '100vh', width: '100%', margin: '10px' }}
      >
        <BgSchoolMapLayer className="absolute-custom" />
        <div className="opacity-60">
          <BgObjMiddleSchoolMap className="absolute-custom" />
        </div>
        <BgMainObjMidSchool className="absolute-custom" />
        <MainObjMidSchoolMap className="absolute-custom" />
      </div>
    </div>
  );
};

export default MiddleSchoolMap;
