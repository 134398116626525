import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ChevronUp } from '../../../../../assets/icons/chevron-up.svg';
import { ReactComponent as Collapse } from '../../../../../assets/icons/collapse-icon.svg';
import { ReactComponent as DocIcon } from '../../../../../assets/icons/doc.svg';
import { useCourseCreation } from '../../../../../providers/course-provider/course-creation-provider';
import { CourseAsideCard } from '../../../../../widgets/CourseAsideCard';
import CircularProgressMini from '../../../../../horizon-components/CircularProgressMini/CircularProgressMini';
import { ReactComponent as ArrowDownIcon } from '../../../../../assets/icons/arrow-down.gray.svg';
import classNames from 'classnames';

interface Props {
  onClick?: () => void;
  isDetaile?: boolean;
}

export const CoursePreviewNav: React.FC<Props> = ({ onClick, isDetaile }) => {
  const { t } = useTranslation(['translation']);
  const {
    courseUnits,
    updateActiveTab,
    activeTab,
    setCurrentUnitExamId,
    currentUnitExamId,
  } = useCourseCreation();
  const [unitsIsVisible, setUnitsIsVisible] = useState(true);
  const [open, setOpen] = useState(0);
  const totalUnits = courseUnits?.length;

  const toggleUnit = () => setUnitsIsVisible(!unitsIsVisible);
  const handleOpen = (unitId: number) => {
    if (!isDetaile) {
      onClick?.();
    }
    updateActiveTab(unitId);
    setOpen(open !== unitId ? unitId : 0);
  };

  const handleExam = (unitId: number) => {
    if (!isDetaile) {
      onClick?.();
    }
    updateActiveTab(unitId);
    setCurrentUnitExamId(unitId);
  };

  return (
    <CourseAsideCard
      title={t('course.Course_Contents')}
      data={
        <p className="flex text-xs font-semibold text-secondary-dark-grey-500 md:text-sm lg:hidden">
          {totalUnits} {t('course.units')}
        </p>
      }
    >
      {totalUnits ? (
        <div className="flex items-center justify-between">
          <p className="hidden text-xs font-semibold text-secondary-dark-grey-500 md:text-sm lg:block">
            {totalUnits} {t('course.units')}
          </p>
        </div>
      ) : null}
      {!unitsIsVisible && (
        <div className={'flex items-center justify-center pt-[10px] lg:hidden'}>
          <div className={'cursor-pointer'} onClick={toggleUnit}>
            <Collapse />
          </div>
        </div>
      )}
      {unitsIsVisible ? (
        <div className={'mt-2 flex flex-col gap-2 md:mt-4 md:gap-4'}>
          {courseUnits?.map((unit, index) => (
            <React.Fragment key={index}>
              <div className="group flex items-center justify-between gap-1 hover:cursor-pointer sm:gap-2">
                <div className="flex w-11/12 items-start gap-2 md:gap-4">
                  <div className="w-[30px] min-w-[30px]">
                    <CircularProgressMini
                      step={(index + 1).toString()}
                      percentage={unit.id === activeTab && isDetaile ? 100 : 0}
                    />
                  </div>
                  <div className="flex w-full flex-col gap-1 overflow-hidden overflow-ellipsis">
                    <h4
                      className={classNames(
                        'mt-1 cursor-pointer text-sm font-semibold text-gray-900 lg:text-base',
                        {
                          'text-primary-green-500':
                            unit.id === activeTab && isDetaile,
                        }
                      )}
                      onClick={() => handleOpen(unit.id)}
                    >
                      {unit.unit.title}
                    </h4>

                    {open === unit.id && (
                      <ul className="!list-disc pl-5 text-sm text-secondary-dark-grey-700">
                        {unit?.topics
                          ?.sort((a, b) => {
                            if (a.order === undefined && b.order === undefined) {
                              return 0;
                            }
                            if (a.order === undefined) {
                              return 1;
                            }
                            if (b.order === undefined) {
                              return -1;
                            }
                            return a.order - b.order;
                          })
                          .map((topic) => <li key={topic.id}>{topic.title}</li>)}
                      </ul>
                    )}
                  </div>
                  <div
                    className="flex w-5 min-w-5 cursor-pointer items-start justify-center pt-2"
                    onClick={() => handleOpen(unit.id)}
                  >
                    <ArrowDownIcon
                      className={open === unit.id ? 'rotate-180' : ''}
                    />
                  </div>
                </div>
              </div>
              <div className="group flex items-center justify-between gap-1 hover:cursor-pointer sm:gap-2">
                <div
                  className="flex w-11/12 items-center gap-2 md:gap-4"
                  // onClick={() => handleExam(unit.id)}
                >
                  <div className="w-[30px] min-w-[30px]">
                    <CircularProgressMini
                      percentage={unit.id === currentUnitExamId ? 100 : 0}
                    >
                      <DocIcon
                        className={classNames(
                          `h-5 w-5 fill-secondary-dark-grey-500`,
                          {
                            '!fill-primary-green-600':
                              unit.id === currentUnitExamId && isDetaile,
                          }
                        )}
                      />
                    </CircularProgressMini>
                  </div>
                  <div
                    className={`flex items-center gap-1 overflow-hidden overflow-ellipsis text-nowrap text-sm font-semibold text-secondary-dark-grey-400 md:text-base`}
                  >
                    <p
                      className={classNames(
                        'overflow-hidden overflow-ellipsis text-nowrap text-secondary-dark-grey-900',
                        {
                          '!text-primary-green-500':
                            unit.id === currentUnitExamId && isDetaile,
                        }
                      )}
                    >
                      {t('course.Unit_test')}
                    </p>
                  </div>
                </div>
              </div>
            </React.Fragment>
          ))}
        </div>
      ) : null}
      {unitsIsVisible && (
        <div className={'flex items-center justify-center pt-2.5 md:hidden'}>
          <div className={'cursor-pointer'} onClick={toggleUnit}>
            <ChevronUp />
          </div>
        </div>
      )}
    </CourseAsideCard>
  );
};
