import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from '../../horizon-components/Dropdown/Dropdown';
import { MTooltip } from '../../horizon-components/MTooltip/MTooltip';
import { useUserContext } from '../../providers';
import { getUserData } from '../../utils/getUserData';
import { AuthRoutes, MainRoutes, ProfileRoutes } from './Routes/types/routes';
import { Link } from '../../horizon-components/Link';

export const ProfileDropdown: React.FC = () => {
  const { logout, user } = useUserContext();
  const { username, image } = getUserData(user || undefined);
  const { t } = useTranslation(['translation']);
  const [isClose, setIsClose] = React.useState(false);

  return (
    <>
      <Dropdown
        isOpen={isClose}
        button={
          <img
            className="h-8 w-8 min-w-8 max-w-8 rounded-full object-cover md:h-10 md:w-10 md:min-w-10 md:max-w-10"
            src={image}
            id={'profile'}
            alt="profile"
          />
        }
        children={
          <div className="flex h-max w-56 flex-col justify-start overflow-hidden rounded-[20px] bg-white bg-cover bg-no-repeat pb-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
            <div className="ml-4 mt-3">
              <div className="flex items-center gap-2">
                <p className="truncate text-sm font-bold text-navy-700 dark:text-white">
                  {username}
                </p>
              </div>
            </div>
            <div className="mt-3 h-px w-full bg-gray-200 dark:bg-white/20 " />
            <div className="ml-4 mt-3 flex flex-col">
              <Link
                to={
                  MainRoutes.auth +
                  AuthRoutes.profile +
                  ProfileRoutes.user +
                  `/${user?.guid}`
                }
                className="mb-1 text-sm text-gray-800 dark:text-white hover:dark:text-white"
                onClick={() => setIsClose(!isClose)}
              >
                {t('menu.Profile')}
              </Link>
              <Link
                to={MainRoutes.auth + AuthRoutes.profile + ProfileRoutes.edit}
                className="mb-1 text-sm text-gray-800 dark:text-white hover:dark:text-white"
                onClick={() => setIsClose(!isClose)}
              >
                {t('menu.Settings')}
              </Link>
              <p
                onClick={() => logout()}
                className="mt-3 cursor-pointer text-sm font-medium text-red-500 hover:text-red-500"
              >
                {t('buttons.Log_Out')}
              </p>
            </div>
          </div>
        }
        classNames={'py-2 top-8 -left-[180px] w-max'}
      />
      <MTooltip anchorSelect={'#profile'} text={t('menu.Profile')} />
    </>
  );
};
