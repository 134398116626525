import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Bid } from '../../models/Auction';
import { MButton } from '../../horizon-components/MButton';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { formatNumber } from '../../shared/utils/formatNumber';
dayjs.extend(relativeTime);

interface BidCardProps {
  className?: string;
  bid?: Bid;
  place?: number;
  canBid?: boolean;
  isMy?: boolean;
  isWin?: boolean;
  onBid?: () => void;
  isMin?: boolean;
}

export const BidCard = (props: BidCardProps) => {
  const { t, i18n } = useTranslation(['translation']);
  const {
    className,
    bid,
    canBid = false,
    place,
    isMy = false,
    isWin = false,
    isMin = false,
    onBid,
  } = props;

  if (!bid?.id) return null;

  return (
    <>
      <div
        className={classNames(
          'top-0 inline-flex w-full items-center justify-start gap-2 bg-white',
          className,
          {
            sticky: isMin && isWin,
          }
        )}
        id={isMy ? 'my-bid' : `bid-${bid.id}`}
      >
        <div
          className={classNames(
            'flex shrink grow basis-0 items-center justify-start gap-3 rounded-xl border  border-[#e0e5f2] bg-white p-2',
            {
              '!border-[#4318ff]': isMy,
              '!border-[#00b574]': isWin,
              '!bg-primary-purple-blue-600': isMy && !canBid && place && isWin,
            }
          )}
          onClick={() => canBid && isMy && onBid?.()}
        >
          <img
            className="h-[34px] w-[34px] rounded-full"
            src={
              bid?.user.user_meta.profile_image ||
              require('../../assets/images/no-avatar.png')
            }
            alt={bid.user.user_meta.username}
          />
          <div className="inline-flex shrink grow basis-0 flex-col items-start justify-start">
            <div
              className={classNames(
                'max-w-32 self-stretch truncate text-sm font-bold leading-[18px] text-[#2b3674]',
                {
                  '!text-white': isMy && !canBid && place && isWin,
                }
              )}
            >
              {bid.user.user_meta.username}
            </div>
            <div className="inline-flex items-center justify-start gap-1 self-stretch">
              <div
                className={classNames(
                  'text-xs font-medium leading-none text-[#8f9bba]',
                  {
                    '!text-white': isMy && !canBid && place && isWin,
                  }
                )}
              >
                {dayjs(bid.created_at).fromNow()}
              </div>
            </div>
          </div>
          <div className="Info inline-flex flex-col items-end justify-center gap-0.5">
            {!!bid.bid_amount && (
              <div
                className={classNames(
                  'text-sm font-bold leading-[18px] text-[#2d396b]',
                  {
                    '!text-white': isMy && !canBid && place && isWin,
                  }
                )}
              >
                {formatNumber(bid.bid_amount.toString(), i18n.language)}
              </div>
            )}
            {!!(isMy && canBid && !isWin) ? (
              <div
                className={classNames(
                  ' text-[10px] font-bold leading-3 text-[#b0bbd5]',
                  {
                    '!text-white': isMy && !canBid && place && isWin,
                  }
                )}
              >
                {t('bid.Raise_the_bid')}
              </div>
            ) : place ? (
              <div
                className={classNames(
                  'text-[10px] font-bold leading-3 text-[#4318ff]',
                  {
                    '!text-white': isMy && !canBid && place && isWin,
                  }
                )}
              >
                {place === 1
                  ? `${t('bid.max_bet')}`
                  : isMin
                    ? `${t('bid.min_bet')}`
                    : `${place} ${t('bid.place')}`}
              </div>
            ) : null}
          </div>
        </div>
        {canBid && !isMy && (
          <MButton variant="transparent" className="!pl-2 !pr-0" onClick={onBid}>
            BID
          </MButton>
        )}
      </div>
    </>
  );
};
