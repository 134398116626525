import { Stream } from '@cloudflare/stream-react';
import React, { useEffect, useMemo, useState } from 'react';
import { getVideoTranslationFile } from '../../../../api/course';
import { ReactComponent as MdChevronLeft } from '../../../../assets/icons/chevron-left.svg';
import { Loader } from '../../../../components/Loader';
import {
  MediaViewer,
  VTTConfigItem,
} from '../../../../components/MediaViewer/MediaViewer';
import { SelectOptions } from '../../../../horizon-components/MMultSelect/MMultiSelect';
import { Unit, UnitItem } from '../../../../models/Course';
import { AiAssistant } from './AiAssistant';
import { TopicTest } from './TopicTest';
import './style.scss';

type Props = {
  currentUnit: Unit;
  currentUnitData: UnitItem;
  startExam: (unitId: number) => void;
  getResults: (unitItem: UnitItem) => void;
  languages?: SelectOptions[];
  isStaff?: boolean;
  isPreview?: boolean;
};

export const CourseContentBody: React.FC<Props> = ({
  currentUnit,
  currentUnitData,
  startExam,
  getResults,
  languages,
  isStaff,
  isPreview,
}) => {
  const [currentTopicIndex, setCurrentTopicIndex] = useState(0);
  const [isTopicTestActive, setIsTopicTestActive] = useState(false);
  const [vttFiles, setVttFiles] = useState<VTTConfigItem[]>([]);
  const [vttDownloaded, setVttDownloaded] = useState(false);

  useEffect(() => {
    setCurrentTopicIndex(0);
  }, [currentUnit?.id]);

  const totalTopics = currentUnitData?.topics?.length;
  const currentTopic = currentUnitData?.topics?.[currentTopicIndex];
  const disablePrev = currentTopicIndex === 0 && !isTopicTestActive;
  const nextStartsTest = currentTopicIndex === totalTopics - 1;

  const topicMedia = useMemo(
    () =>
      currentTopic?.contents?.filter(
        (content) => content.resourcetype === 'FileContent'
      ),
    [currentTopic?.contents]
  );
  const topicText = currentTopic?.contents?.find(
    (content) => content.resourcetype === 'TextContent'
  );
  const cfVideoStream = topicMedia?.[topicMedia.length - 1]?.cf_video_stream;
  const cfVideoGuid = cfVideoStream?.cf_signed_video_guid ?? cfVideoStream?.cf_uid;
  const videoUrl = topicMedia?.[topicMedia.length - 1]?.file;

  const onNextClick = () => {
    if (isPreview && nextStartsTest) {
      startExam(currentUnit?.id);
      return;
    }

    if (
      nextStartsTest &&
      !(
        !isTopicTestActive &&
        !!currentUnitData?.topics?.[currentTopicIndex]?.questions?.length
      )
    ) {
      currentUnit?.completed?.length === 0
        ? startExam(currentUnit?.id)
        : getResults(currentUnitData);
      return;
    }
    if (
      !isTopicTestActive &&
      !!currentUnitData?.topics?.[currentTopicIndex]?.questions?.length
    ) {
      setIsTopicTestActive(true);
      return;
    }

    setIsTopicTestActive(false);
    setCurrentTopicIndex(currentTopicIndex + 1);
  };

  const onPrevClick = () => {
    if (disablePrev) return;
    if (isTopicTestActive) {
      setIsTopicTestActive(false);
      return;
    }
    setCurrentTopicIndex(currentTopicIndex - 1);
  };

  const NavigationButtons = ({ showTitle = false }: { showTitle?: boolean }) => (
    <div className="flex items-center gap-3 sm-max:w-full">
      {/* <p className="text-base font-medium uppercase text-gray-600 dark:text-white">
        {totalTopics !== 1 && `Topic ${currentTopicIndex + 1}/${totalTopics}`}{' '}
        {isTopicTestActive && '(Test)'}
      </p> */}
      <div className="flex items-center justify-between gap-2 sm-max:w-full">
        <div
          onClick={onPrevClick}
          className={`linear flex h-[32px] w-[32px] items-center justify-center rounded-full border-[2px] border-primary-purple-blue-300 text-3xl text-primary-purple-blue-300 transition duration-200 hover:cursor-pointer hover:bg-primary-purple-blue-100 active:bg-primary-purple-blue-200 lg:h-[42px] lg:w-[42px] ${
            disablePrev && '!cursor-default opacity-50 hover:!bg-white'
          }`}
        >
          <MdChevronLeft />
        </div>
        {!!showTitle && (
          <div
            className={
              'overflow-hidden overflow-ellipsis font-bold text-secondary-dark-grey-900 md:hidden md:text-[20px]'
            }
          >
            {currentTopic?.title}
          </div>
        )}
        <div
          onClick={onNextClick}
          className={`linear flex h-[32px] w-[32px] items-center justify-center rounded-full bg-primary-purple-blue-500 text-3xl text-white transition duration-200 hover:cursor-pointer hover:bg-primary-purple-blue-600 active:bg-primary-purple-blue-700 lg:h-[42px] lg:w-[42px]`}
        >
          <MdChevronLeft style={{ transform: 'rotate(180deg)' }} />
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    const mediaId = topicMedia?.[topicMedia.length - 1]?.id;
    if (mediaId) {
      getVideoTranslationFile(mediaId)
        .then((data) => {
          if (data?.results?.length) {
            const _vtt = data.results.map((item) => {
              const lang = languages?.find((el) => el.label === item.language);

              return {
                srcLang: lang?.label || '',
                src: item.file,
              };
            });
            setVttFiles(_vtt);
          }
        })
        .finally(() => {
          setVttDownloaded(true);
        });
    }
  }, [languages, topicMedia]);

  return (
    <>
      {/* Header */}
      <div className="flex w-full items-center justify-between gap-3">
        <div
          className={
            'overflow-hidden overflow-ellipsis font-bold text-secondary-dark-grey-900 sm-max:hidden md:text-[20px]'
          }
        >
          {currentTopic?.title}
          {/* {currentUnitData.topics.length === 1 ? '' : currentTopic?.title} */}
        </div>
        <NavigationButtons showTitle />
      </div>
      {/*Content cf_signed_video_guid*/}
      {!isTopicTestActive && (
        <>
          {topicMedia && topicMedia.length > 0 && (
            <>
              {cfVideoGuid ? (
                <div className="relative overflow-hidden">
                  <Stream
                    src={cfVideoGuid}
                    className="stream z-10 !pt-[380px] md:!pt-[450px] lg:!pt-[530px]"
                    width="100%"
                    height="100%"
                    controls
                    key={currentTopic.id}
                  />
                  <Loader className="absolute top-[50%] z-0" />
                </div>
              ) : (
                videoUrl && (
                  <MediaViewer
                    link={videoUrl || ''}
                    vttFiles={vttFiles}
                    vttDownloaded={vttDownloaded}
                  />
                )
              )}
            </>
          )}
          {topicText && topicText.text && (
            <div dangerouslySetInnerHTML={{ __html: topicText.text }} />
          )}
          {/*AI assistant*/}
          <AiAssistant topicId={currentTopic?.id} />
        </>
      )}

      {/*Topic test*/}
      {isTopicTestActive && (
        <TopicTest
          topic={currentTopic}
          onNextClick={onNextClick}
          isStaff={isStaff}
          isPreview={isPreview}
        />
      )}
    </>
  );
};
