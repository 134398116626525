import React from 'react';
import { ReactComponent as FireIcon } from '../../assets/icons/fire.svg';

type Props = {
  className?: string;
};

export const MultiplierIcon = ({ className }: Props) => {
  return <FireIcon className={className || 'h-[18px] w-[18px]'} />;
};
