import { useTranslation } from 'react-i18next';
import { AuctionViewPlaceBid } from './auction-view-place-bid';
import { BottomSheetModal } from '../../../../horizon-components/bottom-sheet-modal';
import { useAuctionContext } from '../../../../providers/AuctionProvider/AuctionProvider';

export const AuctionViewRate = () => {
  const { t } = useTranslation(['translation']);
  const { setIsSendBidMode, isSendBidMode } = useAuctionContext();

  const handleToggle = () => {
    setIsSendBidMode(!isSendBidMode);
  };

  return (
    <>
      <BottomSheetModal
        close={handleToggle}
        opened={isSendBidMode}
        title={t('bid.Place_a_bet')}
      >
        <AuctionViewPlaceBid onShowBet={handleToggle} />
      </BottomSheetModal>
    </>
  );
};
