import React, { createContext, useContext, ReactNode } from 'react';
import useFetch from '../../hooks/useFetch';
import { getPublicCourseIdV3 } from '../../api/courseV3';
import { Course } from '../../models/Course';

interface CourseContextProps {
  course: Course | null;
  isLoading: boolean;
  error: Error | null;
  fetchCourse: (course_id: number) => void;
}

const CourseContext = createContext<CourseContextProps | undefined>(undefined);

export const useCourse = () => {
  const context = useContext(CourseContext);
  if (!context) {
    throw new Error('useCourseContext must be used within a CourseProvider');
  }
  return context;
};

interface CourseProviderProps {
  children: ReactNode;
}

export const CourseProvider: React.FC<CourseProviderProps> = ({ children }) => {
  const {
    data: course,
    isLoading,
    error,
    fetchData,
  } = useFetch((course_id: number) => getPublicCourseIdV3(course_id));

  const fetchCourse = (course_id: number) => {
    fetchData(course_id);
  };

  return (
    <CourseContext.Provider value={{ course, isLoading, error, fetchCourse }}>
      {children}
    </CourseContext.Provider>
  );
};
