import classNames from 'classnames';
import { ReactComponent as StarIcon } from '../../assets/icons/star-full.svg';
import { TaskProgressStatus } from '../../models/Tasks';

interface AwardsCardProps {
  title?: string;
  subTitle?: string;
  status?: TaskProgressStatus;
  image?: string;
}

export const AwardsCard = ({
  title,
  subTitle,
  status = 'REWARD_RECEIVED',
  image,
}: AwardsCardProps) => {
  return (
    <div
      className={classNames(
        'inline-flex w-full items-center justify-start gap-2',
        'rounded-2xl p-2 lg:gap-4 lg:rounded-[20px] lg:p-4',
        {
          'bg-[#e9e3ff]': status === 'RUNNING',
          'bg-white': status === 'REWARD_RECEIVED',
          'shadow-md shadow-shadow-700': status === 'REWARD_RECEIVED',
        }
      )}
    >
      {image ? (
        <div className="flex items-center justify-center gap-2 rounded-full border-2 border-[#d456ff] p-3 lg:rounded-2xl">
          <img className="relative h-[18px] w-[18px]" src={image} alt={title} />
        </div>
      ) : null}
      <div className="inline-flex w-full flex-col items-start justify-center gap-0.5">
        {title ? (
          <h4 className="self-stretch text-base font-semibold leading-tight text-[#2b3674]">
            {title}
          </h4>
        ) : null}
        {subTitle ? (
          <div className="inline-flex items-center justify-center gap-0.5 rounded-2xl">
            <span className="text-center text-xs font-semibold leading-none text-[#9374ff]">
              {subTitle}
            </span>
          </div>
        ) : null}
      </div>
      <div className="inline-flex h-[42px] items-center justify-center gap-2 rounded-3xl bg-[#c9fbd5] p-3">
        <StarIcon className="relative h-[18px] w-[18px] !fill-primary-green-500" />
      </div>
    </div>
  );
};
