import React from 'react';
import { DownloadMobileAppModal } from '../../../../../components/DownloadMobileAppModal';
import { BehaviourProps } from './base';

export const DownloadMobileAppBehaviour: React.FC<BehaviourProps> = ({
  stopBehaviour,
}) => {
  return (
    <div>
      <DownloadMobileAppModal showModal={true} onClose={() => stopBehaviour()} />
    </div>
  );
};
