export const moveTo = (
  targetPosition: { x: number; y: number },
  currentPosition: { x: number; y: number },
  speed: number,
  draw: (
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    width: number,
    height: number,
    img: CanvasImageSource,
    mirror: boolean
  ) => void,
  img: CanvasImageSource,
  width: number,
  height: number,
  mirror: boolean,
  canvas: HTMLCanvasElement,
  onComplete?: () => void
) => {
  const ctx = canvas.getContext('2d'); // Получаем контекст рисования

  // Проверка на null
  if (!ctx) {
    console.error('Не удалось получить контекст рисования');
    return; // Выход из функции, если контекст не получен
  }

  // Вычисление расстояния между текущей и целевой позицией
  const distance = Math.hypot(
    targetPosition.x - currentPosition.x,
    targetPosition.y - currentPosition.y
  );
  // Общее количество кадров для анимации, основанное на расстоянии и скорости
  const totalFrames = Math.ceil(distance / speed);
  // Переменная для отслеживания текущего кадра анимации
  let frame = 0;

  // Функция анимации, которая будет вызываться для обновления позиции персонажа
  const animate = () => {
    // Проверка, не достигли ли мы конечного кадра анимации
    if (frame < totalFrames) {
      // Сохраняем текущую позицию для очистки
      //const previousPosition = { x: currentPosition.x, y: currentPosition.y };

      // Обновление текущей позиции персонажа
      currentPosition.x +=
        (targetPosition.x - currentPosition.x) / (totalFrames - frame);
      currentPosition.y +=
        (targetPosition.y - currentPosition.y) / (totalFrames - frame);

      // Очистка только области, где находился персонаж
      //ctx.clearRect(previousPosition.x, previousPosition.y, width, height);

      // Очистка полностью canvas
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Отрисовка фона или других объектов, если необходимо
      // drawBackground(ctx); // Вызов функции для отрисовки фона

      // Отрисовка персонажа на новом месте
      draw(ctx, currentPosition.x, currentPosition.y, width, height, img, mirror);

      // Увеличение счетчика кадров
      frame++;
      // Запрос следующего кадра анимации
      requestAnimationFrame(animate);
    } else {
      // Если анимация завершена, устанавливаем текущую позицию на целевую
      currentPosition = targetPosition;
      // Убираем текущего персонажа
      ctx.clearRect(currentPosition.x, currentPosition.y, width, height); // Очистка персонажа
      // Если передан обратный вызов, вызываем его
      if (onComplete) {
        // Проверка, был ли передан onComplete
        onComplete(); // Вызов обратного вызова, если он был передан
      }
    }
  };

  // Запуск анимации
  animate();
};
