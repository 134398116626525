import { Auction, BidStatusEnum } from '../../models/Auction';
import dayjs from 'dayjs';

export const getAuctionStatus = (auction: Auction) => {
  const curTime = dayjs().utc();
  const auctionStartDateTime = dayjs(auction.start_datetime).subtract(1, 'second');
  const auctionEndDateTime = dayjs(auction.end_datetime).subtract(1, 'second');
  const afterStart = curTime.isAfter(auctionStartDateTime);
  const afterEnd = curTime.isAfter(auctionEndDateTime);

  let status = BidStatusEnum.COMPLETED;
  if (!afterStart) {
    status = BidStatusEnum.PLANNED;
  } else if (afterStart && !afterEnd) {
    status = BidStatusEnum.ACTIVE;
  }

  return status;
};

export const groupAuctionsByStatus = (auctions: Auction[]) => {
  const auctionsByStatus: Record<BidStatusEnum, Auction[]> = {
    [BidStatusEnum.COMPLETED]: [],
    [BidStatusEnum.ACTIVE]: [],
    [BidStatusEnum.PLANNED]: [],
  };
  if (!auctions) {
    return auctionsByStatus;
  }

  for (const auction of auctions) {
    const status = getAuctionStatus(auction);
    auctionsByStatus[status].push(auction);
  }

  return auctionsByStatus;
};
