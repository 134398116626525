import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';
import { getUserData } from '../../../../../utils/getUserData';
import { removeTagsAndSpaces } from '../../../../../shared/utils/removeTagsAndSpaces';
import {
  AuthRoutes,
  MainRoutes,
  ProfileRoutes,
} from '../../../../../horizon-layout/MainLayout/Routes/types/routes';
import { NoAvatar } from '../../../../../widgets/NoAvatar';
import { Link } from '../../../../../horizon-components/Link';
import { Loader } from '../../../../../components/Loader';
import { useCourseCreation } from '../../../../../providers/course-provider/course-creation-provider';

interface CoursePreviewDescriptionProps {
  className?: string;
}

export const CoursePreviewDescription = ({
  className,
}: CoursePreviewDescriptionProps) => {
  const { t } = useTranslation(['translation']);
  const { course } = useCourseCreation();
  const authorData = getUserData(course?.owner);

  if (!course?.id) return <Loader />;

  return (
    <div
      className={classNames(
        'flex flex-col gap-4 text-lg font-medium text-primary-primary lg-max:gap-2 lg-max:text-sm',
        className
      )}
    >
      {course?.description && !!removeTagsAndSpaces(course?.description).length && (
        <div>
          <div className="font-semibold text-secondary-grey-700">
            {t('course.Description')}
          </div>
          <div
            className={'w-full'}
            dangerouslySetInnerHTML={{
              __html: course?.description,
            }}
          />
        </div>
      )}
      {course?.requirements &&
        !!removeTagsAndSpaces(course?.requirements).length && (
          <div>
            <div className="font-semibold text-secondary-grey-700">
              {t('course.requirements')}
            </div>
            <div
              className={'w-full'}
              dangerouslySetInnerHTML={{
                __html: course?.requirements,
              }}
            />
          </div>
        )}
      {course?.learning_outcomes &&
        !!removeTagsAndSpaces(course?.learning_outcomes).length && (
          <div>
            <div className="font-semibold text-secondary-grey-700">
              {t('course.Recommendations')}
            </div>
            <div
              className={'w-full'}
              dangerouslySetInnerHTML={{
                __html: course?.learning_outcomes,
              }}
            />
          </div>
        )}
      {!!course?.created_at && (
        <div className="inline-flex flex-col gap-2">
          <div className="font-semibold text-secondary-grey-700">
            {t('course.The_author_of_the_course')}
          </div>

          {!!authorData.guid && (
            <Link
              to={
                MainRoutes.auth +
                AuthRoutes.profile +
                ProfileRoutes.user +
                `/${authorData?.guid}`
              }
            >
              <div className="inline-flex h-[34px] w-[183px] items-center justify-start gap-3 pl-1">
                <NoAvatar
                  src={authorData.image}
                  alt={authorData.username}
                  className="relative !h-[34px] !w-[34px] rounded-[35px]"
                />
                <div className="inline-flex shrink grow basis-0 flex-col items-start justify-start">
                  <div className="self-stretch text-sm font-bold leading-[18px] text-primary-primary">
                    {authorData.username}
                  </div>
                </div>
              </div>
            </Link>
          )}

          <div>
            <div className="font-semibold text-secondary-grey-700">
              {t('course.Date_of_publication')}
            </div>
            <span>{dayjs(course?.created_at).format('DD.MM.YY')}</span>
          </div>
        </div>
      )}
    </div>
  );
};
