// Функция для отрисовки объекта
export const drawObject = (
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number,
  img: CanvasImageSource,
  mirror: boolean = false,
  rotationDegrees?: number
) => {
  if (!img) return; // Если изображение не найдено, выходим из функции

  ctx.save(); // Сохраняем текущее состояние контекста
  ctx.translate(x + width / 2, y + height / 2); // Перемещаем контекст в центр объекта
  ctx.scale(mirror ? -1 : 1, 1); // Отзеркаливаем по оси X, если mirror задан как true
  if (rotationDegrees) {
    const rotation = rotationDegrees * (Math.PI / 180); // Преобразуем градусы в радианы
    ctx.rotate(rotation); // Поворачиваем контекст, если задан угол
  }

  // Устанавливаем сглаживание для улучшения качества изображения
  ctx.imageSmoothingEnabled = true; // Включаем сглаживание
  ctx.drawImage(img, -width / 2, -height / 2, width, height); // Рисуем изображение
  ctx.restore(); // Восстанавливаем состояние контекста
};

export const drawObjects = (
  ctx: CanvasRenderingContext2D,
  objects: Array<
    [number, number, number, number, string, boolean?, number?, number?]
  >,
  images: Record<string, CanvasImageSource>
) => {
  const sortedObjects = objects.sort((a, b) => {
    const zIndexA = a[7] !== undefined ? a[7] : 0;
    const zIndexB = b[7] !== undefined ? b[7] : 0;
    return zIndexA - zIndexB;
  });

  sortedObjects.forEach(
    ([x, y, width, height, imagePath, mirror, rotationDegrees]) => {
      const img = images[imagePath];
      drawObject(ctx, x, y, width, height, img, mirror, rotationDegrees);
    }
  );
};

export const drawButton = (
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number = 87,
  height: number = 35,
  backgroundImage: CanvasImageSource,
  content: string | CanvasImageSource | null = null,
  textColor: string = '#1C94B7',
  iconWidth: number = 20,
  iconHeight: number = 20
) => {
  ctx.drawImage(backgroundImage, x, y, width, height);

  if (content) {
    if (typeof content !== 'string') {
      ctx.drawImage(
        content,
        x + (width - iconWidth) / 2,
        y + (height - iconHeight) / 2,
        iconWidth,
        iconHeight
      );
    } else {
      ctx.fillStyle = textColor;
      ctx.font = 'bold 12px Arial';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';

      const lines = content.split('\n');
      const lineHeight = 14; // Высота строки (можно настроить)

      lines.forEach((line, index) => {
        ctx.fillText(
          line,
          x + width / 2,
          y + height / 2 - (lineHeight * (lines.length - 1)) / 2 + lineHeight * index
        );
      });
    }
  }
};
