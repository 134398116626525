import React from 'react';
import { DownloadMobileAppBehaviour } from './ui/DownloadMobileAppBehaviour';
import { BehaviourProps } from './ui/base';
import { InviteFriendBehaviour } from './ui/InviteFriendBehaviour';

export const BEHAVIOUR_MAPPING: Record<
  string,
  React.FC<BehaviourProps> | undefined
> = {
  DOWNLOAD_MOBILE_APP: DownloadMobileAppBehaviour,
  INVITE_FRIEND: InviteFriendBehaviour,
};
