import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getCourse } from '../../../api/course';
import { useCoursePerformance } from '../../../entities/Courses';
import { Course } from '../../../models/Course';
import { CoursePerfomanceDaily } from './CoursePerfomanceDaily/CoursePerfomanceDaily';
import { CoursePerfomanceHeader } from './CoursePerfomanceHeader/CoursePerfomanceHeader';
import { CoursePerfomanceProfit } from './CoursePerfomanceProfit/CoursePerfomanceProfit';
import { CoursePerfomanceTable } from './CoursePerfomanceTable/CoursePerfomanceTable';
import dayjs from 'dayjs';

export const CoursePerformance: React.FC = () => {
  const { id: courseId } = useParams();
  const [selectedDates, setSelectedDates] = React.useState<Date[]>(lastMonth());
  const [course, setCourse] = useState<Course | null>(null);
  const from = useMemo(() => {
    if (selectedDates[0]) {
      return dayjs(new Date(selectedDates[0])).format('YYYY-MM-DD');
    }
  }, [selectedDates]);
  const to = useMemo(() => {
    if (selectedDates[1]) {
      return dayjs(
        new Date(selectedDates[1]).setDate(new Date(selectedDates[1]).getDate() + 1)
      ).format('YYYY-MM-DD');
    }
  }, [selectedDates]);
  const { data, isLoading } = useCoursePerformance(Number(courseId), from, to);

  useEffect(() => {
    getCourse(courseId || '').then((data) => {
      setCourse(data);
    });
  }, [courseId]);

  function lastMonth() {
    const daysAgo = 30;
    // Получить текущую дату
    const currentDate = new Date();

    // Уменьшить текущую дату на заданное количество дней
    const targetDate = new Date(currentDate);
    targetDate.setDate(currentDate.getDate() - daysAgo);

    // Создать копию текущей даты
    const lastDay = new Date(currentDate);

    // Создать массив из первого и последнего дня
    return [targetDate, lastDay];
  }

  return (
    <div className="flex w-full flex-col gap-[8px] md:gap-6">
      <CoursePerfomanceHeader course={course} />
      <div
        className={'grid w-full grid-cols-1 gap-[8px] md:grid-cols-3 md:gap-[17px]'}
      >
        <div className={'flex flex-col items-start gap-[8px] md:gap-[16px]'}>
          <CoursePerfomanceProfit
            data={data?.profit_estimation}
            isLoading={isLoading}
          />
          <CoursePerfomanceDaily data={data} isLoading={isLoading} />
        </div>
        <div className={'col-span-2'}>
          <CoursePerfomanceTable
            dataTable={data?.units_report}
            onDates={setSelectedDates}
            dates={selectedDates}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  );
};
