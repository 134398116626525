import classNames from 'classnames';
import './LoaderMini.scss';

interface LoaderProps {
  className?: string;
}

export const LoaderMini = ({ className }: LoaderProps) => {
  return (
    <div className="LoaderMini">
      <div className={classNames('spinner', className)}></div>
    </div>
  );
};
