import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import { WebSocketWrapper } from '../api/socket/socket';
import { TNotifications } from '../models/Notifications';
import { useUserContext } from './UserProvider';
import { SOCKET_URL } from '../api/instance';
import { getAssesTokenStorage } from '../api';

type NotificationWebsocketContextType = {
  lastReceivedNotification: TNotifications | null;
};

const NotificationWebsocketContext = createContext<NotificationWebsocketContextType>(
  {} as NotificationWebsocketContextType
);

const NotificationsWebsocketProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { user, updateWallet } = useUserContext();
  const [wsWrapper, setWsWrapper] = useState<WebSocketWrapper | null>(null);
  const [lastReceivedNotification, setLastReceivedNotification] =
    useState<null | TNotifications>(null);

  const startConnection = async () => {
    const wrapper = new WebSocketWrapper(
      `${SOCKET_URL}/auth/ws/notifications/?token=${getAssesTokenStorage()}`
    );

    wrapper.events.onMessage = (message: MessageEvent<string>) => {
      updateWallet();
      const notificationParsed = JSON.parse(message.data) as TNotifications;
      setLastReceivedNotification(notificationParsed);
    };

    await wrapper.connect(true);
    setWsWrapper(wrapper);
  };

  useEffect(() => {
    if (user?.guid && !wsWrapper) {
      startConnection();
    }

    return () => {
      wsWrapper?.close();
      setWsWrapper(null);
    };
  }, [user?.guid]);

  return (
    <NotificationWebsocketContext.Provider
      value={{
        lastReceivedNotification,
      }}
    >
      {children}
    </NotificationWebsocketContext.Provider>
  );
};

const useNotificationsWebsocket = () => useContext(NotificationWebsocketContext);

export {
  NotificationWebsocketContext,
  NotificationsWebsocketProvider,
  useNotificationsWebsocket,
};
