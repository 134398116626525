import React from 'react';
import { PlatformsEnum } from '../../entities/Platforms/platforms';
import { ButtonsProps } from './types';
import getPlatform from '../../utils/getPlatform';
import ShareInstagramButton from './buttons/ShareInstagramButton';
import ShareTelegramButton from './buttons/ShareTelegramButton';
import ShareTwitterButton from './buttons/ShareTwitterButton';
import ShareFacebookButton from './buttons/ShareFacebookButton';
import ShareOtherButton from './buttons/ShareOtherButton';

const ShareDefaultButtons: React.FC<ButtonsProps> = (props) => {
  const platform = getPlatform();
  const isMobile = [PlatformsEnum.ANDROID, PlatformsEnum.IOS].includes(platform);

  const showInstagram = isMobile && !!props.hasFile;
  const showFacebook = platform !== PlatformsEnum.ANDROID;
  const showOther = !!navigator.share || isMobile;

  return (
    <div className={'ShareButtons__socials'}>
      {showInstagram && <ShareInstagramButton {...props} />}
      <ShareTelegramButton {...props} />
      <ShareTwitterButton {...props} />
      {showFacebook && <ShareFacebookButton {...props} />}
      {showOther && <ShareOtherButton {...props} />}
    </div>
  );
};

export default ShareDefaultButtons;
