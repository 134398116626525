import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import useFetch from '../../hooks/useFetch';
import { Auction, Bid, BidStatusEnum } from '../../models/Auction';
import { getAuctionById, getBidsV3 } from '../../api/bid';
import { getAuctionStatus } from '../../modules/Bid/helpers';
import { useTranslation } from 'react-i18next';

type Props = {
  auctionId: number;
  bidsPagesCount: number;
} & PropsWithChildren;

type AuctionProvider = {
  auction: Auction | null;
  isAuctionLoading: boolean;
  fetchAuction: (auction_id: number) => void;
  auctionStatus: BidStatusEnum | null;
  bids: Bid[];
  isBidsLoading: boolean;
  fetchBids: () => Promise<Bid[]>;
  actualBid: number;
  setActualBid: (bid: number) => void;
  isSendBidMode: boolean;
  setIsSendBidMode: (isSend: boolean) => void;
  getIsWinner: (myBid: Bid | null) => boolean;
  onTimeChange: () => void;
};

const AuctionContext = createContext<AuctionProvider>({} as AuctionProvider);

const AuctionProvider: React.FC<Props> = ({
  children,
  auctionId,
  bidsPagesCount,
}) => {
  const { t } = useTranslation(['translation']);
  const [actualBid, setActualBid] = useState(0);
  const [isSendBidMode, setIsSendBidMode] = useState(false);
  const [bids, setBids] = useState<Bid[]>([]);
  const [isBidsLoading, setIsBidsLoading] = useState(false);
  const [timeChanged, setTimeChanged] = useState(0);

  const onTimeChange = useCallback(() => {
    setTimeChanged(timeChanged + 1);
  }, [timeChanged]);

  const getBids = async () => {
    setIsBidsLoading(true);
    try {
      if (bidsPagesCount === 0) {
        setBids([]);
        return [];
      }
      const lastResult = await getBidsV3(auctionId, bidsPagesCount);
      lastResult.reverse();
      setBids(lastResult);
      return lastResult;
    } finally {
      setIsBidsLoading(false);
    }
  };

  const fetchBids = useCallback(() => {
    return getBids();
  }, [setIsBidsLoading, setBids, auctionId, bidsPagesCount]);

  const {
    data: auction,
    isLoading: isAuctionLoading,
    fetchData: fetchAuction,
  } = useFetch(getAuctionById);

  const auctionStatus = useMemo(() => {
    if (!auction) {
      return null;
    }
    return getAuctionStatus(auction);
  }, [auction, onTimeChange]);

  const getLowestWinningBid = useCallback(() => {
    if (!auction) {
      return 0;
    }
    const winnersCount = auction?.number_winners;
    return Number(bids?.find((_, idx) => idx + 1 === winnersCount)?.bid_amount) || 0;
  }, [auction, bids]);

  const getIsWinner = useCallback(
    (bid: Bid | null) => {
      if (!bid) {
        return false;
      }
      if (auctionStatus !== BidStatusEnum.COMPLETED) {
        return false;
      }
      if (!auction) return false;

      const lowestWinningBid = getLowestWinningBid();
      return Number(bid.bid_amount) >= lowestWinningBid;
    },
    [auctionStatus, auction, getLowestWinningBid]
  );

  useEffect(() => {
    fetchAuction(auctionId);
  }, [t, fetchAuction, auctionId]);

  useEffect(() => {
    fetchBids();
  }, [fetchBids, bidsPagesCount, onTimeChange]);

  return (
    <AuctionContext.Provider
      value={{
        bids,
        isBidsLoading,
        fetchBids,
        auction,
        isAuctionLoading,
        fetchAuction,
        setActualBid,
        setIsSendBidMode,
        isSendBidMode,
        actualBid,
        auctionStatus,
        getIsWinner,
        onTimeChange,
      }}
    >
      {children}
    </AuctionContext.Provider>
  );
};

const useAuctionContext = () => useContext(AuctionContext);

export { useAuctionContext, AuctionProvider };
