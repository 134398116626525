import dayjs from 'dayjs';

enum Time {
  MINUTE = 'minute',
  HOUR = 'hour',
  DAY = 'day',
  MONTH = 'month',
  YEAR = 'year',
}

export const getTimeAgo = (
  publishedDateTime: Date,
  abort?: 'minute' | 'hour' | 'day' | 'month' | 'year',
  format: string = 'DD/MM/YY HH:mm'
): [number | undefined, string] => {
  const currentDateTime: Date = new Date();
  const timeDifference: number =
    currentDateTime.getTime() - publishedDateTime.getTime();

  const minutes: number = Math.floor(timeDifference / (1000 * 60));
  const hours: number = Math.floor(timeDifference / (1000 * 60 * 60));
  const days: number = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const months: number = Math.floor(days / 30);
  const years: number = Math.floor(days / 365);

  if (
    abort === Time.MINUTE ||
    abort === Time.HOUR ||
    abort === Time.DAY ||
    abort === Time.MONTH ||
    abort === Time.YEAR
  ) {
    return [undefined, dayjs(publishedDateTime).format(format)];
  }

  if (minutes < 1) {
    return [undefined, 'comment.just_now'];
  } else if (minutes < 60) {
    return [minutes, 'comment.minute_ago'];
  } else if (hours < 24) {
    return [hours, 'comment.hour_ago'];
  } else if (days < 30) {
    return [days, 'comment.day_ago'];
  } else if (months < 12) {
    return [months, 'comment.month_ago'];
  } else {
    return [years, 'comment.year_ago'];
  }
};
