import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MButton } from '../../../../horizon-components/MButton/ui/MButton';
import { CompletedUnit, Grade } from '../../../../models/Course';
import { useModal } from '../../../../providers/ModalProvider';
import { useRewardContext } from '../../../../providers/RewardProvider';
import { CongratulationsModal } from '../../../../components/CongratulationsModal';
import { Share } from '../../../../components/Share/Share';
import { useShareReferal } from '../../../../hooks/useShareReferal';

type Props = {
  completedUnit: CompletedUnit;
  nextUnit: () => void;
  restartUnit: () => void;
  isLastUnit: () => boolean;
  summaryCourse: () => void;
  isGradeCompleted?: boolean;
  isSubjectCompleted?: boolean;
  subjectTitle?: string;
  grade?: string;
};

export const ExamResultInfo: React.FC<Props> = ({
  completedUnit,
  nextUnit,
  restartUnit,
  isLastUnit,
  summaryCourse,
  isGradeCompleted,
  isSubjectCompleted,
  subjectTitle,
  grade,
}) => {
  const { url, activationRewardAmount } = useShareReferal();
  const { openModal, closeModal, isModalOpen } = useModal();
  const { rewards } = useRewardContext();
  const gradeCongratsModalId = 'grade_congrats_modal';
  const subjectCongratsModalId = 'subject_congrats_modal';
  const { t } = useTranslation(['translation']);
  const rowClasses = 'flex flex-row justify-between items-center';
  const topicClasses =
    'text-[14px] font-medium text-secondary-dark-grey-900 md:text-[16px]';
  const resultContainerClasses =
    'rounded-[8px] md:px-[16px] md:py-[12px] px-[12px] py-[8px] font-semibold md:text-[14px] text-[12px]';
  const resultDefaultClasses = 'bg-[#C9FBD5] text-[#009B73]';
  const resultIncomeClasses = 'bg-[#E9E3FF] text-[#3311DB]';
  const hrClasses = 'border-[#E1E9F8] w-full border-1';

  const gradeContentMap: Record<Grade, string> = {
    A: 'bg-primary-green-100 text-primary-green-600',
    B: 'bg-primary-green-100 text-primary-green-600',
    C: 'bg-additional-orange-100 text-additional-orange-600',
    D: 'bg-additional-orange-100 text-additional-orange-600',
    F: 'bg-additional-pink-100 text-additional-pink-600',
  };

  const gradeCompletedReward = rewards?.find(
    (reward) => reward.tokenomic_type === 'class_completion'
  );
  const subjectCompletedReward = rewards?.find(
    (reward) => reward.tokenomic_type === 'subject_completion'
  );

  useEffect(() => {
    if (isSubjectCompleted) {
      openModal(subjectCongratsModalId);
    } else if (isGradeCompleted) {
      openModal(gradeCongratsModalId);
    }
  }, []);

  return (
    <>
      <div
        className={
          'verflow-hidden overflow-ellipsis text-[16px] font-bold md:text-[20px]'
        }
      >
        {t('course.unitExamResults')}
      </div>
      <div className={'flex flex-col gap-[16px] md:gap-[24px]'}>
        <div className={rowClasses}>
          <div className={topicClasses}>{t('course.Your_grade')}</div>
          <div
            className={`${resultContainerClasses} ${
              gradeContentMap[completedUnit.grade] || resultDefaultClasses
            }`}
          >
            {completedUnit.grade}
          </div>
        </div>
        <div className={rowClasses}>
          <div className={topicClasses}>GPA</div>
          <div
            className={`${resultContainerClasses} ${
              gradeContentMap[completedUnit.grade] || resultDefaultClasses
            }`}
          >
            {completedUnit.gpa}
          </div>
        </div>
        <div className={rowClasses}>
          <div className={topicClasses}>{t('course.Percentage')}</div>
          <div
            className={`${resultContainerClasses} ${
              gradeContentMap[completedUnit.grade] || resultDefaultClasses
            }`}
          >
            {completedUnit.percentage_correct}
          </div>
        </div>
        <hr className={hrClasses} />
        <div className={rowClasses}>
          <div className={topicClasses}>{t('course.Earned')}</div>
          <div className={`${resultContainerClasses} ${resultIncomeClasses}`}>
            {completedUnit.er_points_income.split('.')[0]} {t('course.EDRES_POINTS')}
          </div>
        </div>
        <hr className={hrClasses} />
        <div className={'flex justify-center'}>
          <Share
            url={url}
            loading={!url}
            text={t('main.share.description', {
              activationRewardAmount,
            })}
          />
        </div>
        <div className={'flex justify-between'}>
          <MButton color={'primary'} variant={'outline'} onClick={restartUnit}>
            {t('course.Retake')}
          </MButton>
          {!isLastUnit() ? (
            <MButton color={'primary'} variant={'highlighted'} onClick={nextUnit}>
              {t('course.Next_unit')}
            </MButton>
          ) : (
            <MButton
              color={'primary'}
              variant={'highlighted'}
              onClick={summaryCourse}
            >
              {t('course.Summary')}
            </MButton>
          )}
        </div>
      </div>
      <CongratulationsModal
        title={t('reward_modal.congratulations')}
        description={t('reward_modal.grade_completed', {
          grade: grade,
          rewardAmount: gradeCompletedReward?.value?.toLocaleString(),
        })}
        isModalOpen={isModalOpen(gradeCongratsModalId)}
        onClose={() => closeModal(gradeCongratsModalId)}
      />
      <CongratulationsModal
        title={t('reward_modal.congratulations')}
        description={t('reward_modal.subject_completed', {
          subject: subjectTitle,
          rewardAmount: subjectCompletedReward?.value?.toLocaleString(),
        })}
        isModalOpen={isModalOpen(subjectCongratsModalId)}
        onClose={() => closeModal(subjectCongratsModalId)}
      />
    </>
  );
};
