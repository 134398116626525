import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow.svg';
import './style.scss';

interface SelectCourseProps {
  className?: string;
  onClick?: () => void;
}

export const SelectCourse = ({ className, onClick }: SelectCourseProps) => {
  const { t } = useTranslation(['translation']);
  return (
    <div className={classNames('SelectCourse', className)} onClick={onClick}>
      <div>{t('course.Choose_a_course_yourself')}</div>
      <ArrowIcon />
    </div>
  );
};
