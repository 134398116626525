import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  getPrivateFavouriteCoursesV3,
  getPublicCoursesV3,
} from '../../../../api/courseV3';
import { Loader } from '../../../../components/Loader';
import { NotFound } from '../../../../components/NotFound/NotFound';
import { useMyCourses } from '../../../../entities/Courses';
import { PlatformsEnum } from '../../../../entities/Platforms/platforms';
import { useUserMeta } from '../../../../entities/User';
import { Logout } from '../../../../features/Logout';
import { ProfileCard } from '../../../../features/ProfileCard';
import { ProfileReferal } from '../../../../features/ProfileReferal';
import { Link } from '../../../../horizon-components/Link';
import { MButton } from '../../../../horizon-components/MButton';
import {
  EduRoutes,
  MainRoutes,
} from '../../../../horizon-layout/MainLayout/Routes/types/routes';
import { CourseCardItem, CourseStatus } from '../../../../models/Course';
import { ResponsePagination } from '../../../../models/Response';
import { useUserContext } from '../../../../providers';
import { useModal } from '../../../../providers/ModalProvider';
import { generateHashFromGuid } from '../../../../utils/getUserData';
import { getRandomUserImage } from '../../../../utils/randomUserImage';
import { AsideMenu } from '../../../../widgets/AsideMenu';
import { CourseCard } from '../../../../widgets/CourseCard';
import { ProfileCreateCourse } from '../../../../widgets/ProfileCreateCourse';
import { ProfileWallet } from '../../../../widgets/ProfileWallet';
import { DeletedProfile } from './DeletedProfile';
import { PublicProfileHeader } from './PublicProfileHeader';
import getPlatform from '../../../../utils/getPlatform';
import SEO from '../../../../components/SEO/SEO';
import './PublicProfile.scss';
import { ProfileTasksContent } from '../../profile-tasks/ui/profile-tasks-content';

export const PublicProfile: React.FC = () => {
  const { t } = useTranslation(['translation']);
  const { user, wallet } = useUserContext();
  const { id: anotherUserGuid } = useParams();
  const profileId = anotherUserGuid || user?.guid;
  const { data: userMeta } = useUserMeta(profileId || '');
  const [selected, setSelected] = useState<string>('tasks');
  const { openModal } = useModal();
  const {
    data: verifiedCourses,
    isLoading: isVerifiedLoading,
    fetchData: fetchVerifiedData,
  } = useMyCourses();
  const [favouriteCourses, setFavouriteCourses] =
    useState<ResponsePagination<CourseCardItem> | null>(null);
  const platform = getPlatform();
  const [isFavouritesLoading, setIsFavouritesLoading] = useState(false);
  const fetchFavouriteCourses = async (page: number) => {
    if (favouriteCourses) {
      return;
    }
    setIsFavouritesLoading(true);
    try {
      const favouriteIds = await getPrivateFavouriteCoursesV3();
      if (favouriteIds.length > 0) {
        const favouriteCourses_ = await getPublicCoursesV3({
          id__in: favouriteIds.join(','),
          page,
        });
        setFavouriteCourses(favouriteCourses_);
      }
    } finally {
      setIsFavouritesLoading(false);
    }
  };

  const asideMenu = useMemo(
    () => [
      { id: 'tasks', title: t('course.tasks'), isShow: true },
      // { id: 'courses', title: t('course.Courses'), isShow: true },
      {
        id: 'favorites',
        title: t('feed.favorites'),
        isShow: user?.guid === profileId,
      },
    ],
    [t, user?.guid, profileId]
  );
  const mobileMenu = useMemo(
    () => [
      { id: 'tasks', title: t('course.tasks'), isShow: true },
      { id: 'info', title: t('course.Information'), isShow: true },
      // { id: 'courses', title: t('course.Courses'), isShow: true },
      {
        id: 'favorites',
        title: t('feed.favorites'),
        isShow: user?.guid === profileId,
      },
    ],
    [t, user?.guid, profileId]
  );
  useEffect(() => {
    selected === 'courses' && fetchVerifiedData(CourseStatus.Verified);
    selected === 'favorites' && fetchFavouriteCourses(1);
  }, [selected, t, profileId]);
  const coverSrc = useMemo(() => {
    let src =
      userMeta?.cover_image ||
      getRandomUserImage(generateHashFromGuid(profileId || ''));
    return src;
  }, [profileId, userMeta?.cover_image]);
  if (profileId === 'undefined') {
    return <DeletedProfile />;
  }
  if (!user) return null;
  const handleOpen = () => {
    openModal('details');
  };

  return (
    <div className="PublicProfile">
      <SEO
        title={t('pages.profile.meta.title')}
        desc={t('pages.profile.meta.desc')}
      />
      <PublicProfileHeader />
      <div
        className="-mx-2 -mb-11 block h-[88px] bg-cover bg-center bg-no-repeat sm:h-40 lg:hidden"
        style={{ backgroundImage: `url(${coverSrc})` }}
      />
      <div className={`PublicProfile__content`}>
        <ProfileCard userMeta={userMeta} guid={profileId} />
        <div className="PublicProfile__head">
          <ProfileWallet wallet={wallet?.value || '0'} onClick={handleOpen} />
          <ProfileReferal />
        </div>
      </div>
      <div className="PublicProfile__nav">
        {mobileMenu
          .filter((menu) => menu.isShow)
          .map((menu) => (
            <MButton
              onClick={() => setSelected(menu.id)}
              key={menu.id}
              variant={selected === menu.id ? 'highlighted' : 'secondary'}
              color="tab"
            >
              {menu.title}
            </MButton>
          ))}
      </div>
      <div className={`PublicProfile__content !items-start lg:grid-cols-2`}>
        {selected === 'info' && (
          <div className="flex flex-col gap-2 lg:hidden">
            <ProfileWallet wallet={wallet?.value || '0'} onClick={handleOpen} />
            <ProfileReferal />
            {[PlatformsEnum.WEB, PlatformsEnum.ANDROID].includes(platform) && (
              <>
                <ProfileCreateCourse />
                {/* <ProfileTasksCard /> */}
              </>
            )}
            {platform !== PlatformsEnum.TELEGRAM && (
              <div className="PublicProfile__card">
                <Logout />
              </div>
            )}
          </div>
        )}
        {/* {selected === 'tasks' && <ProfileTasksContent />} */}
        <div className="hidden flex-col gap-5 lg:flex">
          <AsideMenu
            menu={asideMenu.filter((menu) => menu.isShow)}
            onClick={setSelected}
            active={selected}
          />
          {/* <ProfileTasksCard /> */}
          <ProfileCreateCourse />
        </div>
        <div className="lg:col-span-2">
          {isVerifiedLoading || isFavouritesLoading ? (
            <Loader />
          ) : selected === 'courses' ? (
            !!verifiedCourses?.length ? (
              <div className={'flex w-full flex-wrap gap-2 lg:gap-5'}>
                <div
                  className={
                    'grid w-full grid-cols-1 flex-wrap gap-4 md:grid-cols-2 lg:grid-cols-3 4xl:grid-cols-4'
                  }
                >
                  {verifiedCourses?.map((course, i) => (
                    <CourseCard
                      course={course as unknown as CourseCardItem}
                      key={course.id}
                    />
                  ))}
                </div>
              </div>
            ) : (
              <div>
                <NotFound text={' '} />
                <Link to={MainRoutes.edu + EduRoutes.courses}>
                  <MButton
                    variant="highlighted"
                    color="primary"
                    className="m-auto w-full lg:w-auto"
                    size="lg"
                  >
                    {t('buttons.Find_courses')}
                  </MButton>
                </Link>
              </div>
            )
          ) : null}

          {selected === 'favorites' ? (
            <>
              {!!favouriteCourses?.results?.length && (
                <div className={'flex w-full flex-wrap gap-2 lg:gap-5'}>
                  <div
                    className={
                      'grid w-full grid-cols-1 flex-wrap gap-4 md:grid-cols-2 lg:grid-cols-3 4xl:grid-cols-4'
                    }
                  >
                    {favouriteCourses?.results?.map((course, i) => (
                      <CourseCard
                        course={course as unknown as CourseCardItem}
                        key={course.id}
                        favorite={true}
                      />
                    ))}
                  </div>
                </div>
              )}
              {!favouriteCourses?.results?.length && !isFavouritesLoading && (
                <div>
                  <NotFound text={' '} />
                  <Link to={MainRoutes.edu + EduRoutes.courses}>
                    <MButton
                      variant="highlighted"
                      color="primary"
                      className="m-auto w-full lg:w-auto"
                      size="lg"
                    >
                      {t('buttons.Find_courses')}
                    </MButton>
                  </Link>
                </div>
              )}
            </>
          ) : null}

          {selected === 'tasks' ? <ProfileTasksContent /> : null}
        </div>
      </div>
    </div>
  );
};
