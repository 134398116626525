/* eslint-disable @typescript-eslint/no-unused-vars */
import { useTranslation } from 'react-i18next';
import { MButton } from '../../../horizon-components/MButton';
import { useEffect, useState } from 'react';
import { Modal } from '../../../horizon-components/Modal';
import { Character2 } from '../LevelMap/items/winterItems';
import { setUserSomeDataStorage, getUserSomeDataStorage } from '../../../api';
import { useUserContext } from '../../../providers';
import { selectProgressLevelData } from '../../../stores/subject';
import { useSelector } from 'react-redux';

const StartGameModal = () => {
  const { t } = useTranslation(['translation']);
  const [startModalIsOpen, setStartModalIsOpen] = useState(false);
  const closeModal = () => {
    setUserSomeDataStorage('startModal', today, user?.guid);
    setStartModalIsOpen(false);
  };
  const { user } = useUserContext();
  const today = new Date().toLocaleDateString();
  const progressLevelData = useSelector(selectProgressLevelData);

  useEffect(() => {
    if (
      progressLevelData !== null &&
      getUserSomeDataStorage('startModal', user?.guid) === undefined
    ) {
      setStartModalIsOpen(true);
    }
  }, [progressLevelData, user?.guid]);

  const playGame = () => {
    setUserSomeDataStorage('startModal', today, user?.guid);
    closeModal();
  };
  return (
    <Modal
      isOpen={startModalIsOpen}
      onClose={closeModal}
      title={t('map.Hello')}
      bodyClassName="!w-[300px] sm:!w-[474px]"
    >
      <div className="relative overflow-hidden">
        <div
          className=" w-full rounded-[20px] pb-[40px] pl-[26px] pr-[16px] pt-[12px] sm:pb-[85px]"
          style={{
            background: 'linear-gradient(to right, #C414FF, #471FF7)',
          }}
        >
          <p className="text-[16px] font-semibold text-white">
            {t('map.StartModalInfo')}
          </p>
        </div>
        <img
          className="absolute right-[-30px] top-[95px] z-10 w-[165px] scale-x-[-1] transform sm:top-[75px] sm:w-[182px]"
          style={{ transform: 'scaleX(-1) rotate(-13.11deg)' }}
          src={Character2}
          alt=""
        />
        <MButton
          variant="highlighted"
          color="primary"
          className="relative z-20 mt-[24px] w-full sm-max:w-full md:!px-8 md:!py-6 md:!text-lg"
          onClick={playGame}
        >
          Start
        </MButton>
      </div>
    </Modal>
  );
};

export default StartGameModal;
