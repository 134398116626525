import { characterPositions } from '../constants';

export function calculatePosition(level: number, progress: number) {
  let start, end;

  if (level >= 1 && level <= 5) {
    start = characterPositions.elementary[level - 1]; // Начальная позиция для elementary
    end = characterPositions.elementary[level]; // Конечная позиция для elementary
  } else if (level >= 6 && level <= 8) {
    start = characterPositions.middle[level - 6]; // Начальная позиция для middle
    end = characterPositions.middle[level - 5]; // Конечная позиция для middle
  } else if (level >= 9 && level <= 12) {
    start = characterPositions.high[level - 9]; // Начальная позиция для high
    end = characterPositions.high[level - 8]; // Конечная позиция для high
  } else {
    return { x: 0, y: 0 }; // Возвращаем координаты по умолчанию, если уровень не существует
  }

  const x = start.x + (end.x - start.x) * progress; // Вычисляем координату X
  const y = start.y + (end.y - start.y) * progress; // Вычисляем координату Y

  return { x, y };
}
