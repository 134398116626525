import instance, { WEBER_API_URL } from './instance';
import {
  Course,
  CourseCardItem,
  CourseStatus,
  CourseTopicQuestion,
  CourseUnitQuestion,
  ImageItem,
  MyCourseCardItem,
  Rejection,
  StartedExam,
  TAttachFile,
  TMailing,
  Topic,
  TopicContent,
  TQuestionItem,
  TSummary,
  UnitExamQuestion,
  UnitExamResult,
  UnitItem,
  UploadTopicImage,
} from '../models/Course';
import { ResponsePagination } from '../models/Response';
import axios from 'axios';
import { Performance } from '../models/Performance';
import { TaxonomyEnum } from '../models/AiAssistant';

export enum CoursesMode {
  DISCOVER = 'DISCOVER',
  MY_COURSES = 'MY_COURSES',
}

export enum SortBy {
  POPULAR = 'POPULAR',
  LATEST = 'LATEST',
  RATING = 'RATING',
}

export enum CourseCategory {
  SCHOOL = '1',
  HIGH_SCHOOL = '2',
  UNIVERSITY = '3',
}

export function getSummary(courseId: number): Promise<TSummary> {
  return instance()
    .get(`/course-creation/course-result/`, {
      params: { course: courseId },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getMyCourses(status?: CourseStatus): Promise<Course[]> {
  return instance(true, false)
    .get(`/course-creation/my-course/`, { params: { status } })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function deleteMyCours(id?: number): Promise<Course[]> {
  return instance()
    .delete(`/course-creation/my-course/${id}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getVerificationCourses(
  created_at_after: string,
  created_at_before: string,
  page?: number
): Promise<ResponsePagination<Course>> {
  return instance()
    .get(`/user-courses/course-verification/`, {
      params: { created_at_after, created_at_before, page: (page || 0) + 1 },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getCourse(courseId: string): Promise<Course> {
  return instance()
    .get(`/course-creation/my-course/${courseId}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getCourseRejectComment(
  courseId: string
): Promise<{ rejections: Rejection[] }> {
  return instance()
    .get(`/course-with-rejections/${courseId}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getCourseVerification(courseId: string): Promise<Course> {
  return instance()
    .get(`/user-courses/course-verification/${courseId}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function unitExamStaff(
  unit: number,
  course: number
): Promise<UnitExamQuestion[]> {
  return instance()
    .get(`/verify-course-units/`, { params: { unit, course } })
    .then((response) => response.data?.results?.[0]?.questions || [])
    .catch((error) => {
      throw error;
    });
}

export function getCourseUnit(course: number): Promise<UnitItem[]> {
  return instance()
    .get(`/course-creation/my-course-unit/`, {
      params: { course },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function createTopic(unit: number, title: string): Promise<Topic> {
  return instance()
    .post(`/course-creation/my-course-topic/`, {
      unit,
      title,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function sendToVerification(courseId: number): Promise<Topic> {
  return instance()
    .post(`/course-creation/my-course/${courseId}/send_to_verification/`, undefined)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function deleteTopic(topicId: number): Promise<Topic> {
  return instance()
    .delete(`/course-creation/my-course-topic/${topicId}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function changeTopic(data: Topic): Promise<Topic> {
  return instance()
    .put(`/course-creation/my-course-topic/${data.id}/`, data)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function attachContent(
  topicId: number,
  resourceType: 'TextContent' | 'FileContent',
  file?: File,
  text?: string
): Promise<TopicContent> {
  const body = new FormData();
  if (file) {
    body.append('file', file);
  }
  if (text) {
    body.append('text', text);
  }
  body.append('resourcetype', resourceType);
  return instance()
    .post(`/course-creation/my-course-topic/${topicId}/attach_content/`, body)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function updateContent(
  contentId: number,
  text?: string
): Promise<TopicContent> {
  const body = new FormData();
  if (text) {
    body.append('text', text);
  }
  return instance()
    .put(`/course-creation/my-course-topic/update-content/${contentId}/`, body)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function requestUploadS3Url(
  topicId: number,
  fileName: string,
  fileSize: number
): Promise<{ upload_url: string; file_name: string; file_size: number }> {
  return instance()
    .post(`/course-creation/my-course-topic/${topicId}/attach_file_s3_direct/`, {
      file_name: fileName,
      file_size: fileSize,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function attachFileCfDirect(
  topicId: number,
  videoUid: string
): Promise<TAttachFile> {
  return instance()
    .post(`/course-creation/my-course-topic/${topicId}/attach_file_cf_submit/`, {
      uid: videoUid,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function uploadFileToS3(url: string, file: File): Promise<void> {
  return axios
    .put(url, file, {
      headers: { 'Content-Type': file.type },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function submitS3FileUploaded(
  topicId: number,
  fileName: string
): Promise<TopicContent> {
  return instance()
    .post(
      `/course-creation/my-course-topic/${topicId}/submit_attach_file_s3_direct/`,
      {
        file_name: fileName,
      }
    )
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function detachContent(
  topicId: number,
  content_id: number
): Promise<TopicContent> {
  return instance()
    .post(`/course-creation/my-course-topic/${topicId}/detach_content/`, {
      content_id,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function attachVideoFile(
  fileId: string,
  language: string,
  file: File
): Promise<Course> {
  const body = new FormData();
  body.append('file', file);
  body.append('file_content', fileId);
  body.append('language', language);
  return instance()
    .post(`/course-creation/file-content-translation/`, body)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getVideoTranslationFile(fileId: number): Promise<
  ResponsePagination<{
    language: string;
    file: string;
    file_content: number;
    id: number;
  }>
> {
  return instance()
    .get(`/course-creation/file-content-translation/`, {
      params: { file_content: fileId },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function detachVideoFile(videoId: number): Promise<Course> {
  return instance()
    .delete(`/course-creation/file-content-translation/${videoId}`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function attachIntroductionFile(
  courseId: number,
  file: File
): Promise<Course> {
  const body = new FormData();
  body.append('file', file);
  return instance()
    .post(`/course-creation/my-course/${courseId}/attach_introduction_file/`, body)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function generateIntroductionFileDirectUploadUrl(
  courseId: number,
  fileName: string,
  fileSize: number
): Promise<{ upload_url: string; file_name: string; file_size: number }> {
  return instance()
    .post(
      `/course-creation/my-course/${courseId}/introduction_file_generate_direct_upload_url/`,
      {
        file_name: fileName,
        file_size: fileSize,
      }
    )
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function submitIntroductionFileDirectUpload(
  courseId: number,
  fileName: string
): Promise<Course> {
  return instance()
    .post(
      `/course-creation/my-course/${courseId}/introduction_file_submit_direct_upload/`,
      {
        file_name: fileName,
      }
    )
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function detachIntroductionFile(
  courseId: number,
  file_id: number
): Promise<Course> {
  return instance()
    .post(`/course-creation/my-course/${courseId}/detach_introduction_file/`, {
      file_id,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function coveredImageList(): Promise<ImageItem[]> {
  return instance()
    .get(`/course-creation/predefined-cover-images-list/`, {
      params: { page: 1 },
    })
    .then((response) => response.data.results)
    .catch((error) => {
      throw error;
    });
}

export function textContentImage(
  file: Blob,
  fileName: string,
  topic: number
): Promise<UploadTopicImage> {
  const body = new FormData();
  body.append('file', file, fileName);
  body.append('topic', topic.toString());

  return instance()
    .post(`/course-creation/text-content-image/`, body)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function createQuestions(
  body: CourseUnitQuestion
): Promise<CourseUnitQuestion> {
  return instance()
    .post(`/questions/unit/questions-answers/`, body)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getQuestions(course_unit: number): Promise<TQuestionItem[]> {
  return instance()
    .get(`/questions/unit/${course_unit}/questions-answers/`)
    .then((response) => response.data.results)
    .catch((error) => {
      throw error;
    });
}

export function createQuestionsTopic(
  body: CourseTopicQuestion
): Promise<CourseTopicQuestion> {
  return instance()
    .post(`/questions/topic/questions-answers/`, body)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function deleteTopicAnswer(answerId: number): Promise<void> {
  return instance()
    .delete(`/questions/topic-question-answers/${answerId}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function deleteUnitAnswer(answerId: number): Promise<void> {
  return instance()
    .delete(`/questions/unit-question-answers/${answerId}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function deleteTopicQuestion(questionId: number): Promise<void> {
  return instance()
    .delete(`/questions/topic-question/${questionId}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function deleteUnitQuestion(questionId: number): Promise<void> {
  return instance()
    .delete(`/questions/unit-question/${questionId}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export type ApplyStep1Body = {
  title?: string;
  subject?: number;
  subject_level?: number;
  subject_level_units?: number[];
  language?: string;
  description?: string;
  tags?: string[];
  outcomes?: string[];
  cover_image?: string | File;
  cover_image_predefined?: number;
};

export function changeStep1(
  courseId: string,
  body: ApplyStep1Body
): Promise<Course> {
  return instance()
    .put(`/course-creation/my-course/${courseId}/`, body)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function createCourse(body: ApplyStep1Body): Promise<Course> {
  return instance()
    .post(`/course-creation/my-course/`, body)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function applyStep2(courseId: string): Promise<Course> {
  return instance()
    .post(`/course-creation/my-course/${courseId}/apply_step_2/`, undefined)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function applyStep3(
  courseId: string,
  cover_image?: File,
  cover_image_predefined?: number
): Promise<Course> {
  const body = new FormData();
  if (cover_image) {
    body.append('cover_image', cover_image);
  }
  if (cover_image_predefined) {
    body.append('cover_image_predefined', cover_image_predefined.toString());
  }
  return instance()
    .post(`/course-creation/my-course/${courseId}/apply_step_3/`, body)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function applyStep4(
  courseId: string,
  requirements: string,
  learning_outcomes: string
): Promise<Course> {
  return instance()
    .post(`/course-creation/my-course/${courseId}/apply_step_4/`, {
      requirements,
      learning_outcomes,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function applyStep5(
  courseId: string,
  welcome_message: string,
  congratulations_message: string
): Promise<Course> {
  return instance()
    .post(`/course-creation/my-course/${courseId}/apply_step_5/`, {
      welcome_message,
      congratulations_message,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

// user courses
export type CourseRequest = {
  page: number;
  mode?: CoursesMode;
  sort_by?: SortBy;
  category?: CourseCategory;
  subject?: string[];
  title?: string;
  language?: string;
  owner?: string;
  owner_guid?: string;
  created_at_after?: string;
  created_at_before?: string;
  exclude_owner?: string;
  subject_level?: number;
  subject_level_units?: number[];
  digital_pass?: number;
  digital_pass_category?: number;
  subject_level_range_min?: number;
  subject_level_range_max?: number;
  tags_or_title?: string[];
  miscellaneous_subject?: boolean;
  id__in?: string;
};
export function getUserCourses({
  title,
  page,
  subject,
  mode,
  sort_by,
  category,
  language,
  owner,
  owner_guid,
  created_at_after,
  created_at_before,
  exclude_owner,
  digital_pass,
  digital_pass_category,
  subject_level,
  subject_level_units,
  subject_level_range_min,
  subject_level_range_max,
  tags_or_title,
  miscellaneous_subject,
}: CourseRequest): Promise<ResponsePagination<CourseCardItem>> {
  return instance()
    .get(`/user-courses/course-view/`, {
      params: {
        title,
        page,
        subject,
        mode,
        sort_by,
        category,
        language,
        owner,
        owner_guid,
        created_at_after,
        created_at_before,
        exclude_owner,
        digital_pass,
        digital_pass_category,
        subject_level,
        subject_level_units,
        subject_level_range_min,
        subject_level_range_max,
        tags_or_title,
        miscellaneous_subject,
      },
      paramsSerializer: {
        indexes: null,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getUserCoursesRecommended(page?: number): Promise<CourseCardItem[]> {
  return instance(true, false)
    .get(`/user-courses/recommended/`, {
      params: { page },
      paramsSerializer: {
        indexes: null,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getUserCoursesStarted(page?: number): Promise<CourseCardItem[]> {
  return instance(true, false)
    .get(`/user-courses/started/`, {
      params: { page },
      paramsSerializer: {
        indexes: null,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getUserCourseById(courseId: number): Promise<Course> {
  return instance()
    .get(`/user-courses/course-view/${courseId}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function enrollUserCourse(courseId: number): Promise<void> {
  return instance()
    .post(`/user-courses/course-view/${courseId}/enroll/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getEnrolledUserCourses(page?: number): Promise<MyCourseCardItem[]> {
  return instance()
    .get(`/user-courses/enrolled-course-view/`, {
      params: { page },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function startUnitExam(unitId: number): Promise<{ id: number }> {
  return instance()
    .post(`/user-courses/unit-view/${unitId}/start_unit_exam/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function restartUnitExam(unitId: number): Promise<{
  id: number;
  ended_at: string;
  enrolled_course: number;
  started_at: string;
  unit: number;
  user_guid: string;
}> {
  return instance()
    .post(`/user-courses/unit-view/${unitId}/restart_unit_exam/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function startUnitExamTeacher(unitId: number): Promise<{ id: number }> {
  return instance()
    .post(`/course-creation/teacher-unit-exams/${unitId}/start-exam/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function resetUnitExamTeacher(unitId: number): Promise<{ id: number }> {
  return instance()
    .post(`/course-creation/teacher-unit-exams/${unitId}/reset-exam/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getUnitExam(examId: number): Promise<StartedExam> {
  return instance()
    .get(`/user-courses/started-unit-exam/${examId}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function setUnitExamAnswer(
  examId: number,
  body: { id: number; answer: number[] }
): Promise<void> {
  return instance()
    .post(`/user-courses/started-unit-exam/${examId}/set_answer/`, body)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getMyAnswers(
  unitId: number
): Promise<{ answer: number[]; question: number }[]> {
  return instance()
    .get(`/user-courses/started-unit-exam/${unitId}/my_answers/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function finishExam(examId: number): Promise<UnitExamResult> {
  return instance()
    .post(`/user-courses/started-unit-exam/${examId}/finish_exam/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function checkEnrolledUserCourse(
  id: number
): Promise<{ is_enrolled: boolean }> {
  return instance()
    .get(`/user-courses/${id}/check-enrollment/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function checkPurchaseDigitalPass(id: number): Promise<{
  purchased: boolean;
  digital_pass_category_id?: number;
  digital_pass_id?: number;
}> {
  return instance()
    .get(`/digital-passes/purchased-check/${id}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getUnitView(unitId: number): Promise<UnitItem> {
  return instance()
    .get(`/user-courses/unit-view/${unitId}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getUnitViewTeacher(unitId: number): Promise<UnitItem> {
  return instance()
    .get(`/course-creation/course-unit-detail/${unitId}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getEnrolledUserCourseById(
  courseId: number
): Promise<{ id: number; status: string; course: Course }> {
  return instance()
    .get(`/user-courses/enrolled-course-view/${courseId}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getCourseViewById(courseId: number): Promise<Course> {
  return instance()
    .get(`/user-courses/course-view/${courseId}/retrieve_any_status/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getCoursePerformanceById(
  courseId: number,
  date_from?: string,
  date_to?: string
): Promise<Performance> {
  return instance()
    .get(`/expert/report/${courseId}/`, {
      params: {
        date_from,
        date_to,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getPopularTopics(): Promise<CourseCardItem[]> {
  return instance()
    .get(`/user-courses/popular/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function addFavoriteCourse(courseId: number): Promise<void> {
  return instance()
    .post(`/user-courses/favorites/`, {
      course: courseId,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function deleteFavoriteCourse(courseId: number): Promise<void> {
  return instance()
    .delete(`/user-courses/favorites/${courseId}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getFavoritesCourses(
  page?: number
): Promise<ResponsePagination<CourseCardItem>> {
  return instance()
    .get(`/user-courses/favorites/`, {
      params: { page },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getDocs(): Promise<
  ResponsePagination<{ id: number; file: string }>
> {
  return instance()
    .get(`/course-creation/documents/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export type AiResult = {
  id: number;
  course: number;
  course_unit: number;
  taxonomy: string;
  result: string;
};

export type AiResponse = {
  status: number;
  data: AiResult;
};

export function getCourseAiResult(
  courseId: number,
  unitId: number
): Promise<AiResult[]> {
  return instance()
    .get(`/course-creation/ai-assistant/`, {
      params: {
        course: courseId,
        course_unit: unitId,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getCourseAiResultNew(topicId: number): Promise<AiResult[]> {
  return instance()
    .get(`/course-creation/ai-assistant/`, {
      params: {
        course_topic: topicId,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function fetchAiAssistantV3(
  topicId: number,
  taxonomy: TaxonomyEnum
): Promise<AiResponse> {
  return instance()
    .post<AiResult>(`/ai-assistant/generate/`, {
      course_topic: topicId,
      taxonomy: taxonomy,
    })
    .then((response) => ({ data: response.data, status: response.status }))
    .catch((error) => {
      throw error;
    });
}

export function updateCourseStatus(
  courseId: string,
  body: { new_status: string; comment?: string }
): Promise<Course> {
  return instance()
    .post(`/course-status-update/${courseId}/`, body)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getParentSubscriptionNotification(): Promise<TMailing> {
  return instance()
    .get(`/course-creation/parent-subscription-notification/check-status/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function setParentSubscriptionNotification(
  parent_email: string
): Promise<TMailing> {
  return instance()
    .post(`/course-creation/parent-subscription-notification/`, { parent_email })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function changeParentSubscriptionNotification(
  mailingId: number,
  parent_email: string
): Promise<TMailing> {
  return instance()
    .put(`/course-creation/parent-subscription-notification/${mailingId}`, {
      parent_email,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function setPrivateCoursePurchaseV3(
  courseId: number
): Promise<{ course: number }> {
  return instance()
    .post(`/v3/private/course-purchase/course-purchase/`, { course: courseId })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}
