import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader } from '../../../../components/Loader';
import { MButton } from '../../../../horizon-components/MButton/ui/MButton';
import { MCheckbox } from '../../../../horizon-components/MCheckbox/MCheckbox';
import { QuestionItemEnum } from '../../../../models/Course';
import { useCourseCreation } from '../../../../providers/course-provider/course-creation-provider';
import useFetch from '../../../../hooks/useFetch';
import { getQuestionsV3 } from '../../../../api/courseV3';

export const CoursePreviewUnitExam: React.FC = () => {
  const { t } = useTranslation(['translation']);
  const {
    course,
    activeTab,
    courseUnits,
    updateActiveTab,
    currentUnit: unit,
  } = useCourseCreation();
  const { data: questions, isLoading, fetchData } = useFetch(getQuestionsV3);

  useEffect(() => {
    unit?.id && fetchData(unit?.id);
  }, [unit?.id, fetchData]);

  const getNextId = (): number | undefined => {
    if (!courseUnits?.length) return undefined;
    const index = courseUnits?.findIndex((item) => item.id === activeTab);
    if (index === -1 || index > courseUnits?.length - 1) {
      return undefined;
    }
    const newArray = courseUnits?.slice(index + 1);
    return newArray[0]?.id;
  };

  const nextUnit = () => {
    if (!course) return;
    const unitId = getNextId();
    updateActiveTab(unitId || courseUnits[0].id);
  };

  return (
    <>
      <div className="flex items-center justify-between gap-2">
        <div className={'text-[14px] font-bold md:text-[18px]'}>
          {t('course.unitExam')}
        </div>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className={'flex flex-col gap-[32px]'}>
          {questions?.map((question, index) => (
            <div className={'flex flex-col gap-[12px]'} key={question.id}>
              <div
                className={
                  'flex text-sm font-medium leading-tight text-secondary-dark-grey-900 md:text-base'
                }
              >
                <span className="font-semibold">{index + 1}. </span>
                <span dangerouslySetInnerHTML={{ __html: question.text }} />
              </div>
              {question.unit_answers.map((answer) => (
                <MCheckbox
                  key={answer.id}
                  checked={answer.correct}
                  type={
                    question.type === QuestionItemEnum.MULTI_CORRECT
                      ? 'checkbox'
                      : 'radio'
                  }
                  id={`answer${answer.id}index${index + 1}`}
                  text={answer.text}
                  disabled
                />
              ))}
            </div>
          ))}
          {courseUnits?.length > 1 ? (
            <div className={'flex w-full justify-end gap-3'}>
              <MButton color={'primary'} variant={'highlighted'} onClick={nextUnit}>
                {t('course.Next_unit')}
              </MButton>
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};
