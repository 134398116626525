import { ImagesEnum } from '../../../../providers/StartProvider/ImagesProvider';


// Массив данных для рисования тайлов
export const movementTilesToDraw: Array<
  [number, number, number, number, string, boolean?, number?, number?]
> = [
  // Blocks (Блоки)
  [523.5, 805.6, 100, 81.5, ImagesEnum.BlockMRBroken, undefined, undefined, 301],
  [431, 747, 103.94, 81.81, ImagesEnum.BlockMRBroken, undefined, undefined, 301],
  [333, 664, 107.5, 85.97, ImagesEnum.BlockMRBlue, undefined, undefined, 298],
  [
    430.86,
    606.2,
    103.94,
    81.81,
    ImagesEnum.BlockLRBroken,
    undefined,
    undefined,
    301,
  ],
  [518.5, 541.74, 71, 68.95, ImagesEnum.BlockMRDouble, undefined, undefined, 301],
  [461, 506.4, 55.5, 43.5, ImagesEnum.BlockSRWhite, undefined, undefined, 299],
  //   [
  //     366.85,
  //     423.19,
  //     67.7,
  //     65.75,
  //     ImagesEnum.BlockMRDouble,
  //     undefined,
  //     undefined,
  //     296,
  //   ],
  //[445.93, 383.18, 67.7, 54.47, ImagesEnum.BlockMRBlue, undefined, undefined, 299],
  //   [
  //     506.04,
  //     321.03,
  //     103.94,
  //     81.81,
  //     ImagesEnum.BlockLRBroken,
  //     undefined,
  //     undefined,
  //     297,
  //   ],
  //[436.5, 265.9, 67.92, 55.33, ImagesEnum.BlockMRWhite, undefined, undefined, 301],
  [388, 458.24, 67.7, 54.47, ImagesEnum.BlockMRBlue, undefined, undefined, 200],
  //[446.15, 184.35, 67.7, 54.47, ImagesEnum.BlockMRBlue, undefined, undefined, 297],

  // Roads (Дороги)
  //1-2
  [489, 762.5, 68.5, 64.5, ImagesEnum.LadderMRDefault, undefined, undefined, 301],
  [
    424.29,
    726.47,
    68.14,
    42.43,
    ImagesEnum.BridgeMRStone,
    undefined,
    undefined,
    300,
  ],
  [390, 696.9, 68.14, 42.43, ImagesEnum.BridgeMRStone, undefined, undefined, 299],
  //2-3
  [402.5, 618.5, 68.5, 64.5, ImagesEnum.LadderMRDefault, true, undefined, 301],
  [487, 553, 68.5, 64.5, ImagesEnum.LadderMRDefault, true, undefined, 301],
  //3-4
  [
    481.79,
    517.4,
    66.18,
    41.23,
    ImagesEnum.BridgeMRStone,
    undefined,
    undefined,
    300,
  ],
  [
    422.79,
    474.47,
    68.14,
    42.43,
    ImagesEnum.BridgeMRStone,
    undefined,
    undefined,
    298,
  ],
  //[388.5, 444.9, 68.14, 42.43, ImagesEnum.BridgeMRStone, undefined, undefined, 297],
  //4-5
  //[400.14, 402.9, 78.55, 32.33, ImagesEnum.BridgeMRWood, true, undefined, 300],
  //[486.29, 354.4, 66.18, 41.23, ImagesEnum.BridgeMRStone, true, undefined, 298],
  //[472, 276.5, 68.5, 64.5, ImagesEnum.LadderMRDefault, undefined, undefined, 301],
  //[408.5, 240.9, 58.16, 36.24, ImagesEnum.BridgeMRStone, undefined, undefined, 300],
  //[401.64, 204.4, 78.55, 32.33, ImagesEnum.BridgeMRWood, true, undefined, 298],

  // Signposts (Столбы)
  [597.5, 794, 24.53, 30.43, ImagesEnum.SignMRArrow, true, undefined, 301],
  [326, 655.62, 24.99, 28.66, ImagesEnum.SignMRArrow, undefined, undefined, 301],
  [492, 487, 23.87, 27.56, ImagesEnum.SignMRArrow, true, undefined, 301],
  [
    408.71,
    310.5 + 124,
    23.87,
    27.56,
    ImagesEnum.SignMRArrow,
    undefined,
    undefined,
    301,
  ],
  //   [
  //     467.65,
  //     160.48,
  //     22.57,
  //     27.99,
  //     ImagesEnum.SignMRArrowUp,
  //     undefined,
  //     undefined,
  //     301,
  //   ],

  // Sticks (Палки)
  [
    498.7,
    739.5,
    27.99,
    22.57,
    ImagesEnum.StickMRDefault,
    undefined,
    undefined,
    301,
  ],
  //[356, 437.73, 27.99, 22.57, ImagesEnum.StickMRDefault, true, undefined, 301],
  // [
  //   595.23,
  //   305.84,
  //   24.74,
  //   36.02,
  //   ImagesEnum.StickMRSkull,
  //   undefined,
  //   undefined,
  //   301,
  // ],

  // Trees (Деревья)
  [425.07, 559, 41.23, 69.22, ImagesEnum.TreeMRDefault, undefined, undefined, 301],

  // Fences (Заборы)
  [504, 613.46, 10.42, 17.79, ImagesEnum.FenceMRDefault, true, -11.42, 301],
  [513.45, 614.12, 8.25, 14.1, ImagesEnum.FenceSRDefault, true, -11.42, 301],
  [390.17, 453.68, 11.93, 20.83, ImagesEnum.FenceMRDefault, true, undefined, 301],
  [400.15, 455.64, 8.25, 14.1, ImagesEnum.FenceSRDefault, true, undefined, 301],
  [437.26, 447.82, 11.93, 20.83, ImagesEnum.FenceMRDefault, false, undefined, 301],
  [446.37, 458.24, 8.25, 14.1, ImagesEnum.FenceSRDefault, false, undefined, 301],
  //СoinsBox
  //[488.53, 177.96, 24.16, 19.96, ImagesEnum.CoinsMRBox, false, 4.24, 301],

  // Stones (Камни)
  [474, 624.5, 22.15, 10.42, ImagesEnum.StonesMRDefault, undefined, undefined, 301],
];

export const pointsToDraw: Array<
  [number, number, number, number, string, boolean?, number?, number?]
> = [
  // Levels (Уровни)
  [
    363.88,
    653.9,
    53.02,
    38.69,
    ImagesEnum.Level6MRDefault,
    undefined,
    undefined,
    302,
  ],
  [549, 794.5, 50.56, 36.67, ImagesEnum.Level7MRDefault, undefined, undefined, 302],
  [
    528.53,
    523.4,
    50.56,
    36.89,
    ImagesEnum.Level8MRDefault,
    undefined,
    undefined,
    302,
  ],
  //   [
  //     375.73,
  //     405.4,
  //     50.56,
  //     36.89,
  //     ImagesEnum.Level4MRDefault,
  //     undefined,
  //     undefined,
  //     302,
  //   ],
  //   [
  //     532.83,
  //     310.27,
  //     50.56,
  //     36.67,
  //     ImagesEnum.Level5MRDefault,
  //     undefined,
  //     undefined,
  //     302,
  //   ],
];

export const backgroundCloudsToDraw: Array<
  [number, number, number, number, string, boolean?, number?, number?]
> = [
  [495, 883.69, 98.93, 58.82, ImagesEnum.Cloud3MRDefault],
  [323.24, 747.04, 94.76, 55, ImagesEnum.Cloud4MRDefault, true],
  [604.75, 747.3, 96.56, 42.36, ImagesEnum.Cloud2MRDefault],
  [164.25, 655.8, 96.56, 42.36, ImagesEnum.Cloud2MRDefault, true],
  [528, 624.01, 67.99, 48.41, ImagesEnum.Cloud1MRDefault, true],
  [710, 581.69, 98.93, 58.82, ImagesEnum.Cloud3MRDefault],
  [293, 467.19, 98.93, 58.82, ImagesEnum.Cloud3MRDefault, true],
  [509.24, 411.04, 94.76, 55, ImagesEnum.Cloud4MRDefault],
  [142.5, 383.69, 98.93, 58.82, ImagesEnum.Cloud3MRDefault],
  [354.5, 358.01, 67.99, 48.41, ImagesEnum.Cloud1MRDefault],
  [254.25, 277.3, 96.56, 42.36, ImagesEnum.Cloud2MRDefault],
  [643, 243.69, 98.93, 58.82, ImagesEnum.Cloud3MRDefault, true],
  //[514.74, 196.04, 94.76, 55, ImagesEnum.Cloud4MRDefault],
  //[328.24, 120.54, 94.76, 55, ImagesEnum.Cloud4MRDefault, true],
  //[530.5, 62.51, 67.99, 48.41, ImagesEnum.Cloud1MRDefault, true],

  //block
  [364.91, 510, 67.22, 52.95, ImagesEnum.BlockMRWhite],
];
