import React from 'react';

interface ProgressLevelBarProps {
  progress: number;
  className: string;
}

const ProgressLevelBar: React.FC<ProgressLevelBarProps> = ({
  progress,
  className,
}) => {
  return (
    <div
      className={`relative h-[28px] w-full rounded-full bg-blue-100 md:h-[36px] ${className}`}
    >
      <div
        className="absolute h-full rounded-full bg-gradient-to-r from-[#471FF7] to-[#C414FF]"
        style={{ width: `${progress}%` }}
      />
      <span className="text-md absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform font-bold text-white">
        {progress}%
      </span>
    </div>
  );
};

export default ProgressLevelBar;
