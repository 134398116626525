import React from 'react';
import { useTranslation } from 'react-i18next';
import { BidRules } from './bid-rules';
import { BidTabs } from '../../../../widgets/BidTabs';

export const BidAbout: React.FC = () => {
  const { t } = useTranslation(['translation']);

  return (
    <div className={'px-[8px] lg:px-0'}>
      <h1 className={'g-main-title hidden lg:mb-6 lg:block'}>{t('menu.About')}</h1>
      <BidTabs />
      <div className={'grid grid-cols-1 gap-5 lg:grid-cols-3'}>
        <BidRules />
      </div>
    </div>
  );
};
