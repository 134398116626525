import { configureStore } from '@reduxjs/toolkit';
import auctionReducer from './auction';
import startReducer from './subject';

export const store = configureStore({
  reducer: {
    auction: auctionReducer,
    start: startReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
