import React, { ReactNode } from 'react';
import { BottomSheet as ReactBottomSheet } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';
import './style.scss';

interface BottomSheetProps {
  children: ReactNode;
  open: boolean;
  onDismiss: () => void;
}

export const BottomSheet: React.FC<BottomSheetProps> = ({
  children,
  open,
  onDismiss,
}) => {
  return (
    <ReactBottomSheet
      open={open}
      onDismiss={onDismiss}
      style={{ zIndex: 50, position: 'relative' }}
    >
      <div className="z-50 px-2 pb-10">{children}</div>
    </ReactBottomSheet>
  );
};
