import React, {
  createContext,
  useContext,
  ReactNode,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react';
import useFetch from '../../hooks/useFetch';
import {
  getCourseUnitV3,
  getPrivateCourseV3,
  getPublicСourseLicenseV3,
} from '../../api/courseV3';
import { Course, TLicense, UnitItem } from '../../models/Course';
import { useParams } from 'react-router-dom';
import { getLanguagesList, Languages } from '../../api/dictionaries';
import { ResponsePagination } from '../../models/Response';

interface CourseContextProps {
  course: Course | null;
  isLoading: boolean;
  error: Error | null;
  fetchCourse: (courseId: string) => void;
  getCourse: () => void;
  updateCourse: (course: Course) => void;
  languages: ResponsePagination<Languages> | null;
  license: ResponsePagination<TLicense> | null;
  courseUnits: UnitItem[];
  updateCourseUnit: (v: UnitItem) => void;
  updateCourseUnits: (v: UnitItem[]) => void;
  activeTab: number;
  updateActiveTab: (v: number) => void;
  currentUnit?: UnitItem;
  setCurrentUnitExamId: (v: number | null) => void;
  currentUnitExamId?: number | null;
  setCurrentTopicIndex: (v: number | null) => void;
  currentTopicIndex?: number | null;
}

const CourseContext = createContext<CourseContextProps | undefined>(undefined);

export const useCourseCreation = () => {
  const context = useContext(CourseContext);
  if (!context) {
    throw new Error('useCourseContext must be used within a CourseCreationProvider');
  }
  return context;
};

interface CourseCreationProviderProps {
  children: ReactNode;
}

export const CourseCreationProvider: React.FC<CourseCreationProviderProps> = ({
  children,
}) => {
  const { id: courseId } = useParams();
  const [course, setCourse] = useState<Course | null>(null);
  const [courseUnits, setCourseUnits] = useState<UnitItem[]>([]);
  const [activeTab, setActiveTab] = useState<number>(
    course?.subject_level_units?.[0]?.id || 1
  );
  const [currentUnitExamId, setCurrentUnitExamId] = useState<number | null>(null);
  const [currentTopicIndex, setCurrentTopicIndex] = useState<number | null>(null);
  const {
    isLoading,
    error,
    fetchData: fetchCourse,
  } = useFetch((courseId: string) => getPrivateCourseV3(courseId));
  const { data: languages, fetchData: fetchLanguagesList } =
    useFetch(getLanguagesList);
  const { fetchData: fetchCourseUnits } = useFetch(getCourseUnitV3);
  const { data: license, fetchData: fetchLicense } = useFetch(
    getPublicСourseLicenseV3
  );

  useEffect(() => {
    fetchCourseUnits(Number(courseId) || 0).then((data) => {
      if (data?.length) {
        setCourseUnits(data);
        setActiveTab(data[0]?.id);
      }
    });
  }, [courseId]);

  const getCourse = useCallback(() => {
    courseId && fetchCourse(courseId).then((res) => res?.id && setCourse(res));
  }, [courseId]);

  useEffect(() => getCourse(), [getCourse]);

  const updateCourseUnit = (data: UnitItem) => {
    setCourseUnits((prev) =>
      prev.map((el) => (el.id === data.id ? { ...el, ...data } : el))
    );
  };

  const currentUnit = useMemo(() => {
    return courseUnits.find((unit) => unit.id === activeTab);
  }, [courseUnits, activeTab]);

  useEffect(() => {
    console.log('Updated currentUnit:', currentUnit);
  }, [currentUnit]);

  useEffect(() => {
    fetchLanguagesList();
    fetchLicense();
  }, []);

  const contextValue = useMemo(
    () => ({
      course,
      isLoading,
      error,
      fetchCourse,
      updateCourse: setCourse,
      getCourse,
      languages,
      license,
      currentUnit,
      courseUnits,
      updateCourseUnit,
      updateCourseUnits: setCourseUnits,
      updateActiveTab: setActiveTab,
      activeTab,
      setCurrentUnitExamId,
      currentUnitExamId,
      setCurrentTopicIndex,
      currentTopicIndex,
    }),
    [
      course,
      isLoading,
      error,
      fetchCourse,
      getCourse,
      languages,
      license,
      currentUnit,
      courseUnits,
      activeTab,
      currentUnitExamId,
      currentTopicIndex,
    ]
  );

  return (
    <CourseContext.Provider value={contextValue}>{children}</CourseContext.Provider>
  );
};
