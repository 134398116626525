import React from 'react';
import { ReactComponent as PenIcon } from '../../../assets/icons/profile/pen.svg';
import { ReactComponent as PlusIcon } from '../../../assets/icons/profile/plus.svg';
import { useDropzone } from 'react-dropzone';

interface DropImgProps {
  onDrop?: (files: File[]) => void;
  multiple?: boolean;
  className?: string;
  children?: React.ReactNode;
  edit?: boolean;
}

export const DropImg = ({
  onDrop,
  multiple = true,
  className,
  children,
  edit = true,
}: DropImgProps) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
    },
  });

  return (
    <div {...getRootProps()} className="h-full w-full">
      {children}
      <div className={className}>
        <input {...getInputProps()} />
        {edit ? <PenIcon /> : <PlusIcon />}
      </div>
    </div>
  );
};
