import { FC, useEffect, useRef } from 'react';

type BgElSchoolMapProps = {
  className?: string;
};

const BgSchoolMapLayer: FC<BgElSchoolMapProps> = ({ className }) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  // const drawBackground = (ctx: CanvasRenderingContext2D) => {
  //   ctx.fillStyle = '#2D396B';
  //   ctx.fillRect(0, 0, 960, 960);
  // };

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext('2d');

      if (ctx) {
        ctx.imageSmoothingEnabled = false;
        canvas.width = 960;
        canvas.height = 960;
        // drawBackground(ctx);
      }
    }
  }, []);
  return (
    <div className={`${className} bg-[#2d396b]`}>
      <canvas
        className="scale-100 overflow-hidden"
        ref={canvasRef}
        width={960}
        height={960}
      ></canvas>
    </div>
  );
};

export default BgSchoolMapLayer;
