import { CoinIcon } from '../../../../components/CoinIcon';
import { ReactComponent as AddBidIcon } from '../../../../assets/icons/add-bid.svg';
import { ReactComponent as SubBidIcon } from '../../../../assets/icons/sub-bid.svg';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserContext } from '../../../../providers';
import { useAuctionContext } from '../../../../providers/AuctionProvider/AuctionProvider';

export const AuctionViewBet = () => {
  const { t } = useTranslation(['translation']);
  const { user } = useUserContext();
  const { auction, setActualBid, actualBid, bids } = useAuctionContext();

  const inputRef = useRef<HTMLInputElement>(null);
  const spanRef = useRef<HTMLSpanElement>(null);

  const add = () => setActualBid(actualBid + 1);
  const sub = () => {
    const lastAmount =
      (Number(
        bids?.find(
          (bid, idx) =>
            bid.user.guid === user?.guid ||
            (auction?.number_winners && idx + 1 === auction?.number_winners)
        )?.bid_amount
      ) || 0) + 1;
    actualBid > lastAmount && setActualBid(actualBid - 1);
  };

  useEffect(() => {
    if (spanRef.current && inputRef.current) {
      const spanWidth = spanRef.current.offsetWidth;
      inputRef.current.style.width = `${spanWidth * 2}px`;
    }
  }, [actualBid]);

  return (
    <div className="inline-flex w-full flex-col items-center justify-center gap-1 rounded-3xl border border-[#e0e5f2] p-2">
      <p
        className={
          'text-center text-sm font-semibold leading-[18px] text-secondaryGrey-600'
        }
      >
        {t('bid.Enter_the_amount')}
      </p>

      <div className={'flex w-full items-center justify-between'}>
        <div
          onClick={sub}
          className={
            'flex h-[34px] min-w-[34px] cursor-pointer items-center justify-center rounded-full bg-secondaryGrey-300'
          }
        >
          <SubBidIcon />
        </div>
        <div className={'flex items-center gap-[6px]'}>
          <div ref={inputRef}>
            <input
              value={actualBid}
              onChange={(e) => {
                const value = e.currentTarget.value.replace(/\D/g, '');
                setActualBid(Number(value));
              }}
              type="tel"
              pattern="[0-9]*"
              inputMode="numeric"
              className="w-full border-none p-0 text-[30px] font-semibold text-secondary-dark-grey-800 outline-none"
            />
          </div>
          <CoinIcon />
          <span
            ref={spanRef}
            style={{
              position: 'absolute',
              visibility: 'hidden',
              whiteSpace: 'pre',
            }}
          >
            {actualBid}
          </span>
        </div>
        <div
          onClick={add}
          className={
            'flex h-[34px] min-w-[34px] cursor-pointer items-center justify-center rounded-full bg-purple-100'
          }
        >
          <AddBidIcon />
        </div>
      </div>
    </div>
  );
};
