import classNames from 'classnames';
import { ReactComponent as MdChevronLeft } from '../../../../../assets/icons/chevron-left.svg';
import { useCourseCreation } from '../../../../../providers/course-provider/course-creation-provider';

interface CoursePreviewNavigationProps {
  onPrevClick?: () => void;
  onNextClick?: () => void;
  currentTopicIndex: number;
  currentUnitExamId?: number | null;
}

export const CoursePreviewNavigation = ({
  onPrevClick,
  onNextClick,
  currentTopicIndex,
  currentUnitExamId,
}: CoursePreviewNavigationProps) => {
  const { currentUnit } = useCourseCreation();
  const currentTopic = currentUnit?.topics?.[currentTopicIndex];

  return (
    <div className="flex items-center gap-3 sm-max:w-full">
      <div className="flex items-center justify-between gap-2 sm-max:w-full">
        <div
          onClick={onPrevClick}
          className={classNames(
            `linear flex items-center justify-center transition duration-200`,
            `cursor-pointer rounded-full border-2`,
            `h-[32px] w-[32px] lg:h-[42px] lg:w-[42px]`,
            {
              'border-primary-purple-blue-300 bg-white text-primary-purple-blue-300':
                currentTopicIndex === 0 && !currentUnitExamId,
            },
            {
              'border-primary-purple-blue-500 bg-primary-purple-blue-500 text-white hover:bg-primary-purple-blue-600 active:bg-primary-purple-blue-700':
                (currentUnit &&
                  currentUnit?.topics?.length > currentTopicIndex &&
                  currentTopicIndex !== 0) ||
                currentUnitExamId,
            }
          )}
        >
          <MdChevronLeft />
        </div>
        <div
          className={
            'overflow-hidden overflow-ellipsis font-bold text-secondary-dark-grey-900 md:hidden md:text-[20px]'
          }
        >
          {currentTopic?.title}
        </div>
        <div
          onClick={onNextClick}
          className={classNames(
            `linear flex items-center justify-center transition duration-200`,
            `cursor-pointer rounded-full border-2`,
            `h-[32px] w-[32px] lg:h-[42px] lg:w-[42px]`,
            {
              'border-primary-purple-blue-300 bg-white text-primary-purple-blue-300':
                currentUnit &&
                currentUnit?.topics.length - 1 === currentTopicIndex &&
                currentTopicIndex !== 0 &&
                !currentUnit?.topics[currentTopicIndex]?.questions?.length &&
                currentUnitExamId,
            },
            {
              'border-primary-purple-blue-500 bg-primary-purple-blue-500 text-white hover:bg-primary-purple-blue-600 active:bg-primary-purple-blue-700':
                ((currentUnit &&
                  currentUnit?.topics?.length - 1 >= currentTopicIndex) ||
                  !!currentUnit?.topics[currentTopicIndex]?.questions?.length) &&
                !currentUnitExamId,
            }
          )}
        >
          <MdChevronLeft style={{ transform: 'rotate(180deg)' }} />
        </div>
      </div>
    </div>
  );
};
