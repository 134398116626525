import { FC, useEffect } from 'react';
import HighSchoolMap from './HighSchoolMap/HighSchoolMap';
import ElementarySchoolMap from './ElementarySchoolMap/ElementarySchoolMap';
import MiddleSchoolMap from './MiddleSchoolMap/MiddleSchoolMap';
import CharacterMovementArea from './CharacterMovementArea/CharacterMovementArea';
import './LevelMap.scss';
import useFetch from '../../../hooks/useFetch';
import { getPrivateSubjectsProgress } from '../../../api/subjectV3';
import { useUserContext } from '../../../providers';
import { updateProgressLevelData } from '../../../stores/subject';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../stores';
import { useTranslation } from 'react-i18next';

export const LevelMap: FC = () => {
  const { user } = useUserContext();
  const dispatch: AppDispatch = useDispatch();
  // const { currentPosition } = useCharacterPositionContext();
  const { data: progressLevelData, fetchData: fetchSubjectsProgress } = useFetch(
    getPrivateSubjectsProgress
  );
  const { i18n } = useTranslation(['translation']);
  const language = i18n.language;
  const currentSubjectId = useSelector(
    (state: RootState) => state.start.currentSubjectId
  );

  const subjectData = progressLevelData?.find(
    (data) => data.subject_id === currentSubjectId
  );

  useEffect(() => {
    if (user?.guid) {
      fetchSubjectsProgress();
    }
  }, [user?.guid]);

  useEffect(() => {
    if (progressLevelData) {
      dispatch(updateProgressLevelData({ language, data: progressLevelData }));
    }
  }, [progressLevelData, dispatch]);

  const subjectLevel = subjectData?.level ?? 1;

  useEffect(() => {
    const scrollToPosition = () => {
      let scrollPosition = 0;

      if (
        (subjectLevel >= 1 && subjectLevel <= 4) ||
        (subjectLevel >= 9 && subjectLevel <= 11)
      ) {
        scrollPosition = 1000;
      } else if (subjectLevel === 5) {
        scrollPosition = 50;
      } else if (subjectLevel >= 6 && subjectLevel <= 7) {
        scrollPosition = 300;
      } else if (subjectLevel === 8 || subjectLevel === 12) {
        scrollPosition = 200;
      } else {
        scrollPosition = 1000;
      }
      window.scrollTo({
        top: scrollPosition,
        behavior: 'smooth',
      });
    };

    scrollToPosition();
  }, [subjectLevel]);

  const isMiddleSchoolMapVisible = !(subjectLevel >= 9 && subjectLevel <= 12);
  const isElementarySchoolMapVisible = !(subjectLevel >= 6 && subjectLevel <= 12);

  // const [isFalling, setIsFalling] = useState(false);
  // const [isTopFalling, setIsTopFalling] = useState(false);
  // const [isVisible, setIsVisible] = useState(true);

  // const triggerFall = () => {
  //   setIsFalling(true);
  //   setTimeout(() => {
  //     setIsFalling(false);
  //     setIsVisible(false);
  //   }, 5000);
  // };

  // useEffect(() => {
  //   if (currentPosition === 'middle') {
  //     triggerFall();
  //     setTimeout(() => {
  //       setIsTopFalling(true);
  //     }, 1500);
  //   }
  // }, [currentPosition]);

  const renderMaps = () => (
    <>
      <div
        className="h-[960px] pt-[64px]"
        style={{
          marginTop: `calc(var(--safe-area-top-height))`,
          marginBottom: `calc(var(--safe-area-bottom-height) + 4rem)`,
        }}
      >
        <div className="relative h-full w-full">
          <div className="absolute top-0 h-full w-full">
            {' '}
            <HighSchoolMap className="absolute top-0 h-full w-full" />
            {isMiddleSchoolMapVisible && (
              <MiddleSchoolMap className={`absolute top-0 h-full w-full`} />
            )}
            {isElementarySchoolMapVisible && (
              <ElementarySchoolMap className={`absolute top-0 h-full w-full`} />
            )}
            <CharacterMovementArea className="absolute top-0 h-full w-full" />
          </div>
        </div>
      </div>
    </>
  );

  return (
    <>
      <div className="school-map h-full w-full">{renderMaps()}</div>
    </>
  );
};
