import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  getGradesByPurchasableSubject,
  getGradesProgress,
  getPurchasableSubjectsIdV3,
} from '../../../../api/digitalPassV3';
import { Loader } from '../../../../components/Loader';
import useFetch from '../../../../hooks/useFetch';
import { useNavigate } from '../../../../hooks/useNavigate';
import {
  EduRoutes,
  MainRoutes,
} from '../../../../horizon-layout/MainLayout/Routes/types/routes';
import { Grade, GradeProgress } from '../../../../models/Grade';
import { useUserContext } from '../../../../providers';
import { useTitleBackContext } from '../../../../providers/TitleBackProvider/TitleBackProvider';
import { GradeCard } from '../../../../widgets/GradeCard';
import './style.scss';

interface SubjectSchoolsProps {
  className?: string;
}

export const SubjectSchools = ({ className }: SubjectSchoolsProps) => {
  const { id: subjectId } = useParams();
  const { user } = useUserContext();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation(['translation']);
  const [isLoading, setIsLoading] = useState(false);
  const { useTitleBack } = useTitleBackContext();
  const { data, fetchData: fetchPurchasable } = useFetch(getPurchasableSubjectsIdV3);
  const [grades, setGrades] = useState<Grade[]>([]);
  const [gradesProgress, setGradesProgress] = useState<GradeProgress[]>([]);

  useTitleBack(data?.subject?.title);

  const fetchGrades = async () => {
    if (!subjectId) return;
    const grades_ = await getGradesByPurchasableSubject(parseInt(subjectId));
    setGrades(grades_);
  };

  const fetchGradesProgress = async () => {
    if (!user) return;
    const progress = await getGradesProgress();
    setGradesProgress(progress);
  };

  useEffect(() => {
    setIsLoading(true);
    Promise.all([fetchGrades(), fetchGradesProgress()]).finally(() =>
      setIsLoading(false)
    );
    fetchPurchasable(subjectId ? parseInt(subjectId) : 0, i18n.language);
  }, [t]);

  const gradeProgressMapping = useMemo(() => {
    const gradeProgressMapping: Record<number, GradeProgress> = {};
    for (const gradeProgress of gradesProgress) {
      gradeProgressMapping[gradeProgress.id] = gradeProgress;
    }
    return gradeProgressMapping;
  }, [gradesProgress]);

  const goTo = (gradeId: number, title: string) =>
    navigate(
      MainRoutes.edu +
        EduRoutes.nft +
        `/${subjectId}` +
        EduRoutes.shcools +
        `/-1` +
        EduRoutes.classes +
        `/${gradeId}`,
      { state: { title } }
    );

  return (
    <div className={classNames('SubjectSchools', className)}>
      {t('edu.subject.Select_a_class')}
      {isLoading ? (
        <Loader />
      ) : (
        <div className="SubjectSchools__content">
          {grades.map((grade) => (
            <GradeCard
              key={grade.id}
              title={grade.title}
              description={grade.description || undefined}
              units={grade.units_count}
              completed={gradeProgressMapping[grade.id]?.completed_units_count || 0}
              onActive={() => goTo(grade.id, grade.title)}
              isEnrolled={false}
              className={`order-${grade.id}`}
              style={{ order: grade.id }}
            />
          ))}
        </div>
      )}
    </div>
  );
};
