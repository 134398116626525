import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { getUserSomeDataStorage, setUserSomeDataStorage } from '../../api';
import { getTokenomicConfigs } from '../../api/auth';
import { DailyRewardModal } from '../../features/DailyRewardModal';
import { Modal } from '../../horizon-components/Modal';
import { parseDate } from '../../shared/utils/parseDate';
import { useUserContext } from '../UserProvider';

interface DailyRewardContextType {
  isStartOpen: boolean;
  hndlOpenStartModal: () => void;
  hndlCloseStartModal: () => void;
  forceSetDataLoaded: () => void;
}

const DailyRewardContext = createContext<DailyRewardContextType | undefined>(
  undefined
);

interface DailyRewardProviderProps {
  children: ReactNode;
}

export const DailyRewardProvider: React.FC<DailyRewardProviderProps> = ({
  children,
}) => {
  const [isStartOpen, setIsStartOpen] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const { user, setHasClaimedReward } = useUserContext();
  const today = new Date().toLocaleDateString();

  useEffect(() => {
    if (user?.guid && isDataLoaded) {
      const lastVisit = getUserSomeDataStorage('lastRewardClaim', user.guid);
      const date1 = parseDate(today);
      const date2 = lastVisit && parseDate(lastVisit);

      if (!date2 || date1.getTime() !== date2.getTime()) {
        hndlOpenStartModal();
      }
    }
  }, [user, isDataLoaded, today]);

  const forceSetDataLoaded = () => {
    setIsDataLoaded(true);
  };

  const hndlOpenStartModal = () => {
    setIsStartOpen(true);
  };

  const hndlCloseStartModal = () => {
    if (isStartOpen) {
      setIsStartOpen(false);
      setIsDataLoaded(false);
      setUserSomeDataStorage('lastCloseRewardModal', today, user?.guid);
    }
  };

  useEffect(() => {
    const checkDataLoaded = async () => {
      try {
        await getTokenomicConfigs();
        const lastClose = getUserSomeDataStorage('lastCloseRewardModal', user?.guid);
        const lastClaim = getUserSomeDataStorage('lastRewardClaim', user?.guid);
        const isClaimed = getUserSomeDataStorage('isRewardClaimed', user?.guid);
        const todayDate = parseDate(today);
        const lastClaimDate = lastClaim && parseDate(lastClaim);
        const lastCloseDate = lastClose && parseDate(lastClose);

        if (!lastCloseDate || todayDate.getTime() !== lastCloseDate.getTime()) {
          forceSetDataLoaded();
        }

        if (
          isClaimed === 'true' &&
          lastClaimDate &&
          todayDate.getTime() === lastClaimDate.getTime()
        ) {
          setHasClaimedReward(true);
        }

        if (!isClaimed && !lastClaimDate) {
          hndlOpenStartModal();
        }
      } catch (error) {
        console.error('Error loading data:', error);
      }
    };

    user?.guid && checkDataLoaded();
  }, [today, user]);

  const handleClose = () => {
    setHasClaimedReward(false);
    hndlCloseStartModal();
  };

  return (
    <DailyRewardContext.Provider
      value={{
        isStartOpen,
        hndlOpenStartModal,
        hndlCloseStartModal,
        forceSetDataLoaded,
      }}
    >
      {children}
      {!!user?.guid && (
        <Modal
          bgClose={false}
          isOpen={isStartOpen}
          showClose={false}
          onClose={handleClose}
        >
          <DailyRewardModal onClose={handleClose} />
        </Modal>
      )}
    </DailyRewardContext.Provider>
  );
};

export const useDailyReward = (): DailyRewardContextType => {
  const context = useContext(DailyRewardContext);
  if (!context) {
    throw new Error('useDailyReward must be used within a DailyRewardProvider');
  }
  return context;
};
