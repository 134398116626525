import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';
import React, { useEffect, useState } from 'react';
import { useNavigate } from '../../hooks/useNavigate';
import { sendOpenMobileIfNative } from '../../hooks/useAnalytics';
import getPlatform from '../../utils/getPlatform';
import { PlatformsEnum } from '../../entities/Platforms/platforms'

const AppUrlListener: React.FC<any> = () => {
  let navigate = useNavigate();
  const [initUrl, setInitUrl] = useState(false);
  const [initButton, setInitButton] = useState(false);
  const [initRestore, setInitRestore] = useState(false);
  const SplashScreeHide = async () => {
    await SplashScreen.hide();
  };
  setTimeout(SplashScreeHide, 1500);
  useEffect(() => {
    setTimeout(SplashScreeHide, 1500);
    // Hide the splash (you should do this on app launch)

    //fix StatusBar style ios TODO replace to style switch
    if (getPlatform() === PlatformsEnum.IOS) {
      const setStatusBarStyleLight = async () => {
        await StatusBar.setStyle({ style: Style.Light });
      };
      setStatusBarStyleLight();
      Browser.addListener('browserPageLoaded', () => {
        // console.log('page loaded');
        // Browser.close();
        // console.log('Browser closed');
        // Browser.removeAllListeners();
      });
    }

    if (!initUrl) {
      App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
        // Example url: https://beerswift.app/tabs/tab2
        // slug = /tabs/tab2
        const slug = event.url.split('edres.com').pop();
        // const urlIn = new URL(event.url);
        // const slug = urlIn.pathname + urlIn.search + urlIn.hash
        // console.log(event.url);
        // console.log(slug);
        if (slug) {
          //   navigate(slug);
          window.location.assign(slug);
          // console.log('to slug');
          // close broeser after succes auth
          if (
            slug.startsWith('/auth/apple-oauth-submit/') ||
            slug.startsWith('/auth/google-callback') ||
            slug.startsWith('/auth/telegram-oauth-submit')
          ) {
            Browser.close();
          }
        } else {
          window.location.assign('/');
          //   navigate('/');
        }
      }).then(() => {
        setInitUrl(true);
      });
    }

    //fix android back button
    if (!initButton) {
      App.addListener('backButton', ({ canGoBack }) => {
        if (!canGoBack) {
          App.exitApp();
        } else {
          window.history.back();
        }
      }).then(() => {
        setInitButton(true);
      });
    }

    //fix state after app restored from hide (white screen if ram clered)
    if (!initRestore) {
      App.addListener('pause', () => {
        console.log('pause state');
      });
      App.addListener('resume', () => {
        sendOpenMobileIfNative();
        console.log('resume state');
      });
      App.addListener('appStateChange', (data) => {
        console.log('appStateChange state:', data);
      });

      App.addListener('appRestoredResult', (data) => {
        console.log('Restored state:', data);
      }).then(() => {
        setInitRestore(true);
      });
      // addListener(eventName: 'resume', listenerFunc: () => void) => Promise<PluginListenerHandle>
      // App.addListener
    }
  }, []);

  return null;
};

export default AppUrlListener;
