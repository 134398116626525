import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ReactComponent as StarIcon } from '../../assets/icons/star-full.svg';
import EdresCoinImage from '../../assets/images/reward/erp.png';
import { CoinIcon } from '../../components/CoinIcon';
import { Badge } from '../../horizon-components/Badge';
import { MButton } from '../../horizon-components/MButton';
import { formatNumber } from '../../shared/utils/formatNumber';

interface CongratulationTaskModalProps {
  className?: string;
  onToggle?: () => void;
  award?: string;
  starsCount?: number;
}

export const CongratulationTaskModal = ({
  className,
  onToggle,
  award,
  starsCount,
}: CongratulationTaskModalProps) => {
  const { t, i18n } = useTranslation(['translation']);

  return (
    <div
      className={classNames(
        'inline-flex w-full flex-col items-center justify-center gap-6 rounded-3xl bg-white md:max-w-[600px] lg:p-12',
        className
      )}
    >
      <div className="inline-flex items-start justify-start">
        <img src={EdresCoinImage} alt="edres coin" className={'w-[244px]'} />
      </div>
      <div className="flex flex-col items-center justify-center gap-2 self-stretch">
        <div className="text-center text-2xl font-bold leading-9 text-[#4318ff] lg:text-[32px]">
          {t('task.Congratulate')}!
        </div>
        <div className="self-stretch text-center text-base font-semibold leading-normal text-[#2b3674] lg:text-xl">
          {t('task.You_have_successfully_completed_the_task_and_received_a_reward')}:
        </div>
      </div>

      {award || starsCount ? (
        <div className="inline-flex items-start justify-start gap-2">
          {award ? (
            <Badge variant="gradient" size="lg">
              +{formatNumber(award, i18n.language)}{' '}
              <CoinIcon className="relative !h-4 !w-4" />
            </Badge>
          ) : null}
          {starsCount ? (
            <Badge variant="gradient" size="lg">
              +{starsCount} <StarIcon className="relative !h-4 !w-4" />
            </Badge>
          ) : null}
        </div>
      ) : null}

      <MButton
        variant="highlighted"
        color="primary"
        className="modal-h w-full"
        onClick={onToggle}
      >
        {t('buttons.More_rewards')}!
      </MButton>
    </div>
  );
};
