import React from 'react';
import classNames from 'classnames';
import { PlatformsEnum } from '../../entities/Platforms/platforms';
import { sendShare } from '../../hooks/useAnalytics';
import ShareDefaultButtons from './ShareDefaultButtons';
import ShareTelegramButtons from './ShareTelegramButtons';
import ShareCopyButton from './buttons/ShareCopyButton';
import getPlatform from '../../utils/getPlatform';
import { ShareOnSocialHandler } from './types';
import './Share.scss';

type Props = {
  text?: string;
  url: string;
  className?: string;
  loading?: boolean;
  fileBase64?: string;
};

export const Share: React.FC<Props> = ({
  text,
  url,
  className,
  loading,
  fileBase64,
}) => {
  const isTelegram = getPlatform() === PlatformsEnum.TELEGRAM;
  const Buttons = isTelegram ? ShareTelegramButtons : ShareDefaultButtons;

  const onSocialClick =
    (method: string, shareOnSocial: ShareOnSocialHandler) => () => {
      shareOnSocial(url, text, fileBase64)
        .then(() => {
          sendShare(method, 'text');
        })
        .catch(() => {});
    };

  return (
    <div
      className={classNames('ShareButtons', className)}
      style={{ outline: 'none', boxShadow: 'none' }}
      tabIndex={0}
    >
      <Buttons
        hasFile={!!fileBase64}
        loading={loading}
        onSocialClick={onSocialClick}
      />
      <div className={'flex'}>
        <ShareCopyButton text={text} url={url} loading={loading} />
      </div>
    </div>
  );
};
