import React from 'react';
import { useTranslation } from 'react-i18next';

export const BidRules: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <div
        className={`col-span-1 flex flex-col gap-5 rounded-[24px] p-[0px] !shadow-[14px_17px_40px_4px_#7090B014] lg:col-span-2 lg:!p-[24px]`}
      >
        <p className={'text-[18px] font-bold text-secondaryGrey-900'}>
          {t('bid.Rules')}
        </p>
        <div className={'flex items-start gap-[8px]'}>
          <div
            className={
              'flex h-[28px] w-[28px] flex-none items-center justify-center rounded-full bg-purple-100 text-[16px] font-bold text-purple-400'
            }
          >
            1
          </div>
          <p className={'mt-1 text-[16px] font-[600] text-secondaryGrey-900'}>
            {t('bid.r1')}
          </p>
        </div>
        <div className={'flex items-start gap-[8px]'}>
          <div
            className={
              'flex h-[28px] w-[28px] flex-none items-center justify-center rounded-full bg-purple-100 text-[16px] font-bold text-purple-400'
            }
          >
            2
          </div>
          <p className={'mt-1 text-[16px] font-[600] text-secondaryGrey-900'}>
            {t('bid.r2')}
          </p>
        </div>
        <div className={'flex items-start gap-[8px]'}>
          <div
            className={
              'flex h-[28px] w-[28px] flex-none items-center justify-center rounded-full bg-purple-100 text-[16px] font-bold text-purple-400'
            }
          >
            3
          </div>
          <p className={'mt-1 text-[16px] font-[600] text-secondaryGrey-900'}>
            {t('bid.r3')}
          </p>
        </div>
        <div className={'flex items-start gap-[8px]'}>
          <div
            className={
              'flex h-[28px] w-[28px] flex-none items-center justify-center rounded-full bg-purple-100 text-[16px] font-bold text-purple-400'
            }
          >
            4
          </div>
          <p className={'mt-1 text-[16px] font-[600] text-secondaryGrey-900'}>
            {t('bid.r4')}
          </p>
        </div>
        <div className={'flex items-start gap-[8px]'}>
          <div
            className={
              'flex h-[28px] w-[28px] flex-none items-center justify-center rounded-full bg-purple-100 text-[16px] font-bold text-purple-400'
            }
          >
            5
          </div>
          <p className={'mt-1 text-[16px] font-[600] text-secondaryGrey-900'}>
            {t('bid.r5')}
          </p>
        </div>
        <div className={'flex items-start gap-[8px]'}>
          <div
            className={
              'flex h-[28px] w-[28px] flex-none items-center justify-center rounded-full bg-purple-100 text-[16px] font-bold text-purple-400'
            }
          >
            6
          </div>
          <p className={'mt-1 text-[16px] font-[600] text-secondaryGrey-900'}>
            {t('bid.r6')}
          </p>
        </div>
        <div className={'flex items-start gap-[8px]'}>
          <div
            className={
              'flex h-[28px] w-[28px] flex-none items-center justify-center rounded-full bg-purple-100 text-[16px] font-bold text-purple-400'
            }
          >
            7
          </div>
          <p className={'mt-1 text-[16px] font-[600] text-secondaryGrey-900'}>
            {t('bid.r7')}
          </p>
        </div>
        <div className={'flex items-start gap-[8px]'}>
          <div
            className={
              'flex h-[28px] w-[28px] flex-none items-center justify-center rounded-full bg-purple-100 text-[16px] font-bold text-purple-400'
            }
          >
            8
          </div>
          <p className={'mt-1 text-[16px] font-[600] text-secondaryGrey-900'}>
            {t('bid.r8')}
          </p>
        </div>
        <div className={'flex items-start gap-[8px]'}>
          <div
            className={
              'flex h-[28px] w-[28px] flex-none items-center justify-center rounded-full bg-purple-100 text-[16px] font-bold text-purple-400'
            }
          >
            9
          </div>
          <p className={'mt-1 text-[16px] font-[600] text-secondaryGrey-900'}>
            {t('bid.r9')}
          </p>
        </div>
        <div className={'flex items-start gap-[8px]'}>
          <div
            className={
              'flex h-[28px] w-[28px] flex-none items-center justify-center rounded-full bg-purple-100 text-[16px] font-bold text-purple-400'
            }
          >
            10
          </div>
          <p className={'mt-1 text-[16px] font-[600] text-secondaryGrey-900'}>
            {t('bid.r10')}
          </p>
        </div>
        <div className={'flex items-start gap-[8px]'}>
          <div
            className={
              'flex h-[28px] w-[28px] flex-none items-center justify-center rounded-full bg-purple-100 text-[16px] font-bold text-purple-400'
            }
          >
            11
          </div>
          <p className={'mt-1 text-[16px] font-[600] text-secondaryGrey-900'}>
            {t('bid.r11')}
          </p>
        </div>
      </div>
    </>
  );
};
