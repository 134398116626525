import NotFoundImg from '../../assets/images/not-found/not-found.png';
import { useTranslation } from 'react-i18next';
import { MAIN_PAGE_ROUTE } from '../../horizon-layout/MainLayout/Routes/types/routes';
import React from 'react';
import { Link } from '../../horizon-components/Link';
import { Helmet } from 'react-helmet';

export const NotFoundPage = () => {
  const { t } = useTranslation(['translation']);

  return (
    <>
      <Helmet>
        <meta property="robots" content="noindex" />
      </Helmet>
      <div
        className={
          'flex h-full flex-col items-center justify-center gap-4 text-center'
        }
      >
        <div className={'flex w-[208px] justify-center lg:w-[400px]'}>
          <img src={NotFoundImg} alt={'Not found'} />
        </div>
        <span className={'text-xl font-semibold lg:text-3xl'}>
          {t('not_found.title')}
        </span>
        <span className={'text-sm font-medium lg:text-lg'}>
          <span>{t('not_found.description')}</span>{' '}
          <Link to={MAIN_PAGE_ROUTE}>
            <span className={'cursor-pointer text-purple-600'}>
              {t('not_found.return_to_main')}
            </span>
          </Link>
        </span>
      </div>
    </>
  );
};
