import { useTranslation } from 'react-i18next';
import { ReactComponent as StarIcon } from '../../assets/icons/star-full.svg';
import { ReactComponent as NotifyIcon } from '../../assets/icons/tasks/notify.svg';
import { CoinIcon } from '../../components/CoinIcon';
import { Loader } from '../../components/Loader';
import { useShareReferal } from '../../hooks/useShareReferal';
import { Badge } from '../../horizon-components/Badge';
import { Link } from '../../horizon-components/Link';
import { MButton } from '../../horizon-components/MButton';
import { TaskProgressStatus, TaskType } from '../../models/Tasks';
import { formatNumber } from '../../shared/utils/formatNumber';
import { hasDomainInUrl } from '../../shared/utils/hasDomainInUrl';

interface TaskInfoModalProps {
  id?: number;
  title?: string;
  notice?: string;
  description?: string;
  onStart?: () => void;
  onReward?: () => void;
  error?: string;
  status?: TaskProgressStatus;
  isLoading?: boolean;
  loading?: boolean;
  award?: string;
  link?: string;
  starsCount?: number;
  taskType?: TaskType;
}

export const TaskInfoModal = ({
  id,
  title,
  notice,
  description,
  onStart,
  onReward,
  error,
  status,
  isLoading,
  loading,
  award,
  link,
  starsCount,
  taskType,
}: TaskInfoModalProps) => {
  const { t, i18n } = useTranslation(['translation']);
  const { handleClick: handleShare } = useShareReferal();

  if (isLoading) return <Loader />;

  return (
    <div className="flex flex-col items-center gap-1 lg:gap-2">
      {title ? (
        <h3 className="text-lg font-bold leading-9 text-[#1b2559] lg:-mt-10 lg:text-[32px]">
          {title}
        </h3>
      ) : null}
      <div className="flex max-w-[500px] flex-col gap-4 overflow-y-auto lg:gap-8">
        {description ? (
          <p className="text-sm font-medium leading-normal text-[#2b3674] lg:text-xl lg:font-semibold">
            {description}
          </p>
        ) : null}

        {notice ? (
          <div className="inline-flex flex-col items-start justify-start gap-1 rounded-lg bg-[#fde0d0] p-2">
            <div className="self-stretch text-sm font-semibold leading-[18px] text-[#c31322]">
              {t('task.Attention')}!
            </div>
            <div className="self-stretch">
              <span className="text-sm font-medium leading-[18px] text-[#2b3674]">
                {notice}
              </span>
            </div>
          </div>
        ) : null}

        {loading ? (
          <div className="inline-flex h-12 w-full items-center justify-start gap-2 rounded-lg bg-[#c9fbd5] p-3">
            <div className="inline-flex shrink grow basis-0 flex-col items-start justify-center gap-1 self-stretch">
              <span className="modal-h self-stretch text-base font-semibold leading-tight text-[#2b3674]">
                {t('task.Check')}...
              </span>
            </div>
            <Loader className="ml-auto !h-6 !w-6" />
          </div>
        ) : (
          <>
            {error ? (
              <div className="inline-flex h-12 items-center justify-start gap-2 rounded-lg bg-[#fff6da] p-3">
                <div className="inline-flex shrink grow basis-0 flex-col items-start justify-center gap-1 self-stretch">
                  <span className="self-stretch text-sm font-semibold leading-[18px] text-[#2b3674]">
                    {error}
                  </span>
                </div>
                <NotifyIcon className="relative h-6 w-6" />
              </div>
            ) : null}
          </>
        )}

        {award || starsCount ? (
          <div className="inline-flex h-[50px] items-center justify-start gap-2 rounded-lg bg-[#f4f7fe] p-3">
            <div className="shrink grow basis-0 text-sm font-semibold leading-[18px] text-[#2b3674]">
              {t('task.Get_a_reward')}
            </div>
            <div className="flex items-start justify-start gap-1">
              {award ? (
                <Badge variant="gradient" size="lg">
                  +{formatNumber(award, i18n.language)}{' '}
                  <CoinIcon className="relative !h-4 !w-4" />
                </Badge>
              ) : null}
              {starsCount ? (
                <Badge variant="gradient" size="lg">
                  +{starsCount} <StarIcon className="relative !h-4 !w-4" />
                </Badge>
              ) : null}
            </div>
          </div>
        ) : null}

        {id ? (
          <div className="flex flex-col gap-2">
            {status === 'RUNNING' ||
            status === 'PENDING' ||
            status === 'COMPLETED' ? (
              !link ? (
                <MButton
                  variant={status === 'PENDING' ? 'highlighted' : 'secondary'}
                  color="primary"
                  className="modal-h w-full"
                  onClick={() => onStart?.()}
                >
                  {t('buttons.Go_over')}
                </MButton>
              ) : (
                <Link
                  to={link || ''}
                  target={hasDomainInUrl(link || '') ? '_blank' : '_self'}
                  rel={hasDomainInUrl(link || '') ? 'noopener noreferrer' : ''}
                  className="w-full"
                  onClick={() => onStart?.()}
                >
                  <MButton
                    variant={status === 'PENDING' ? 'highlighted' : 'secondary'}
                    color="primary"
                    className="modal-h w-full"
                  >
                    {t('buttons.Go_over')}
                  </MButton>
                </Link>
              )
            ) : null}

            {status === 'PENDING' ||
            status === 'RUNNING' ||
            status === 'COMPLETED' ? (
              <MButton
                className="modal-h"
                variant={status === 'PENDING' ? 'secondary' : 'highlighted'}
                color="primary"
                onClick={onReward}
              >
                {t('buttons.Check')}
              </MButton>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
};
