import React, {
  createContext,
  useContext,
  useState,
  PropsWithChildren,
} from 'react';

type CharacterPositionContextType = {
  currentPosition: 'elementary' | 'middle' | 'high';
  setCurrentPosition: (position: 'elementary' | 'middle' | 'high') => void;
};

const CharacterPositionContext = createContext<
  CharacterPositionContextType | undefined
>(undefined);

const CharacterPositionProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [currentPosition, setCurrentPosition] = useState<
    'elementary' | 'middle' | 'high'
  >('elementary');

  return (
    <CharacterPositionContext.Provider
      value={{ currentPosition, setCurrentPosition }}
    >
      {children}
    </CharacterPositionContext.Provider>
  );
};

const useCharacterPositionContext = () => {
  const context = useContext(CharacterPositionContext);
  if (!context) {
    throw new Error(
      'useCharacterPositionContext must be used within a CharacterPositionProvider'
    );
  }
  return context;
};

export { CharacterPositionProvider, useCharacterPositionContext };
