import React, { ReactNode, useEffect, useState } from 'react';
import { Tab, Tabs } from '../../../../horizon-components/Tabs/Tabs';
import { CourseViewReview } from './course-view-review';
import { useTranslation } from 'react-i18next';
import useHistoryParams from '../../../../hooks/useHistoryParams';
import CourseViewUnits from './course-view-units';
import classNames from 'classnames';
import { CourseViewDescription } from './course-view-description';

export enum TabsEnum {
  CONTENT = 'content',
  DESCRIPTION = 'description',
  FEEDBACK = 'feedback',
}

type Props = {
  className?: string;
};

const CourseViewContent: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation(['translation']);
  const { getParamFromUrl } = useHistoryParams();

  const [currentTab, setCurrentTab] = useState<TabsEnum>(TabsEnum.DESCRIPTION);

  const tabFromUrl = getParamFromUrl('tab');
  useEffect(() => {
    if (tabFromUrl && Object.values(TabsEnum).includes(tabFromUrl as TabsEnum)) {
      setCurrentTab(tabFromUrl as TabsEnum);
    }
  }, [tabFromUrl]);

  const tabsConfig: Tab[] = [
    {
      id: TabsEnum.DESCRIPTION,
      title: t('course.about'),
    },
    ...(window.innerWidth < 992
      ? [
          {
            id: TabsEnum.CONTENT,
            title: t('course.Content'),
          },
        ]
      : []),
    ...(window.innerWidth > 992
      ? [
          {
            id: TabsEnum.FEEDBACK,
            title: t('course.studentReviews'),
          },
        ]
      : []),
  ];

  const toggleTab = (tab: Tab) => {
    setCurrentTab(tab.id as TabsEnum);
  };

  const config: Record<string, ReactNode> = {
    [TabsEnum.DESCRIPTION]: <CourseViewDescription />,
    [TabsEnum.CONTENT]: <CourseViewUnits isInTab />,
    [TabsEnum.FEEDBACK]: <CourseViewReview />,
  };
  const tabData = config[currentTab];

  return (
    <div
      className={classNames(
        'inline-flex flex-col items-start justify-start gap-2 lg:gap-6',
        'w-full rounded-2xl bg-white shadow-md shadow-shadow-700 lg:rounded-[30px]',
        'p-2 lg:px-10 lg:py-6',
        className
      )}
    >
      <Tabs
        tabs={tabsConfig}
        onTabChanged={toggleTab}
        tabsExtra={'md:!gap-[16px] !gap-[4px] !justify-start !m-0 !overflow-auto'}
        itemExtra={'justify-center text-nowrap'}
        active={tabsConfig.find((tab) => tab.id === currentTab)}
      />
      {tabData}
    </div>
  );
};

export default CourseViewContent;
