import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CircularProgressMini from '../../../../horizon-components/CircularProgressMini/CircularProgressMini';
import { CourseCardStatus } from '../../../../models/Course';
import { CourseAsideCard } from '../../../../widgets/CourseAsideCard';
import { ReactComponent as FlagIcon } from '../../../../assets/icons/flag.svg';
import { ReactComponent as ArrowDownIcon } from '../../../../assets/icons/arrow-down.gray.svg';
import { useCourse } from '../../../../providers';

type Props = {
  isInTab?: boolean;
  className?: string;
};

const CourseViewUnits: React.FC<Props> = ({ isInTab, className }) => {
  const { t } = useTranslation(['translation']);
  const { course } = useCourse();
  const [open, setOpen] = useState(0);

  const handleOpen = (unitId: number) => {
    setOpen(open !== unitId ? unitId : 0);
  };

  const renderData = (
    <>
      <div
        className={`flex items-center justify-between self-stretch ${
          isInTab && 'mt-3'
        } ${className}`}
      >
        <span
          className={`text-xs font-semibold text-secondary-dark-grey-500 md:text-sm`}
        >
          {course?.units.length} {t('course.units')}
        </span>
        {course?.status !== CourseCardStatus.CAN_ENROLL && (
          <FlagIcon className="sm-max:!hidden" />
        )}
      </div>
      <div>
        {course?.units.map((unit, index) => (
          <div
            className="mt-[19px] flex items-center justify-between gap-2"
            key={unit.id}
          >
            <div className="flex w-full gap-2">
              <div className="w-[30px] min-w-[30px]">
                <CircularProgressMini step={(index + 1).toString()} percentage={0} />
              </div>
              <div className="flex w-full flex-col gap-1 overflow-hidden overflow-ellipsis">
                <h4
                  className="mt-1 cursor-pointer text-sm font-semibold text-gray-900 lg:text-base"
                  onClick={() => handleOpen(unit.id)}
                >
                  {!course?.subject?.is_miscellaneous_subject
                    ? unit.unit.title
                    : course?.title}
                </h4>

                {open === unit.id && (
                  <ul className="!list-disc pl-5 text-sm text-secondary-dark-grey-700">
                    {unit.topics.map((topic) => (
                      <li key={topic.id}>
                        {!course?.subject?.is_miscellaneous_subject
                          ? topic.title
                          : t('course.test')}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <div
                className="flex w-5 min-w-5 cursor-pointer items-start justify-center pt-2"
                onClick={() => handleOpen(unit.id)}
              >
                <ArrowDownIcon className={open === unit.id ? 'rotate-180' : ''} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );

  if (isInTab) {
    return renderData;
  }

  return (
    <CourseAsideCard
      title={t('course.courseContents')}
      className="!flex lg-max:!hidden"
    >
      {renderData}
    </CourseAsideCard>
  );
};

export default CourseViewUnits;
