import { Browser } from '@capacitor/browser';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as FcGoogle } from '../../../assets/icons/google.svg';
import { generateGoogleRedirectUri } from '../SignIn/utils';
import getPlatform from '../../../utils/getPlatform';
import { PlatformsEnum } from '../../../entities/Platforms/platforms';

export type Props = {
  signIn: boolean;
};

export const GoogleAuth: React.FC<Props> = ({ signIn }) => {
  const [googleOAuthUrl, setGoogleOAuthUrl] = useState('');
  const { t } = useTranslation(['translation']);
  const platform = getPlatform();

  const openCapacitorSite = async () => {
    await Browser.open({
      url: googleOAuthUrl,
    });
  };

  const clientId = process.env.REACT_APP_GOOGLE_OAUTH2_CLIENT_ID;

  useEffect(() => {
    (async () => {
      if (!clientId) return;
      const params = {
        client_id: clientId,
        response_type: 'code',
        scope: 'email',
        access_type: 'offline',
        redirect_uri: generateGoogleRedirectUri(),
      };
      let paramsString = new URLSearchParams(params).toString();
      let oAuthUrl = `https://accounts.google.com/o/oauth2/v2/auth?${paramsString}`;
      setGoogleOAuthUrl(oAuthUrl);
    })();
  }, [clientId, signIn]);

  if (!clientId) {
    console.warn('Google client id not found');
    return null;
  }

  const innerJsx = (
    <div className="flex h-[44px] w-full items-center justify-center gap-2 rounded-xl bg-lightPrimary hover:cursor-pointer dark:bg-navy-700 dark:text-white md:h-[48px]">
      <div className="rounded-full text-xl">
        <FcGoogle className="h-[20px] w-[20px]" />
      </div>
      <p className="text-sm font-medium text-navy-700 dark:text-white">
        {signIn ? t('auth.signInG') : t('auth.signUpG')}
      </p>
    </div>
  );

  if (platform === PlatformsEnum.ANDROID) {
    return <div onClick={openCapacitorSite}>{innerJsx}</div>;
  }
  return <a href={googleOAuthUrl}>{innerJsx}</a>;
};
