import { Capacitor } from '@capacitor/core';
import { PlatformsEnum } from '../entities/Platforms/platforms';

export const IN_APP_BROWSER_MODE_STORAGE_KEY = 'isInAppBrowser';

export function setInAppBrowserModeIOS() {
  localStorage.setItem(IN_APP_BROWSER_MODE_STORAGE_KEY, 'ios');
}

export function setInAppBrowserModeAndroid() {
  localStorage.setItem(IN_APP_BROWSER_MODE_STORAGE_KEY, 'android');
}

export function isSafari() {
  const userAgent = navigator.userAgent.toLowerCase();
  return userAgent.includes('safari') && !userAgent.includes('chrome');
}

function getPlatform() {
  const platform = Capacitor.getPlatform() as PlatformsEnum;
  const telegramObject = window.Telegram?.WebApp;

  if (telegramObject?.initData) {
    return PlatformsEnum.TELEGRAM;
  }
  console.log('platform: ', platform);
  return platform;
}

export default getPlatform;
