import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getPrivateTasks, setPrivateClaimedTaskReward } from '../../../../api/tasks';
import NoTaskImg from '../../../../assets/images/task/no-task.webp';
import { Loader } from '../../../../components/Loader';
import useFetch from '../../../../hooks/useFetch';
import { BottomSheetModal } from '../../../../horizon-components/bottom-sheet-modal';
import { TTaskType } from '../../../../models/Tasks';
import { AwardsCard } from '../../../../widgets/awards-card';
import { CongratulationTaskModal } from '../../../../widgets/congratulation-task-modal';
import { MiniQuestCard } from '../../../../widgets/mini-quest-card';
import { ProgressCard } from '../../../../widgets/progress-card';
import { useUserContext } from '../../../../providers';

export const ProfileTasksProgress = () => {
  const { t } = useTranslation(['translation']);
  const [isOpen, setIsOpen] = useState(false);
  const [checkTask, setCheckTask] = useState<TTaskType>();
  const { user, updateWallet } = useUserContext();

  const { data, isLoading, fetchData } = useFetch(getPrivateTasks);
  const { fetchData: fetchReward } = useFetch(setPrivateClaimedTaskReward);

  const handleData = useCallback(() => {
    fetchData({ execution_frequency: 'ALL_TIME' });
  }, [fetchData]);

  useEffect(() => {
    handleData();
  }, [handleData, t]);

  const handleReward = (task: TTaskType) => {
    fetchReward(task.id)
      .then((res) => {
        if (res?.status === 'success' && task.task_progress.status === 'COMPLETED') {
          !isOpen && handleToggle();
          task?.id &&
            setCheckTask({
              ...task,
              task_progress: {
                ...task?.task_progress,
                status: 'REWARD_RECEIVED',
              },
            });
          user?.guid && updateWallet();
        }
      })
      .finally(handleData);
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const activeTasks = useMemo(
    () =>
      data?.results?.filter(
        (item) => item.task_progress.status !== 'REWARD_RECEIVED'
      ),
    [data]
  );

  const endedTasks = useMemo(
    () =>
      data?.results?.filter(
        (item) => item.task_progress.status === 'REWARD_RECEIVED'
      ),
    [data]
  );

  return (
    <div
      className={
        'inline-flex w-fit flex-col items-start justify-start gap-4 rounded-[30px] bg-white p-0 lg:w-full lg:p-6 lg:shadow-md lg:shadow-shadow-700'
      }
    >
      <div className="flex flex-col items-start justify-start gap-0.5">
        <h2 className="text-xl font-semibold leading-normal text-[#2b3674]">
          {t('profile.Progress')}
        </h2>
        <p className="text-base font-normal leading-tight text-[#8f9bba]">
          {t('profile.Long_term_assignments_with_great_rewards')}
        </p>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {activeTasks?.length ? (
            <>
              {activeTasks?.map((item) => (
                <ProgressCard
                  key={item.id}
                  title={item.title}
                  count={item.task_progress.executions_count}
                  total={item.required_count}
                  image={item.image}
                  status={item.task_progress.status}
                >
                  {item.children?.map((child) => (
                    <MiniQuestCard
                      key={child.id}
                      award={child.award}
                      status={child.task_progress.status}
                      executionsCount={child.task_progress.executions_count}
                      requiredCount={child.required_count}
                      onTaskReward={() => handleReward(child)}
                      onClick={() => setCheckTask(child)}
                    />
                  ))}
                </ProgressCard>
              ))}
            </>
          ) : (
            <>
              <img src={NoTaskImg} alt="no task" className="mx-auto" />
              <div className="w-full text-center text-base font-semibold leading-tight text-[#7551ff]">
                {t('task.You_have_completed_all_the_quests')}
              </div>
            </>
          )}
          {endedTasks?.length ? (
            <>
              <h3 className="!text-sm font-semibold leading-[18px] text-[#2b3674]">
                {t('quest.Awards')}
              </h3>
              {endedTasks?.map((item) => (
                <AwardsCard
                  key={item.id}
                  title={item.title}
                  image={item.image}
                  status={item.task_progress.status}
                />
              ))}
            </>
          ) : null}
        </>
      )}
      <BottomSheetModal close={handleToggle} opened={isOpen}>
        <CongratulationTaskModal onToggle={handleToggle} award={checkTask?.award} />
      </BottomSheetModal>
    </div>
  );
};
