import { useEffect, RefObject } from 'react';

const useInfiniteScroll = (
  targetRef: RefObject<HTMLDivElement>,
  callback: () => void
) => {
  useEffect(() => {
    let observer: IntersectionObserver | null = null;

    const handleScroll = () => {
      if (!targetRef.current) return;

      const { bottom } = targetRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      if (bottom <= windowHeight) {
        callback();
      }
    };

    if (typeof IntersectionObserver !== 'undefined') {
      observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            callback();
          }
        },
        {
          root: null,
          rootMargin: '0px',
          threshold: 0.1,
        }
      );

      if (targetRef.current) {
        observer.observe(targetRef.current);
      }
    } else {
      window.addEventListener('scroll', handleScroll);
      window.addEventListener('resize', handleScroll);
      handleScroll();
    }

    return () => {
      if (observer && targetRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(targetRef.current);
      } else {
        window.removeEventListener('scroll', handleScroll);
        window.removeEventListener('resize', handleScroll);
      }
    };
  }, [callback, targetRef]);
};

export default useInfiniteScroll;
