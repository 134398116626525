import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getVerificationCourses } from '../../../api/course';
import { DatePicker } from '../../../components/DatePicker';
import { Loader } from '../../../components/Loader';
import { NotFound } from '../../../components/NotFound/NotFound';
import { Course, CourseCardItem } from '../../../models/Course';
import { CourseCard } from '../../../widgets/CourseCard';
import SEO from '../../../components/SEO/SEO';
import dayjs from 'dayjs';

const lastWeek = () => {
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  const lastDay = new Date(currentDate);
  const firstDay = new Date(currentDate);
  firstDay.setDate(currentDate.getDate() - 6);
  return [firstDay, lastDay];
};

const PAGE_SIZE = 12;

export const VerifyCourseList: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [courses, setCourses] = useState<Course[]>([]);
  const { t } = useTranslation(['translation']);
  const [selectedDates, setSelectedDates] = useState<Date[]>(lastWeek());
  const [total, setTotal] = useState(0);
  const [, setUploadedPage] = useState(0);

  const getData = useCallback((page: number, dates: Date[]) => {
    if (dates.length !== 2) return;
    setIsLoading(true);
    getVerificationCourses(
      dayjs(new Date(dates[0])).format('YYYY-MM-DD'),
      dayjs(new Date(dates[1]).setDate(new Date(dates[1]).getDate() + 1)).format(
        'YYYY-MM-DD'
      ),
      page
    )
      .then((data) => {
        data?.results?.length && setCourses(data.results);
        data?.count && setTotal(data.count);
        setUploadedPage((v) => ++v);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    getData(0, selectedDates);
  }, [selectedDates, getData]);

  const scrollBottom = useCallback(
    (page: number) => {
      if (page * PAGE_SIZE < total) {
        getData(page, selectedDates);
      }
    },
    [total, getData, selectedDates]
  );

  useLayoutEffect(() => {
    if (window) {
      window.onscroll = () => {
        if (
          !isLoading &&
          window.innerHeight + window.scrollY >= document.body.offsetHeight
        ) {
          setUploadedPage((v) => {
            scrollBottom(v);
            return v;
          });
        }
      };
    }

    return () => {
      if (window) {
        window.onscroll = null;
      }
    };
  }, [isLoading, scrollBottom]);

  return (
    <>
      <SEO
        title={t('pages.verify-course.meta.title')}
        desc={t('pages.verify-course.meta.desc')}
      />

      <div className={'b-course-list w-full'}>
        <div className={'mb-6 flex items-center justify-between gap-x-2'}>
          <h1 className={'g-main-title text-xl md:text-4xl'}>
            {t('menu.VerifyCourse')}
          </h1>
          <DatePicker
            selectedDates={selectedDates}
            onDateChange={setSelectedDates}
            configs={{ dateFormat: 'dd.MM' }}
          />
        </div>
        {isLoading ? (
          <div className={'mt-4 flex w-full items-center justify-center'}>
            <Loader />
          </div>
        ) : !courses.length ? (
          <NotFound />
        ) : (
          !!courses?.length && (
            <div className={'mt-3 flex flex-wrap gap-3'}>
              {courses?.map((course, i) => (
                <div
                  className={'w-full sm:w-[45%] md:w-[30%] md:max-w-[256px]'}
                  key={i}
                >
                  <CourseCard
                    course={course as unknown as CourseCardItem}
                    hideFavorites
                    inVerifyCourse
                  />
                </div>
              ))}
            </div>
          )
        )}
      </div>
    </>
  );
};
