import { CourseCreationProvider } from '../../../providers/course-provider/course-creation-provider';
import { CoursePreview } from './ui/course-preview';

export const CoursePreviewPage = () => {
  return (
    <CourseCreationProvider>
      <CoursePreview />
    </CourseCreationProvider>
  );
};
