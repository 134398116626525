import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
// import { ReactComponent as StarIcon } from '../../assets/icons/star-full.svg';
import { ReactComponent as SuccessIcon } from '../../assets/icons/tasks/success.svg';
import { CoinIcon } from '../../components/CoinIcon';
import { TaskProgressStatus } from '../../models/Tasks';
import { formatNumber } from '../../shared/utils/formatNumber';

interface MiniQuestCardProps {
  status?: TaskProgressStatus;
  award?: string;
  executionsCount?: number;
  requiredCount?: number;
  onTaskReward?: () => void;
  onClick?: () => void;
}

export const MiniQuestCard = ({
  status = 'PENDING',
  award,
  executionsCount,
  requiredCount,
  onTaskReward,
  onClick,
}: MiniQuestCardProps) => {
  const { t, i18n } = useTranslation(['translation']);

  return (
    <>
      <div
        className={classNames(
          'inline-flex min-w-20 flex-none flex-shrink cursor-pointer flex-col items-center justify-center overflow-hidden rounded-xl border bg-white',
          { 'border-[#c314ff]': status === 'COMPLETED' }
        )}
        onClick={(e) => {
          e.stopPropagation();
          status === 'COMPLETED' && onTaskReward?.();
          onClick?.();
        }}
      >
        <div className="flex h-[60px] flex-col items-center justify-between gap-0.5 self-stretch pb-1 pt-2">
          <div className="inline-flex h-full items-center justify-center gap-1">
            {status !== 'REWARD_RECEIVED' ? <CoinIcon /> : null}
            {/* <div className="flex items-center justify-center rounded-full bg-primary-purple-blue-500 p-1">
              <StarIcon className="fill-primary-white p-0" />
            </div> */}
            {status === 'REWARD_RECEIVED' ? (
              <SuccessIcon className="fill-primary-green-400 p-0" />
            ) : null}
          </div>
          {award && status !== 'REWARD_RECEIVED' ? (
            <div className="text-center text-sm font-semibold leading-[18px] text-primary-purple-blue-500">
              +{formatNumber(award, i18n.language)}
            </div>
          ) : null}
        </div>
        <div
          className={classNames(
            'inline-flex items-center justify-center gap-2.5 self-stretch py-1',
            {
              'bg-primary-purple-blue-300': status !== 'REWARD_RECEIVED',
              'bg-primary-green-400': status === 'REWARD_RECEIVED',
              'bg-gradient-to-r from-[#c314ff] to-[#471ff6]': status === 'COMPLETED',
            }
          )}
        >
          {status === 'COMPLETED' ? (
            <div className="text-center text-sm font-semibold leading-none text-[#e9e3ff]">
              {t('buttons.Pick_up')}
            </div>
          ) : (
            <div className="text-center text-sm font-semibold leading-none text-[#e9e3ff]">
              {executionsCount}/{requiredCount}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
