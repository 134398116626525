import { BottomSheet } from '../bottom-sheet/ui';
import { Modal } from '../Modal';

interface BottomSheetModalProps {
  opened?: boolean;
  close: () => void;
  title?: string;
  children: JSX.Element;
  key?: string;
  showClose?: boolean;
  bgClose?: boolean;
  modalId?: string;
  bodyClassName?: string;
}

export const BottomSheetModal = ({
  opened = false,
  close,
  title,
  children,
  key,
  showClose = true,
  bgClose = true,
  modalId,
  bodyClassName,
}: BottomSheetModalProps) => {
  return window.innerWidth >= 768 ? (
    <Modal
      isOpen={opened}
      onClose={close}
      title={title}
      showClose={showClose}
      bgClose={bgClose}
      modalId={modalId}
      bodyClassName={bodyClassName}
    >
      {children}
    </Modal>
  ) : (
    <BottomSheet open={opened} onDismiss={close} key={key}>
      {children}
    </BottomSheet>
  );
};
