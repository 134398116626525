import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { sendToVerificationV3 } from '../../../../../api/courseV3';
import { sendCreationComplite } from '../../../../../hooks/useAnalytics';
import useFetch from '../../../../../hooks/useFetch';
import { useNavigate } from '../../../../../hooks/useNavigate';
import { MButton } from '../../../../../horizon-components/MButton/ui/MButton';
import { Modal } from '../../../../../horizon-components/Modal';
import {
  EduRoutes,
  MainRoutes,
} from '../../../../../horizon-layout/MainLayout/Routes/types/routes';
import { CourseStatus } from '../../../../../models/Course';
import { useCourseCreation } from '../../../../../providers/course-provider/course-creation-provider';
import { CoursePreviewModeModal } from './course-preview-mode-modal';
import { toast } from 'react-toastify';

export const CoursePreviewMode: React.FC = () => {
  const navigate = useNavigate();
  const { id: courseId } = useParams();
  const { course } = useCourseCreation();
  const { t } = useTranslation('translation');
  const [showPreviewModal, setShowPreviewModal] = useState(false);

  const { isLoading: isVerificationLoading, fetchData: fetchVerification } =
    useFetch(sendToVerificationV3);

  const sendToVerificationClick = () => {
    courseId &&
      fetchVerification(Number(courseId || 0))
        .then((res) => {
          if (res?.id) {
            sendCreationComplite(course?.id, course?.title);
            setShowPreviewModal(true);
          }
        })
        .catch(() => toast(t('errors.server_error'), { type: 'error' }));
  };

  const goToBack = () => navigate(-1);

  return (
    <>
      <div
        className={
          'inline-flex flex-col justify-between gap-2 rounded-2xl border border-secondary-grey-500 bg-secondary-grey-200 p-2 shadow-md shadow-shadow-700 md:flex-row md:items-center md:gap-10 md:rounded-[30px] md:p-6'
        }
      >
        <div className="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-[3px]">
          <p className="self-stretch text-base font-semibold leading-snug text-[#1b2559] lg:text-lg">
            {t('course.preview')}
          </p>
          <p className="self-stretch text-xs font-semibold leading-none text-[#8f9bba]">
            {t('course.previewInfo')}
          </p>
        </div>
        <div
          className={
            'mt-2 flex w-full flex-wrap justify-end gap-2 md:gap-5 lg:mt-0 lg:w-auto'
          }
        >
          <MButton
            onClick={goToBack}
            variant={'outline'}
            className={'w-full md:w-auto'}
          >
            {course?.course_status === CourseStatus.Draft ||
            course?.course_status === CourseStatus.Rejected
              ? t('buttons.Back_to_edit')
              : t('course.backPerform')}
          </MButton>
          {(course?.course_status === CourseStatus.Draft ||
            course?.course_status === CourseStatus.Rejected) && (
            <MButton
              onClick={sendToVerificationClick}
              disabled={isVerificationLoading}
              className={'w-full md:w-auto'}
              variant="highlighted"
              color="primary"
            >
              {t('course.verify')}
            </MButton>
          )}
        </div>
      </div>
      <Modal
        isOpen={showPreviewModal}
        onClose={() => navigate(MainRoutes.edu + EduRoutes['my-courses'])}
        title={''}
      >
        <CoursePreviewModeModal />
      </Modal>
    </>
  );
};
