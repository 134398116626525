import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Router } from './Router';
import AppUrlListener from './components/AppUrlListener/AppUrlListener';
import BundleUpdate from './components/BundleUpdate/BundleUpdate';
import { FacebookPixel } from './components/FacebookPixel/FacebookPixel';
import { GoogleTagManager } from './components/GoogleTagManager/GoogleTagManager';
import { YandexMetrica } from './components/YandexMetrica/YandexMetrica';
import { Cookie } from './features/Cookie';
import {
  CourseReviewProvider,
  DailyRewardProvider,
  LanguageProvider,
  ModalProvider,
  PushProvider,
  TitleBackProvider,
  UserProvider,
} from './providers';
import { store } from './stores';
import { RewardProvider } from './providers/RewardProvider';
import { TelegramApp } from './components/TelegramApp';
import { NotificationsWebsocketProvider } from './providers/NotificationsWebsocketProvider';
import SEOProvider from './components/SEO/SEOProvider';
import { SaveUserLanguageOnServer } from './components/SaveUserLanguageOnServer';
import OrganizationLdJson from './modules/Main/seo/OrganizationLdJson';
import SoftwareApplicationLdJson from './modules/Main/seo/SoftwareApplicationLdJson';
import WebSiteLdJson from './modules/Main/seo/WebSiteLdJson';
const App: React.FC = () => {
  return (
    <BrowserRouter>
      <BundleUpdate />
      <LanguageProvider>
        <AppUrlListener />
        <RewardProvider>
          <ModalProvider>
            <Provider store={store}>
              <UserProvider>
                <DailyRewardProvider>
                  <SaveUserLanguageOnServer />
                  <NotificationsWebsocketProvider>
                    <TelegramApp />
                    <PushProvider />
                    <GoogleTagManager />
                    <YandexMetrica />
                    <FacebookPixel />
                    <ToastContainer />
                    <SEOProvider>
                      <OrganizationLdJson />
                      <SoftwareApplicationLdJson />
                      <WebSiteLdJson />
                      <CourseReviewProvider>
                        <TitleBackProvider>
                          <Router />
                        </TitleBackProvider>
                      </CourseReviewProvider>
                    </SEOProvider>
                  </NotificationsWebsocketProvider>
                  <Cookie />
                </DailyRewardProvider>
              </UserProvider>
            </Provider>
          </ModalProvider>
        </RewardProvider>
      </LanguageProvider>
    </BrowserRouter>
  );
};

export default App;
