import { Helmet } from 'react-helmet';
import { helmetJsonLdProp } from 'react-schemaorg';
import { WebSite } from 'schema-dts';
import {
  EduRoutes,
  MainRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';

function WebSiteLdJson() {
  return (
    <Helmet
      script={[
        helmetJsonLdProp<WebSite>({
          '@context': 'https://schema.org',
          '@type': 'WebSite',
          url: 'https://edres.com',
          potentialAction: [
            {
              '@type': 'SearchAction',
              target: {
                '@type': 'EntryPoint',
                urlTemplate: `https://edres.com${MainRoutes.edu}${EduRoutes.courses}?q={search_term_string}`,
              },
              'query-input': 'required name=search_term_string',
            },
            {
              '@type': 'SearchAction',
              target: {
                '@type': 'EntryPoint',
                urlTemplate: `android-app://com.edres.app/https/edres.com${MainRoutes.edu}${EduRoutes.courses}/?q={search_term_string}`,
              },
              'query-input': 'required name=search_term_string',
            },
          ] as any[],
        }),
      ]}
    />
  );
}

export default WebSiteLdJson;
