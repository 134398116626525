import instance, { AUTH_API_URL } from './instance';
import { ResponsePagination } from '../models/Response';

export type Dictionaries = {
  name: string;
  value: string;
};

export type Subjects = {
  id: number;
  title: string;
  slug: string;
  is_miscellaneous_subject?: boolean;
};

export type Languages = {
  id: number;
  title: string;
  language_iso_code: string;
};

export function getInstitutionType(): Promise<[string, string][]> {
  return instance()
    .get(`${AUTH_API_URL}/institution-meta/institution_types_list/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getSubjectsList(): Promise<Subjects[]> {
  return instance()
    .get(`/get-subjects-list/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getLanguagesList(): Promise<ResponsePagination<Languages>> {
  return instance()
    .get(`/course-creation/languages/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getLevelsList(subject: string): Promise<Subjects[]> {
  return instance()
    .get(`/get-levels-list/`, {
      params: { subject },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getUnitsList(subject_level: string): Promise<Subjects[]> {
  return instance()
    .get(`/get-units-list/`, {
      params: { subject_level },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}
