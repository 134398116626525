import { Helmet } from 'react-helmet';
import { helmetJsonLdProp } from 'react-schemaorg';
import { SoftwareApplication, WithContext } from 'schema-dts';

const payload: WithContext<SoftwareApplication> = {
  '@context': 'https://schema.org',
  '@type': 'MobileApplication',
  name: 'EdRes',
  applicationCategory: 'EducationalApplication',
  offers: {
    '@type': 'Offer',
    price: 0,
  },
  // TODO
  // aggregateRating: {
  //   '@type': 'AggregateRating',
  //   ratingValue: 4.6,
  //   ratingCount: 8864,
  //   reviewCount: 2200,
  // },
};

function SoftwareApplicationLdJson() {
  return (
    <Helmet
      script={[
        helmetJsonLdProp<SoftwareApplication>({
          ...payload,
          operatingSystem: 'ANDROID',
          installUrl: 'https://play.google.com/store/apps/details?id=com.edres.app',
        }),
        helmetJsonLdProp<SoftwareApplication>({
          ...payload,
          operatingSystem: 'IOS',
          installUrl: 'https://apps.apple.com/us/app/edres/id6557075013',
        }),
      ]}
    />
  );
}

export default SoftwareApplicationLdJson;
