import { FC, useEffect, useRef } from 'react';
import {
  ImagesEnum,
  useImagesContext,
} from '../../../../../providers/StartProvider/ImagesProvider';
import { drawButton } from '../../utils/drawing';
import { pointsToDraw } from '../constants';
import * as WinterItems from '../../items/winterItems';
import BlockObject from '../../BlockObject';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../stores';
import { useTranslation } from 'react-i18next';

type MainObjMidSchoolMapProps = {
  className?: string;
};

const MainObjMidSchoolMap: FC<MainObjMidSchoolMapProps> = ({ className }) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const drawBackground = (ctx: CanvasRenderingContext2D) => {
    ctx.fillStyle = '#2D396B';
  };
  const currentSubjectId = useSelector(
    (state: RootState) => state.start.currentSubjectId
  );
  const subjectDetails = useSelector(
    (state: RootState) => state.start.subjectDetails
  );
  const { t, i18n } = useTranslation(['translation']);
  const language = i18n.language;
  const { images, isLoaded, getImage } = useImagesContext();

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext('2d');

      if (ctx && isLoaded) {
        ctx.imageSmoothingEnabled = false;
        canvas.width = 960;
        canvas.height = 960;
        drawBackground(ctx);
        BlockObject({
          ctx,
          objects: pointsToDraw,
          offsetX: 0,
          offsetY: -124,
          images,
        });

        const highButton = getImage(ImagesEnum.HighButton);
        drawButton(ctx, 436.67, 371.34 - 124, 86.7, 38.1, highButton!);

        const buttonBackgroundImg = getImage(ImagesEnum.Button1);
        const lockClosedButtonImg = getImage(ImagesEnum.LockClosed);
        const subjectData = subjectDetails?.[language]?.find(
          (data) => data.subjectId === currentSubjectId
        );

        if (subjectData?.levelProgress === 1 && subjectData?.subjectLevel === 8) {
          drawButton(ctx, 436.67, 371.34 - 124, 86.7, 38.1, highButton!);
        } else {
          drawButton(
            ctx,
            436.67,
            371.34 - 124,
            86.7,
            38.1,
            buttonBackgroundImg!,
            lockClosedButtonImg
          );
        }
      }
    }
  }, [isLoaded, images, t]);

  return (
    <div className={className}>
      <canvas
        className="scale-100 overflow-hidden"
        ref={canvasRef}
        width={960}
        height={960}
      ></canvas>
    </div>
  );
};

export default MainObjMidSchoolMap;
