import React, { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Loader } from '../../../../components/Loader';
import { CourseStatus } from '../../../../models/Course';
import { useUserContext } from '../../../../providers';
import { useCourseCreation } from '../../../../providers/course-provider/course-creation-provider';
import { CoursePreviewBody } from './course-preview-body';
import { CoursePreviewBreadcrumbs } from './course-preview-breadcrumbs';
import { CoursePreviewNav } from './course-preview-navigation/course-preview-nav';
import { CoursePreviewUserData } from './course-preview-user-data';
import { CoursePreviewMode } from './course-preview-verify/course-preview-mode';
import { CoursePreviewStaff } from './course-preview-verify/course-preview-staff';
import './CoursePreview.scss';

export const CoursePreview: React.FC = () => {
  const { user } = useUserContext();
  const { state }: { state: { isMyCourse: boolean } } = useLocation();
  const { course, isLoading, currentUnit, updateActiveTab, courseUnits } =
    useCourseCreation();
  const [isDetaile, setIsDetaile] = useState(false);

  const isStaff = useMemo(
    () => !!user?.is_staff && !state?.isMyCourse,
    [state?.isMyCourse, user?.is_staff]
  );

  const handleDetail = () => {
    setIsDetaile(!isDetaile);
    updateActiveTab(courseUnits[0]?.id);
  };

  return (
    <div className="CoursePreview">
      <CoursePreviewBreadcrumbs />
      {isLoading ? (
        <Loader />
      ) : currentUnit ? (
        <>
          {isStaff && course?.course_status === CourseStatus.Unverified ? (
            <CoursePreviewStaff />
          ) : course?.course_status === CourseStatus.Draft ? (
            <CoursePreviewMode />
          ) : null}
          <div className="CoursePreview__content">
            <div className="CoursePreview__list">
              <CoursePreviewUserData onClick={handleDetail} isDetaile={isDetaile} />
              {course ? (
                <div className={'block md:hidden'}>
                  <CoursePreviewNav onClick={handleDetail} isDetaile={isDetaile} />
                </div>
              ) : null}
              <CoursePreviewBody isDetaile={isDetaile} />
            </div>
            {course ? (
              <div className="CoursePreview__aside md-max:!hidden">
                <CoursePreviewNav onClick={handleDetail} isDetaile={isDetaile} />
              </div>
            ) : null}
          </div>
        </>
      ) : null}
    </div>
  );
};
