import { FC, useState } from 'react';
import { Loader } from '../../../components/Loader';

interface SubjectSelectCardProps {
  className?: string;
  imageSrc: string;
  title: string;
  subtitle: string;
  details: string;
  icon?: string;
}

const SubjectSelectCard: FC<SubjectSelectCardProps> = ({
  className,
  imageSrc,
  title,
  subtitle,
  details,
  icon,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <div className={`flex h-[72px] w-full items-center ${className}`}>
      <div className="w-[72px]">
        {isLoading && <Loader />}
        <img
          src={imageSrc}
          alt=""
          className="w-[72px]"
          onLoad={() => setIsLoading(false)}
          style={{ display: isLoading ? 'none' : 'block' }}
        />
      </div>

      <div className="flex h-[72px] w-full items-center justify-between rounded-[15px] bg-white">
        <div className="flex flex-col p-[8px]">
          <p className="mb-[2px] text-[16px] font-bold text-[#1B2559]">{title}</p>
          <p className="mb-[2px] text-[12px] font-semibold text-[#3311DB]">
            {subtitle}
          </p>
          <p className="text-[12px] font-bold text-[#7551FF]">{details}</p>
        </div>
        {icon ? (
          <div className="m-[12px] flex h-[34px] w-[34px] items-center justify-center rounded-full border-[1.5px] border-[#4318Ff]">
            <img className="h-[18px] w-[18px] fill-[#3311DB]" src={icon} alt="" />
          </div>
        ) : (
          <div className="m-[12px] flex h-[34px] w-[34px] items-center justify-center rounded-full border-[1.5px] border-gray-500"></div>
        )}
      </div>
    </div>
  );
};

export default SubjectSelectCard;
