import React, { useEffect } from 'react';
import { BehaviourProps } from './base';
import { useShareReferal } from '../../../../../hooks/useShareReferal';

export const InviteFriendBehaviour: React.FC<BehaviourProps> = ({
  stopBehaviour,
}) => {
  const { handleClick: handleShare } = useShareReferal();

  useEffect(() => {
    handleShare();
    stopBehaviour();
  }, []);

  return null;
};
