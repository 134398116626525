import { FC, useEffect, useRef } from 'react';
import {
  backgroundCloudsToDraw,
  bigIsland1,
  bigIsland2,
  bigIsland3,
  bigIsland6,
  smallIsland1,
  smallIsland2,
  smallIsland3,
  smallIsland4,
} from '../constants';
import { useImagesContext } from '../../../../providers/StartProvider/ImagesProvider';
import BlockObject from '../BlockObject';
import { drawObjects } from '../utils/drawing';

type BgObjElSchoolMapProps = {
  className?: string;
};

const BgObjSchoolMap: FC<BgObjElSchoolMapProps> = ({ className }) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const { images, isLoaded } = useImagesContext();

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext('2d');

      if (ctx && isLoaded) {
        ctx.imageSmoothingEnabled = false;
        canvas.width = 960;
        canvas.height = 960;

        const bigIslands = [
          { objects: bigIsland1, offsetX: 189.87, offsetY: 90.5 },
          { objects: bigIsland2, offsetX: 567.5, offsetY: 38.5 },
          { objects: bigIsland3, offsetX: 200, offsetY: 441.5 },
          { objects: bigIsland1, offsetX: 618, offsetY: 478.5 },
          { objects: bigIsland2, offsetX: 213, offsetY: 682.5 },
          { objects: bigIsland1, offsetX: 317.37, offsetY: 804.5 },
          {
            objects: bigIsland6,
            offsetX: 617.61,
            offsetY: 804.5,
          },
        ];

        bigIslands.forEach(({ objects, offsetX, offsetY }) => {
          BlockObject({
            ctx,
            objects,
            offsetX,
            offsetY,
            images,
          });
        });

        //SmallIslands
        const smallIslands = [
          { objects: smallIsland4, offsetX: 542, offsetY: 238.5 },
          { objects: smallIsland2, offsetX: 359.5, offsetY: 275.5 },
          { objects: smallIsland3, offsetX: 258, offsetY: 365 },
          { objects: smallIsland2, offsetX: 640, offsetY: 316.5 },
          { objects: smallIsland1, offsetX: 532, offsetY: 438.5 },
          { objects: smallIsland3, offsetX: 555.5, offsetY: 679 },
        ];

        smallIslands.forEach(({ objects, offsetX, offsetY }) => {
          BlockObject({
            ctx,
            objects,
            offsetX,
            offsetY,
            images,
          });
        });

        // Отрисовка объектов на основе массивов
        drawObjects(ctx, backgroundCloudsToDraw, images);
      }
    }
  }, [isLoaded, images]);
  return (
    <div className={className}>
      <canvas
        className="scale-100 overflow-hidden"
        ref={canvasRef}
        width={960}
        height={960}
      ></canvas>
    </div>
  );
};

export default BgObjSchoolMap;
