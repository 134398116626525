import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CoursesMode } from '../../../api/course';
import { getCoursePublicTagsListV3 } from '../../../api/weber/tags';
import useFetch from '../../../hooks/useFetch';
import useHistoryParams from '../../../hooks/useHistoryParams';
import { MButton } from '../../../horizon-components/MButton';
import { SelectOptions } from '../../../horizon-components/MMultSelect/MMultiSelect';
import { TagSearch } from '../../../horizon-components/TagSearch';
import { useUserContext } from '../../../providers';
import { EduTabs } from '../../../widgets/EduTabs';
import { AllCourses } from './AllCourses';
import { CoursesGetStarted } from './CoursesGetStarted';
import { StartedCourses } from './StartedCourses';

export const Main: React.FC = () => {
  const { t } = useTranslation(['translation']);
  const { user } = useUserContext();
  const [options, setOptions] = useState<SelectOptions[]>([]);
  const [selectedMode, setSelectedMode] = useState<CoursesMode>(
    CoursesMode.DISCOVER
  );
  const { fetchData: getTags } = useFetch(getCoursePublicTagsListV3);
  const { removeParamFromUrl } = useHistoryParams();

  const handleMode = (mode: CoursesMode) => {
    setSelectedMode(mode);
    removeParamFromUrl('page');
    removeParamFromUrl('title');
    removeParamFromUrl('tags_or_title');
  };

  useEffect(() => {
    getTags().then(
      (data) =>
        data?.length &&
        setOptions([
          ...data?.map((item: { name: string }) => ({
            label: item.name,
            value: item.name,
          })),
        ])
    );
  }, [t]);

  return (
    <div className="flex flex-col gap-[8px] md:gap-[24px]">
      <EduTabs />

      <div className="flex justify-between">
        <div
          className={
            'relative flex w-full flex-col items-center justify-between gap-2 sm:flex-row md-max:hidden'
          }
        >
          <div className={'flex w-full items-center gap-2 lg:w-auto'}>
            <MButton
              variant={
                selectedMode === CoursesMode.DISCOVER ? 'highlighted' : 'secondary'
              }
              color="tab"
              size="sm"
              onClick={() => handleMode(CoursesMode.DISCOVER)}
              className="w-full md:w-auto"
            >
              {t('course.discover')}
            </MButton>
            {!!user && (
              <MButton
                variant={
                  selectedMode === CoursesMode.MY_COURSES
                    ? 'highlighted'
                    : 'secondary'
                }
                color="tab"
                size="sm"
                onClick={() => handleMode(CoursesMode.MY_COURSES)}
                className="w-full md:w-auto"
              >
                {t('course.myCourses')}
              </MButton>
            )}
          </div>
        </div>

        <TagSearch options={options} />
      </div>

      {selectedMode === CoursesMode.DISCOVER && <AllCourses />}
      {selectedMode === CoursesMode.MY_COURSES && (
        <>
          {!!user && <StartedCourses />}
          {<CoursesGetStarted />}
        </>
      )}
    </div>
  );
};
