import { FC, useEffect, useState } from 'react';
import { LevelMap } from './LevelMap/LevelMap';
import SubjectSwitcher from './components/SubjectSwitcher';
import AvatarProgress from './components/AvatarProgress';
import StartGameModal from './components/StartGameModal';
import { BottomSheetModal } from '../../horizon-components/bottom-sheet-modal';
import SubjectsModal from './components/SubjectsModal';
import LevelModal from './components/LevelModal';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectIsOpenSubjectModal,
  setIsOpenLevelModal,
  setIsOpenSubjectModal,
} from '../../stores/subject';
import { RootState } from '../../stores';
import './Start.scss';
import getPlatform from '../../utils/getPlatform';
import { PlatformsEnum } from '../../entities/Platforms/platforms';

const Start: FC = () => {
  const { t } = useTranslation(['translation']);
  const isOpenSubjectModal = useSelector(selectIsOpenSubjectModal);
  const isOpenLevelModal = useSelector(
    (state: RootState) => state.start.isOpenLevelModal
  );
  const [subjectModalIsOpen, setSubjectModalIsOpen] = useState(isOpenSubjectModal);
  const [levelModalIsOpen, setLevelModalIsOpen] = useState(isOpenLevelModal);
  const dispatch = useDispatch();
  useEffect(() => {
    setSubjectModalIsOpen(isOpenSubjectModal);
  }, [isOpenSubjectModal]);

  useEffect(() => {
    if (isOpenLevelModal === true) {
      setLevelModalIsOpen(isOpenLevelModal);
    }
    if (isOpenLevelModal === false) {
      closeLevelModal();
    }
  }, [isOpenLevelModal]);

  const closeSubjectModal = () => {
    dispatch(setIsOpenSubjectModal(false));
    setSubjectModalIsOpen(false);
  };
  const closeLevelModal = () => {
    dispatch(setIsOpenLevelModal(false));
    setLevelModalIsOpen(false);
  };
  const subjectDetails = useSelector(
    (state: RootState) => state.start.subjectDetails
  );
  const { i18n } = useTranslation(['translation']);
  const language = i18n.language;

  const currentSubjectId = useSelector(
    (state: RootState) => state.start.currentSubjectId
  );

  const findSubjectData = () => {
    return subjectDetails?.[language]?.find(
      (data) => data.subjectId === currentSubjectId
    );
  };

  const subjectData = findSubjectData();

  return (
    <div
      className={'h-fit-content relative'}
      style={{ WebkitOverflowScrolling: 'touch' }}
    >
      <LevelMap />
      <div className="subject-avatar-container fixed left-2 right-2 mx-auto flex max-w-[600px] justify-between">
        <SubjectSwitcher />
        <AvatarProgress />
      </div>
      <StartGameModal />
      {/* <GetLevelRewardModal /> */}
      {isOpenSubjectModal ? (
        <BottomSheetModal
          title={`${t('map.TrainingUnderTheProgram')}`}
          opened={subjectModalIsOpen}
          close={closeSubjectModal}
          bodyClassName="sm:!w-[474px]"
        >
          <SubjectsModal />
        </BottomSheetModal>
      ) : undefined}

      {isOpenLevelModal ? (
        <BottomSheetModal
          title={`${t('map.Level')} ${subjectData?.subjectLevel || ''}`}
          opened={levelModalIsOpen}
          close={closeLevelModal}
          bodyClassName="sm:!w-[474px]"
        >
          <LevelModal title={t('map.Level')} />
        </BottomSheetModal>
      ) : undefined}
    </div>
  );
};

export default Start;
