import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import SEO from '../../../components/SEO/SEO';
import { MobileNavBar } from '../../../features/MobileNavBar';
import Navbar from '../../../horizon-layout/MainLayout/Navbar';
import { Footer } from '../../../widgets/Footer';
import { MainPageLearnMore } from '../../../widgets/MainPageLearnMore';
import { SupportConfidential } from '../../../widgets/SupportConfidential';
import { SupportContactEmail } from '../../../widgets/SupportContactEmail';
import './Support.scss';

interface MainProps {
  className?: string;
}

export const Support = ({ className }: MainProps) => {
  const { t } = useTranslation(['translation']);

  return (
    <>
      <SEO
        title={t('pages.support.meta.title')}
        desc={t('pages.support.meta.desc')}
      />
      <div className={classNames('Support', className)}>
        <Navbar />
        <div className={classNames('Support__wrapper', className)}>
          <MainPageLearnMore />
          <SupportContactEmail />
          <SupportConfidential />
          <Footer className={'lg-max:hidden'} />
        </div>
        <MobileNavBar />
      </div>
    </>
  );
};
