import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserContext } from '../../../../providers';
import { BidCard } from '../../../../widgets/bid-card';
import { useAuctionContext } from '../../../../providers/AuctionProvider/AuctionProvider';

export const AuctionViewBidsWinnerList = () => {
  const { t } = useTranslation(['translation']);
  const { user } = useUserContext();
  const { auction, bids, getIsWinner } = useAuctionContext();
  const winnersLenght = (bids?.filter((bid) => getIsWinner(bid)) || [])?.length;
  const number_winners =
    auction?.number_winners && winnersLenght < auction?.number_winners
      ? winnersLenght
      : auction?.number_winners || 0;

  const winners = useMemo(() => {
    return (
      bids?.filter(
        (item, idx) =>
          getIsWinner(item) &&
          (idx === 0 || idx === (number_winners ? number_winners - 1 : 0))
      ) || []
    );
  }, [number_winners, bids]);

  if (!winners.length) return null;

  return (
    <>
      <div className={'flex w-full items-center justify-between'}>
        <p className={'text-[16px] font-[600] text-gray-900'}>
          {t('bid.Winning_bets')}
        </p>
      </div>
      {winners?.map((bid, idx) => (
        <BidCard
          bid={bid}
          place={idx + 1}
          isMy={bid.user.guid === user?.guid}
          isWin={getIsWinner(bid)}
          isMin={idx + 1 === 2}
        />
      ))}
    </>
  );
};
