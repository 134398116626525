/* eslint-disable @typescript-eslint/no-unused-vars */
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Turnstile from 'react-turnstile';
import { getUserSomeDataStorage, setUserSomeDataStorage } from '../../api';
import { getPrivateDailyReward, setPrivateDailyReward } from '../../api/auth';
import CloseIcon from '../../assets/icons/close.svg';
import TurnstileImg from '../../assets/images/cloudflare/turnstile.webp';
import { Loader } from '../../components/Loader';
import { sendEarnEDRES, sendRewardReceived } from '../../hooks/useAnalytics';
import useFetch from '../../hooks/useFetch';
import { MButton } from '../../horizon-components/MButton';
import { useUserContext } from '../../providers';
import { useRewardContext } from '../../providers/RewardProvider';
import { parseDate } from '../../shared/utils/parseDate';
import getErrorMessages from '../../utils/getErrorMessages';
import { DailyRewardCard } from '../../widgets/DailyRewardCard';
import './style.scss';

interface DailyRewardModalProps {
  className?: string;
  onClose?: () => void;
}

export const DailyRewardModal = ({ className, onClose }: DailyRewardModalProps) => {
  const { t, i18n } = useTranslation(['translation']);
  const {
    data,
    isLoading,
    fetchData: fetchDailyReward,
  } = useFetch(getPrivateDailyReward);
  const { updateWallet, user, setHasClaimedReward } = useUserContext();
  const { rewards, isLoading: isLoadingRewards } = useRewardContext();
  const today = new Date().toLocaleDateString();
  const lastClaim = getUserSomeDataStorage('lastRewardClaim', user?.guid);

  useEffect(() => {
    const date1 = parseDate(today);
    const date2 = lastClaim && parseDate(lastClaim);

    if (!date2 || date1.getTime() !== date2.getTime()) {
      setUserSomeDataStorage('lastRewardClaim', today, user?.guid);
    }
  }, [lastClaim, t, today, user?.guid]);

  useEffect(() => {
    fetchDailyReward();
  }, [fetchDailyReward, t]);

  const getRewardValue = (index: number, type: string) => {
    return Math.round(
      Number(
        rewards?.find((reward) => reward.tokenomic_type === `${type}_${index + 1}`)
          ?.value
      )
    );
  };

  const currentDailyRewardIndex = data?.daily_info?.findIndex(
    (reward) => reward.status === 'can_be_claimed'
  );

  const lastClaimedIndex = data?.daily_info?.reduce((lastIndex, reward, index) => {
    return reward.status === 'claimed' ? index : lastIndex;
  }, -1);

  let rewardIndex;

  if (currentDailyRewardIndex !== -1) {
    rewardIndex = currentDailyRewardIndex;
  } else {
    rewardIndex = lastClaimedIndex;
  }

  const dailyTaskCoin =
    rewardIndex !== undefined
      ? getRewardValue(rewardIndex, 'daily_reward_streak')
      : undefined;

  const dailyTaskMultiplier =
    rewardIndex !== undefined
      ? getRewardValue(rewardIndex, 'wiz_daily_streak_multiplier')
      : undefined;

  setUserSomeDataStorage('dailyTaskCoin', dailyTaskCoin, user?.guid);
  setUserSomeDataStorage('dailyTaskMultiplier', dailyTaskMultiplier, user?.guid);

  const [token, setToken] = useState<string | undefined>(undefined);

  const onGetReward = () => {
    if (!token) {
      toast(getErrorMessages('Captcha not verified'), {
        type: 'error',
      });
    } else {
      setPrivateDailyReward(token)
        .then((res) => {
          // TODO set amount earned
          if (res?.id) {
            sendEarnEDRES(0);
            updateWallet();
            toast(t('reward.daily.success'), { type: 'success' });
            setUserSomeDataStorage('isRewardClaimed', 'true', user?.guid);
          }
        })
        .catch((error) => {
          toast(getErrorMessages(error?.response?.data), {
            type: 'error',
          });
        })
        .finally(() => {
          setHasClaimedReward(true);
        });
      sendRewardReceived();
      onClose?.();
    }
  };

  return (
    <div className={classNames('DailyRewardModal', className)}>
      <img
        src={CloseIcon}
        alt="Close"
        className="DailyRewardModal__closeIcon"
        onClick={onClose}
      />
      <div className="DailyRewardModal__head">
        <h1>{t('reward.daily.title')}</h1>
        {/* <p>{t('reward.daily.description')}</p> */}
      </div>
      {isLoading || isLoadingRewards ? (
        <Loader />
      ) : (
        <>
          <div className="DailyRewardModal__days">
            {data &&
              data.daily_info.map((day, idx) => (
                <DailyRewardCard
                  title={`${t('reward.day')} ${idx + 1}`}
                  earn={`${Math.round(
                    Number(
                      rewards?.find(
                        (reward) =>
                          reward.tokenomic_type ===
                          'daily_reward_streak_' + (idx + 1)
                      )?.value || 0
                    )
                  )}`}
                  multiplier={`${Math.round(
                    Number(
                      rewards?.find(
                        (reward) =>
                          reward.tokenomic_type ===
                          'wiz_daily_streak_multiplier_' + (idx + 1)
                      )?.value || 0
                    )
                  )}X`}
                  status={day.status}
                  key={idx}
                  onClick={day.status === 'can_be_claimed' ? onGetReward : undefined}
                />
              ))}
          </div>
          <div style={{ height: '65px', width: '300px', position: 'relative' }}>
            <Turnstile
              sitekey="0x4AAAAAAAynUyjZse1JlJmu"
              theme="light"
              className="DailyRewardModal__turnstile"
              language={i18n.language}
              onSuccess={(inToken) => {
                setToken(inToken);
              }}
              onError={(e) => {
                console.log('CAPTCHA error:', e);
              }}
            />
            <div className="DailyRewardModal__loadingOverlay">
              <img
                src={TurnstileImg}
                alt="Loading"
                className="DailyRewardModal__image"
              />
            </div>
          </div>

          <MButton
            variant="highlighted"
            color="primary"
            className="modal-h sm-max:w-full md:!px-8 md:!py-6 md:!text-lg"
            onClick={onGetReward}
            disabled={!token}
          >
            {t('buttons.Collect_the_reward')}
          </MButton>
        </>
      )}
    </div>
  );
};
