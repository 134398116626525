import {
  Modal as CUModal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/modal';
import classNames from 'classnames';
import React from 'react';
import { ReactComponent as CloseIcon } from '../../../assets/icons/modal/close.svg';
import { useModal } from '../../../providers/ModalProvider';
import { MButton } from '../../MButton/ui/MButton';
import './Modal.scss';

export type ModalProps = {
  title?: string;
  isOpen?: boolean;
  onClose?(): void;
  children?: JSX.Element;
  showClose?: boolean;
  overlayClassName?: string;
  bodyClassName?: string;
  contentClassName?: string;
  modalId?: string;
  bgClose?: boolean;
};

export const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  children,
  title,
  showClose = true,
  overlayClassName,
  bodyClassName,
  contentClassName,
  modalId,
  bgClose = true,
}) => {
  const { isModalOpen, closeModal } = useModal();

  const hndlClose = () => {
    onClose?.();
    modalId && closeModal(modalId);
  };

  const handleBgClose = () => {
    if (!bgClose) return;
    hndlClose();
  };

  return (
    <CUModal isOpen={isOpen || isModalOpen(modalId || '')} onClose={handleBgClose}>
      <ModalOverlay
        className={`Modal__bg ${overlayClassName}`}
        onClick={handleBgClose}
      />
      <ModalContent className={`Modal__content ${contentClassName}`}>
        <ModalBody className={`Modal__body ${bodyClassName}`}>
          {(!!title || showClose) && (
            <div
              className={classNames('Modal__title', {
                '!justify-center': !showClose,
              })}
            >
              {title && <h3>{title}</h3>}
              {showClose && (
                <MButton
                  color="white"
                  variant="highlighted"
                  className="Modal__close !p-2"
                  onClick={hndlClose}
                >
                  <CloseIcon />
                </MButton>
              )}
            </div>
          )}
          {children}
        </ModalBody>
      </ModalContent>
    </CUModal>
  );
};
