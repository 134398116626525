import {
  AuthRoutes,
  MainRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';
import getPlatform from '../../../utils/getPlatform';
import { PlatformsEnum } from '../../../entities/Platforms/platforms';

export const generateGoogleRedirectUri = () => {
  const redirectPage = AuthRoutes['google-signin-signup'];
  const platform = getPlatform();
  let redirectURI = `${process.env.REACT_APP_API_URL}${MainRoutes.auth}${redirectPage}/`;
  if ([PlatformsEnum.ANDROID, PlatformsEnum.IOS].includes(platform)) {
    redirectURI = `${process.env.REACT_APP_API_AUTHURL}${MainRoutes.auth}${redirectPage}/`;
  }
  return redirectURI;
};

export const generateAppleRedirectUri = () => {
  let redirectURI = `${process.env.REACT_APP_API_URL}${MainRoutes.auth}${AuthRoutes['apple-signin-signup']}/`;
  const platform = getPlatform();
  if ([PlatformsEnum.IOS].includes(platform)) {
    redirectURI = `${process.env.REACT_APP_API_AUTHURL}${MainRoutes.auth}${AuthRoutes['apple-signin-signup']}/`;
  }
  return redirectURI;
};

export const generateTelegramRedirectUri = (isMobile: boolean) => {
  const postfix = `${MainRoutes.auth}${AuthRoutes['telegram-oauth-submit']}`;
  let redirectURI = `${process.env.REACT_APP_API_URL}${postfix}`;
  if (isMobile) {
    redirectURI = `${process.env.REACT_APP_API_AUTHURL}${postfix}`;
  }

  return redirectURI;
};
