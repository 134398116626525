import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { ISubjectDetail, ISubjectProgress, IUnit } from '../models/Subjects';
import { ISubject } from '../models/Subjects';
import { getPublicSubjectsV3, getPublicUnitsV3 } from '../api/subjectV3';
import { RootState } from '.';
import mathImage from '../assets/images/map/subjects/math.svg';

interface SubjectState {
  progressLevelData: Record<string, ISubjectProgress[]> | null;
  subjects: Record<string, ISubject[]> | null;
  isOpenSubjectModal: boolean;
  isOpenLevelModal: boolean | undefined;
  subjectsStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  subjectsError: string | null;
  units: Record<string, IUnit[]> | null;
  unitsStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  unitsError: string | null;
  currentSubjectId: number;
  subjectDetails: Record<string, ISubjectDetail[]> | null;
}

const initialState: SubjectState = {
  progressLevelData: null,
  subjects: null,
  isOpenSubjectModal: false,
  isOpenLevelModal: undefined,
  subjectsStatus: 'idle',
  subjectsError: null,
  units: null,
  unitsStatus: 'idle',
  unitsError: null,
  currentSubjectId: 8,
  subjectDetails: null,
};

export const fetchPublicSubjects = createAsyncThunk<
  { language: string; data: ISubject[] },
  string
>('subjects/fetchPublicSubjects', async (language: string) => {
  const data = await getPublicSubjectsV3(language);
  return { language, data };
});

export const fetchPublicUnits = createAsyncThunk<
  { language: string; data: IUnit[] },
  string
>('units/fetchPublicUnits', async (language: string) => {
  const data = await getPublicUnitsV3(language);
  return { language, data };
});

export const fetchSubjectDetails = createAsyncThunk<
  { language: string; data: ISubjectDetail[] },
  string,
  { state: RootState }
>('subjects/fetchSubjectDetails', async (language, { getState }) => {
  const state = getState();
  const { progressLevelData, subjects, units } = state.start;

  // Ensure progressLevelData is accessed by language
  const languageProgressData = progressLevelData?.[language];

  if (!languageProgressData || !subjects || !units) return { language, data: [] };

  const data = languageProgressData
    .map((progress) => {
      const subject = subjects[language].find((s) => s.id === progress.subject_id);

      const subjectImage = subject?.image;
      const defaultImage = mathImage;

      if (!subject) return null;

      const category = subject.subject_categories.find((cat) =>
        cat.grades.some((grade) => grade.order === progress.level)
      );

      if (!category) return null;

      const matchingUnits = units[language]
        .filter((unit) => unit.level === progress.level)
        .filter((unit) => !progress.completed_units_ids.includes(unit.id));

      if (matchingUnits.length === 0) return null;
      let levelProgress = null;

      levelProgress = progress.completed_units_ids.length / matchingUnits.length;

      const unit = matchingUnits[0];

      let nextLevel = null;
      let nextTitle = null;
      let nextUnitId = null;

      if (unit === undefined) {
        const matchingUnits = units[language]
          .filter((unit) => unit.level === progress.level + 1)
          .filter((unit) => !progress.completed_units_ids.includes(unit.id));
        levelProgress = 0;
        nextLevel = matchingUnits[0].level;
        nextTitle = matchingUnits[0].title;
      }

      return {
        subjectId: subject.id,
        title: subject.title,
        levelLabel: category.level_label,
        gradeCount: category.grade_count,
        unitCount: category.unit_count,
        levelProgress: parseFloat((levelProgress ?? 0).toFixed(2)),
        unitTitle: nextTitle ?? unit.title,
        unitId: nextUnitId ?? unit.id,
        subjectLevel: nextLevel ?? progress.level,
        subjectImage: subjectImage ?? defaultImage,
      };
    })
    .filter((item): item is ISubjectDetail => item !== null);

  return { language, data };
});

export const startSlice = createSlice({
  name: 'start',
  initialState,
  reducers: {
    updateProgressLevelData: (
      state,
      action: PayloadAction<{ language: string; data: ISubjectProgress[] }>
    ) => {
      if (!state.progressLevelData) {
        state.progressLevelData = {};
      }
      state.progressLevelData[action.payload.language] = action.payload.data;
    },

    setCurrentSubjectId: (state, action: PayloadAction<number>) => {
      state.currentSubjectId = action.payload;
    },

    setIsOpenSubjectModal: (state, action: PayloadAction<boolean>) => {
      state.isOpenSubjectModal = action.payload;
    },

    setIsOpenLevelModal: (state, action: PayloadAction<boolean>) => {
      state.isOpenLevelModal = action.payload;
    },

    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPublicSubjects.pending, (state) => {
        state.subjectsStatus = 'loading';
        state.subjectsError = null;
      })

      .addCase(fetchPublicSubjects.fulfilled, (state, action) => {
        state.subjectsStatus = 'succeeded';
        if (!state.subjects) {
          state.subjects = {};
        }
        state.subjects[action.payload.language] = action.payload.data;
      })

      .addCase(fetchPublicSubjects.rejected, (state, action) => {
        state.subjectsStatus = 'failed';
        state.subjectsError = action.error.message || 'Error loading subjects';
      })

      .addCase(fetchPublicUnits.pending, (state) => {
        state.unitsStatus = 'loading';
        state.unitsError = null;
      })

      .addCase(
        fetchPublicUnits.fulfilled,
        (state, action: PayloadAction<{ language: string; data: IUnit[] }>) => {
          state.unitsStatus = 'succeeded';
          if (!state.units) {
            state.units = {};
          }
          state.units[action.payload.language] = action.payload.data;
        }
      )

      .addCase(
        fetchSubjectDetails.fulfilled,
        (
          state,
          action: PayloadAction<{ language: string; data: ISubjectDetail[] }>
        ) => {
          if (!state.subjectDetails) {
            state.subjectDetails = {};
          }
          state.subjectDetails[action.payload.language] = action.payload.data;
        }
      )

      .addCase(fetchPublicUnits.rejected, (state, action) => {
        state.unitsStatus = 'failed';
        state.unitsError = action.error.message || 'Error loading units';
      });
  },
});

export const {
  updateProgressLevelData,
  setIsOpenSubjectModal,
  setIsOpenLevelModal,
  setCurrentSubjectId,
  resetState,
} = startSlice.actions;
export const selectProgressLevelData = (state: { start: SubjectState }) =>
  state.start.progressLevelData;
export const selectIsOpenSubjectModal = (state: { start: SubjectState }) =>
  state.start.isOpenSubjectModal;
export default startSlice.reducer;
