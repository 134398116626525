import React from 'react';
import { useTranslation } from 'react-i18next';
import { AuctionCard } from '../../AuctionCard/AuctionCard';
import { Auction, BidStatusEnum } from '../../../../models/Auction';

type Props = {
  activeAuctions: Auction[];
  plannedAuctions: Auction[];
  onZeroTimer(): void;
};

export const BidActual: React.FC<Props> = ({
  activeAuctions,
  plannedAuctions,
  onZeroTimer,
}) => {
  const { t } = useTranslation(['translation']);

  return (
    <>
      <h1 className={'g-main-title mb-4 block lg:mb-6 lg-max:hidden'}>
        {t('menu.Actual')}
      </h1>
      <div className={'grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3'}>
        {activeAuctions.map((auction) => (
          <AuctionCard
            key={auction.id}
            data={auction}
            status={BidStatusEnum.ACTIVE}
            onZeroTimer={onZeroTimer}
          />
        ))}
        {plannedAuctions.map((auction) => (
          <AuctionCard
            key={auction.id}
            data={auction}
            status={BidStatusEnum.PLANNED}
            onZeroTimer={onZeroTimer}
          />
        ))}
      </div>
    </>
  );
};
