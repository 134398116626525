import React from 'react';
import { useTranslation } from 'react-i18next';
import { AuctionCard } from '../../AuctionCard/AuctionCard';
import { Auction, BidStatusEnum } from '../../../../models/Auction';

type Props = {
  completedAuctions: Auction[];
  onZeroTimer(): void;
};

export const BidPast: React.FC<Props> = ({ completedAuctions, onZeroTimer }) => {
  const { t } = useTranslation(['translation']);

  if (completedAuctions.length === 0) {
    return null;
  }

  return (
    <>
      <h3
        className={
          'mb-2 mt-4 text-base font-semibold text-secondaryGrey-800 lg:mb-4 lg:mt-5 lg:text-2xl lg:font-bold'
        }
      >
        {t('bid.pastBids')}
      </h3>
      <div className={'grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3'}>
        {completedAuctions.map((item) => (
          <AuctionCard
            key={item.id}
            data={item}
            status={BidStatusEnum.COMPLETED}
            onZeroTimer={onZeroTimer}
          />
        ))}
      </div>
    </>
  );
};
