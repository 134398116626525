import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MButton } from '../../../../horizon-components/MButton';
import { ProfileTasksProgress } from './profile-tasks-progress';
import { ProfileTasksQuests } from './profile-tasks-quests';

interface ProfileTasksProps {
  className?: string;
}

export const ProfileTasksContent = ({ className }: ProfileTasksProps) => {
  const { t } = useTranslation(['translation']);
  const [selected, setSelected] = useState<string>('tasks');

  const mobileMenu = useMemo(
    () => [
      { id: 'tasks', title: t('course.tasks'), isShow: true },
      { id: 'progress', title: t('course.progress'), isShow: true },
    ],
    [t]
  );

  return (
    <>
      <div className="hidden w-full gap-2 rounded-full bg-secondary-grey-300 lg-max:flex">
        {mobileMenu
          .filter((menu) => menu.isShow)
          .map((menu) => (
            <MButton
              onClick={() => setSelected(menu.id)}
              key={menu.id}
              variant={selected === menu.id ? 'highlighted' : 'secondary'}
              color="tab"
              className={`w-full !text-secondary-grey-900 ${selected === menu.id ? '!border-secondary-dark-grey-300 !bg-secondary-dark-grey-300' : ''}`}
            >
              {menu.title}
            </MButton>
          ))}
      </div>
      <div className="mt-3 hidden w-full lg-max:inline-flex">
        {selected === 'tasks' ? <ProfileTasksQuests /> : null}
        {selected === 'progress' ? <ProfileTasksProgress /> : null}
      </div>
      <div className="mx-auto inline-flex w-full flex-col items-start justify-start gap-5 lg:flex-row lg-max:hidden">
        <ProfileTasksQuests />
        <ProfileTasksProgress />
      </div>
    </>
  );
};
