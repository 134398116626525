import getPlatform from '../../utils/getPlatform';
import { PlatformsEnum } from '../../entities/Platforms/platforms';
import { useEffect, useState } from 'react';
import { LiveUpdate } from '@capawesome/capacitor-live-update';


const fetchParams = {
  method: "GET",
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }
};
const bundleFile = '/bundleinfo.json'
const platform = getPlatform();
const isMobile = [PlatformsEnum.ANDROID, PlatformsEnum.IOS].includes(platform);

const getLocalInfo = async () => {
  try{
    const local = await fetch(`${bundleFile}`, fetchParams);
    return await local.json();
  } catch (e) {
    return{ error: 'No local bundle'};
  }
};
const getRemoteInfo = async () => {
  try{
    const remote = await fetch(`${process.env.REACT_APP_API_URL}${bundleFile}`, fetchParams);
    return await remote.json();
  } catch (e) {
    return{ error: 'No Internet'};
  }
}

const getBundleUpdate = async () => {
  const [local, rem] = await Promise.all([getLocalInfo(), getRemoteInfo()])
  if ( !local.hasOwnProperty('error') && !rem.hasOwnProperty('error') && rem.version > local.version) {
    return {
      updateNeeded: true,
      url: rem.url,
      bundleId: rem.version,
    };
  }
  else {
    return {
      updateNeeded: false,
      url: rem.url,
      bundleId: rem.version,
      localbundleId: local.version,
    };
  }
};

const downloadBundle = async (url:string, bundleId:number) => {
  await LiveUpdate.downloadBundle({ url: url, bundleId: bundleId.toString()});
};

const setBundle = async (bundleIdNew:number) => {
  await LiveUpdate.setBundle({ bundleId: bundleIdNew.toString() });
};

const reload = async () => {
  await LiveUpdate.reload();
};

const getBundle = async () => {
  const result = await LiveUpdate.getBundle();
  return result.bundleId;
};

const getBundles = async () => {
  const result = await LiveUpdate.getBundles();
  return result.bundleIds;
};

const ready = async () => {
  await LiveUpdate.ready();
};

function getCookieValue(name:string) {
  const regex = new RegExp(`(^| )${name}=([^;]+)`);
  const match = document.cookie.match(regex);
  if (match) {
    return Number(match[2]);
  } else {
    return 0;
  }
}

const BundleUpdate: React.FC<any> = () => {

  if (isMobile) {
    ready();
  }
  const [localVersion, setLocalVersion] = useState(getCookieValue('bundleversion'));

  // console.log(async () => {await getRemoteInfo()})

  useEffect(() => {
    if (isMobile) {
      ready();
      let intervalId = setInterval( async () => {
        const nus = await getBundleUpdate();
        if (nus.updateNeeded){
          clearInterval(intervalId);
          await downloadBundle(nus.url, nus.bundleId);
          await setBundle(nus.bundleId);
          reload();
        }
      }, 30000);

    } else {
      let intervalWebId = setInterval( async () => {
        try {
          const rem = await getRemoteInfo();
          console.log(`Local version: ${localVersion}, Remote version:${rem.version}`)
          if (localVersion !== 0 && rem.version > localVersion){
            clearInterval(intervalWebId);
            setLocalVersion(rem.version);
            document.location.reload();
          }
        } catch (error) {
          document.cookie.split(";").forEach(function(c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
        }
      }, 30000);
    }
  }, []);
  return null;
};


export default BundleUpdate;