import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Navigate } from '../../../horizon-components/Navigate';
import Start from '../../../modules/Start/Start';
import { ImagesProvider } from '../../../providers/StartProvider/ImagesProvider';
import { CharacterPositionProvider } from '../../../providers/StartProvider/PositionContext';

export const StartRouter: React.FC = () => {
  return (
    <CharacterPositionProvider>
      <ImagesProvider>
        <Routes>
          <Route path="/" element={<Start />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </ImagesProvider>
    </CharacterPositionProvider>
  );
};
