import { Languages } from '../i18n';

export function saveCurrentUrl(currentUrl: string) {
  const language = Object.values(Languages).join('|');
  const regex = new RegExp(`^/(${language})`);

  if (regex.test(currentUrl)) {
    const url = currentUrl.replace(regex, '');
    return sessionStorage.setItem('redirectPath', url);
  }

  return sessionStorage.setItem('redirectPath', currentUrl);
}

export function getRedirectPath() {
  return sessionStorage.getItem('redirectPath') ?? null;
}

export function removeRedirectPath() {
  sessionStorage.removeItem('redirectPath');
}
