import {
  DigitalPassCategory,
  DigitalPassCategoryDetail,
} from '../models/DigitalPass';
import { ResponsePagination } from '../models/Response';
import instance from './instance';
import { Grade, GradeProgress } from '../models/Grade';
import { PublicUnit, UnitProgress } from '../models/Unit';

export function getPurchasableSubjectV3(
  language: string,
  page?: number,
  ordering?: string
): Promise<ResponsePagination<DigitalPassCategory>> {
  return instance()
    .get(`/v3/public/purchasable-subjects/purchasable-subject/`, {
      params: { page, ordering, language },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getPurchasedSubjectsV3(
  page?: number
): Promise<ResponsePagination<DigitalPassCategory>> {
  return instance()
    .get(`/v3/private/purchasable-subjects/purchased-subjects/`, {
      params: { page },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getFavouritePurchasableSubjectsV3(): Promise<number[]> {
  return instance()
    .get(`/v3/private/purchasable-subjects/favourite-purchasable-subjects-ids/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getPurchasableSubjectsIdV3(
  id: number,
  language: string
): Promise<DigitalPassCategoryDetail> {
  return instance()
    .get(`/v3/public/purchasable-subjects/purchasable-subject/${id}/`, {
      params: { language },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getGradesByPurchasableSubject(id: number): Promise<Grade[]> {
  return instance()
    .get(`/v3/public/purchasable-subjects/${id}/levels/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getGradesProgress(): Promise<GradeProgress[]> {
  return instance()
    .get(`/v3/private/level/grades-progresses/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getPublicUnits({
  grade,
  digitalPass,
  digitalPassCategory,
}: {
  grade?: number;
  digitalPass?: number;
  digitalPassCategory?: number;
}): Promise<PublicUnit[]> {
  const params: Record<string, number> = {};
  if (grade) params.subject_level = grade;
  if (digitalPass) params.digital_pass = digitalPass;
  if (digitalPassCategory) params.digital_pass_category = digitalPassCategory;
  return instance()
    .get(`/v3/public/unit/units/`, { params })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getUnitsProgress(): Promise<UnitProgress[]> {
  return instance()
    .get(`/v3/private/level/units-progresses/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}
