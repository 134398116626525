import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { updateCourseStatus } from '../../../../../api/course';
import { CustomQuill } from '../../../../../components/CustomQuill/CustomQuill';
import useFetch from '../../../../../hooks/useFetch';
import { useNavigate } from '../../../../../hooks/useNavigate';
import { MButton } from '../../../../../horizon-components/MButton';
import { Modal } from '../../../../../horizon-components/Modal';
import { CourseStatus } from '../../../../../models/Course';
import {
  EduRoutes,
  MainRoutes,
} from '../../../../../horizon-layout/MainLayout/Routes/types/routes';

export const CoursePreviewStaff: React.FC = () => {
  const navigate = useNavigate();
  const { id: courseId } = useParams();
  const [isCommentModalVisible, setIsCommentModalVisible] = useState(false);
  const [comment, setComment] = useState('');
  const { t } = useTranslation(['translation']);

  const { isLoading: isRejecting, fetchData: fetchRejectedStatus } =
    useFetch(updateCourseStatus);
  const { isLoading: isSubmitting, fetchData: fetchVerifiedStatus } =
    useFetch(updateCourseStatus);

  const reject = () => {
    courseId &&
      fetchRejectedStatus(courseId, {
        new_status: CourseStatus.Rejected,
        comment,
      }).then(() => navigate(MainRoutes.edu + EduRoutes['my-courses']));
  };

  const approve = () => {
    courseId &&
      fetchVerifiedStatus(courseId, {
        new_status: CourseStatus.Verified,
        comment: '',
      }).then(() => navigate(MainRoutes.edu + EduRoutes['my-courses']));
  };

  const closeComment = () => setIsCommentModalVisible(false);
  const handleChangeComment = (value: string) => setComment(value);
  const showRejectModal = () => setIsCommentModalVisible(true);

  return (
    <>
      <div
        className={
          'inline-flex flex-col justify-between gap-2 rounded-2xl border border-secondary-grey-500 bg-secondary-grey-200 p-2 shadow-md shadow-shadow-700 md:flex-row md:items-center md:gap-10 md:rounded-[30px] md:p-6'
        }
      >
        <div>
          <p className={'text-[18px] font-[600] text-gray-900'}>
            {t('course.verificationMode')}
          </p>
          <p className={'text-[12px] font-[600] text-gray-500'}>
            {t('course.verificationModeDesc')}
          </p>
        </div>
        <div
          className={
            'mt-2 flex w-full flex-wrap justify-end gap-2 md:gap-5 lg:mt-0 lg:w-auto'
          }
        >
          <MButton
            color={'danger'}
            className={'w-full md:w-auto'}
            variant={'outline'}
            onClick={showRejectModal}
          >
            {t('course.Reject')}
          </MButton>
          <MButton
            color={'primary'}
            className={'w-full md:w-auto'}
            variant={'highlighted'}
            onClick={approve}
            disabled={isSubmitting}
            loading={isSubmitting}
          >
            {t('course.Approve')}
          </MButton>
        </div>
      </div>

      <Modal
        title={t('course.commentRejection')}
        isOpen={isCommentModalVisible}
        onClose={closeComment}
        bodyClassName={'!min-w-full md:!min-w-[760px]'}
      >
        <>
          <CustomQuill
            wrapperClassName={'mb-1'}
            value={comment}
            onChange={handleChangeComment}
          />
          <div className={'flex justify-end'}>
            <MButton
              color={'primary'}
              variant={'outline'}
              onClick={reject}
              disabled={!comment || isRejecting}
              loading={isRejecting}
            >
              {t('course.sendAndReject')}
            </MButton>
          </div>
        </>
      </Modal>
    </>
  );
};
