import classNames from 'classnames';
import { Link } from '../../../horizon-components/Link';
import './Breadcrumbs.scss';
import { Helmet } from 'react-helmet';
import { helmetJsonLdProp } from 'react-schemaorg';
import { BreadcrumbList } from 'schema-dts';

export interface Bread {
  title?: string;
  url?: string;
}

interface BreadcrumbsProps {
  breads?: Bread[];
  className?: string;
}

export const Breadcrumbs = ({ breads = [], className }: BreadcrumbsProps) => {
  const cleanBreads = breads.filter((b) => b.title && b.url);

  return (
    <div className={classNames('Breadcrumbs', className)}>
      {cleanBreads.length > 0 && (
        <>
          <Helmet
            script={[
              helmetJsonLdProp<BreadcrumbList>({
                '@context': 'https://schema.org',
                '@type': 'BreadcrumbList',
                itemListElement: cleanBreads.map((bread, idx) => ({
                  '@type': 'ListItem',
                  position: idx + 1,
                  name: bread.title!,
                  item: `${window.location.origin}${bread.url}`,
                })),
              }),
            ]}
          />
          {cleanBreads.map((bread, idx) => (
            <Link to={bread.url!} key={idx}>
              {bread.title!}
            </Link>
          ))}
        </>
      )}
    </div>
  );
};
