import { Helmet } from 'react-helmet';
import { helmetJsonLdProp } from 'react-schemaorg';
import { ItemList, ListItem, Person } from 'schema-dts';
import { CourseCardItem } from '../../../models/Course';
import { trimIfLarger } from '../../../utils/trimIfLarger';
import { clearStringFromHtmlTags } from '../../../utils/clearStringFromHtmlTags';
import {
  AuthRoutes,
  EduRoutes,
  MainRoutes,
  ProfileRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';
import { Organization } from 'schema-dts';

export interface CoursesLdJsonProps {
  items: CourseCardItem[];
}

function CoursesLdJson({ items }: CoursesLdJsonProps) {
  const provider: Organization = {
    '@type': 'Organization',
    name: 'EdRes',
    url: 'www.edres.com',
  };

  const jsonItems: ListItem[] = items.map((course, i) => ({
    '@type': 'ListItem',
    position: i + 1,
    item: {
      '@type': 'Course',
      name: course.title,
      description: trimIfLarger(
        clearStringFromHtmlTags(course.description) ?? '',
        60
      ),
      url: `https://edres.com${MainRoutes.edu}${EduRoutes.course}/${course.id}`,
      provider: provider,
      offers: {
        '@type': 'Offer',
        category: 'Free',
      },
      hasCourseInstance: [
        {
          '@type': 'CourseInstance',
          courseMode: 'Online',
          courseSchedule: {
            '@type': 'Schedule',
            repeatCount: course.units_count,
            repeatFrequency: 'Weekly',
          },
        } as any,
      ],
      // provider: course.owner && {
      //   '@type': 'Person',
      //   name: course.owner.name,
      //   sameAs: `https://edres.com${MainRoutes.auth}${AuthRoutes.profile}${ProfileRoutes.user}/${course.owner.id}`,
      // },
    },
  }));

  return (
    <Helmet
      script={[
        helmetJsonLdProp<ItemList>({
          '@context': 'https://schema.org',
          '@type': 'ItemList',
          itemListElement: jsonItems,
        }),
      ]}
    />
  );
}

export default CoursesLdJson;
