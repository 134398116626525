import classNames from 'classnames';
import './Badge.scss';

interface BadgeProps {
  className?: string;
  styles?: React.CSSProperties;
  children: React.ReactNode;
  size?: 'xl' | 'lg' | 'md' | 'sm';
  variant?: 'primary' | 'secondary' | 'success' | 'danger' | 'outline' | 'gradient';
  disabled?: boolean;
  active?: boolean;
  onClick?: () => void;
}

export const Badge = ({
  className,
  children,
  size = 'md',
  variant = 'primary',
  disabled = false,
  active = false,
  onClick,
  styles, // Add this line to destructure styles from props
}: BadgeProps) => {
  return (
    <div
      className={classNames('Badge', className, size, variant, { disabled, active })}
      style={styles}
      onClick={onClick}
    >
      {children}
    </div>
  );
};
