import React, { useEffect, useMemo, useState } from 'react';
import { Loader } from '../../../../components/Loader';
import { Modal } from '../../../../horizon-components/Modal';
import { AuctionViewBreadcrumbs } from './auction-view-breadcrumbs';
import { AuctionViewContent } from './auction-view-content';
import { AuctionViewPoster } from './auction-view-poster';
import { AuctionViewWinnerForm } from './auction-view-winner-form';
import { useBidServiceContext } from '../../../../providers/BidServiceProvider/BidServiceProvider';
import { useAuctionContext } from '../../../../providers/AuctionProvider/AuctionProvider';
import { getIsRewardClaimed } from '../../../../api/bid';
import { AuctionViewWinner } from './auction-view-winner';
import { useTranslation } from 'react-i18next';
import { BidStatusEnum } from '../../../../models/Auction';

type Props = {
  auctionId: number;
};

export const AuctionView: React.FC<Props> = ({ auctionId }) => {
  const { getMyBidForAuction, isMyBidsLoading, fetchBidsCommonInfo } =
    useBidServiceContext();
  const { t } = useTranslation(['translation']);

  const {
    auction,
    getIsWinner,
    isAuctionLoading,
    bids,
    auctionStatus,
    isBidsLoading,
  } = useAuctionContext();
  const [winnerFormModalIsOpen, setWinnerFormModalIsOpen] = useState(false);
  const [winnersListModalIsOpen, setWinnersListModalIsOpen] = useState(false);

  const [winners, isMultiWinners] = useMemo(() => {
    const winners = bids?.filter((item) => getIsWinner(item)) || [];
    const isMultiWinners = winners.length > 1;
    return [winners, isMultiWinners];
  }, [getIsWinner, bids, auctionStatus]);

  useEffect(() => {
    if (!isMyBidsLoading && !isBidsLoading && auction) {
      const myBid = getMyBidForAuction(auctionId);
      const isWinner = getIsWinner(myBid);
      if (isWinner) {
        getIsRewardClaimed(auctionId).then((isRewardClaimed) => {
          if (!isRewardClaimed) {
            setWinnerFormModalIsOpen(true);
            setWinnersListModalIsOpen(false);
          } else if (winners.length >= 1) {
            setWinnersListModalIsOpen(true);
            setWinnerFormModalIsOpen(false);
          }
        });
      } else if (auctionStatus === BidStatusEnum.COMPLETED && winners.length >= 1) {
        setWinnersListModalIsOpen(true);
        setWinnerFormModalIsOpen(false);
      }
    }
  }, [
    auctionId,
    getMyBidForAuction,
    getIsWinner,
    setWinnerFormModalIsOpen,
    auctionStatus,
    setWinnersListModalIsOpen,
    isMyBidsLoading,
    isBidsLoading,
  ]);

  useEffect(() => {
    fetchBidsCommonInfo();
  }, []);

  return (
    <div>
      <div className="container mb-2.5 hidden md:block">
        <AuctionViewBreadcrumbs />
      </div>
      {isAuctionLoading ? (
        <Loader />
      ) : (
        <div className={'grid grid-cols-1 gap-4 pb-4 lg:grid-cols-2 lg:gap-3.5'}>
          <AuctionViewPoster />
          <AuctionViewContent />

          {auction && (
            <Modal
              modalId={'winner-form-modal'}
              isOpen={winnerFormModalIsOpen}
              onClose={() => setWinnerFormModalIsOpen(false)}
              showClose={false}
              bodyClassName={`max-w-[520px]`}
            >
              <AuctionViewWinnerForm
                auctionId={auctionId}
                auction={auction}
                onClose={() => setWinnerFormModalIsOpen(false)}
              />
            </Modal>
          )}
          {auction && (
            <Modal
              modalId={'winned-modal'}
              isOpen={winnersListModalIsOpen}
              onClose={() => setWinnersListModalIsOpen(false)}
              title={t('bid.Our_winners')}
              bodyClassName={`max-w-[390px]`}
            >
              <AuctionViewWinner
                isMultiWinners={isMultiWinners}
                winners={winners}
                auction={auction}
                onClose={() => setWinnersListModalIsOpen(false)}
              />
            </Modal>
          )}
        </div>
      )}
    </div>
  );
};
