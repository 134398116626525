export const formatNumber = (numberString: string, lang: string): string => {
  const number = parseFloat(numberString.replaceAll(' ', ''));
  const formattedNumber = new Intl.NumberFormat(
    lang === 'ru' || lang === 'en' ? lang : 'ru',
    {
      maximumFractionDigits: 0,
    }
  ).format(number);

  return formattedNumber;
};
