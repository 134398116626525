import { Link as RouterLink, LinkProps } from 'react-router-dom';
import { useLanguage } from '../../providers/LanguageProvider';

export const Link = ({ to, ...props }: LinkProps) => {
  const { language } = useLanguage();

  const isExternalLink =
    typeof to === 'string' &&
    (to.startsWith('http://') || to.startsWith('https://'));

  if (!isExternalLink) {
    const path =
      typeof to === 'string'
        ? `/${language}${to}`
        : { ...to, pathname: `/${language}${to.pathname}` };

    return <RouterLink to={path} {...props} />;
  }

  return <RouterLink to={to} {...props} />;
};
