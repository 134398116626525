import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CourseRequest } from '../../../api/course';
import {
  getPrivateEnrolledCoursesV3,
  getPrivateFavouriteCoursesV3,
  getPublicCoursesV3,
} from '../../../api/courseV3';
import { Loader } from '../../../components/Loader';
import { NotFound } from '../../../components/NotFound/NotFound';
import useFetch from '../../../hooks/useFetch';
import { Pagination } from '../../../horizon-components/Pagination';
import { CourseCardStatus } from '../../../models/Course';
import { useUserContext } from '../../../providers';
import { CourseCard } from '../../../widgets/CourseCard';
import SEO from '../../../components/SEO/SEO';
import CoursesLdJson from './CoursesLdJson';
import useHistoryParams from '../../../hooks/useHistoryParams';

export const AllCourses: React.FC = () => {
  const { t, i18n } = useTranslation(['translation']);
  const { user } = useUserContext();
  const {
    addParamToUrl,
    getParamFromUrl,
    removeParamFromUrl,
    removeEmptyParamsFromUrl,
  } = useHistoryParams();
  const pageParam = getParamFromUrl('page') || 1;
  const titleParam = getParamFromUrl('title') || '';
  const tagsOrTitleParam = getParamFromUrl('tags_or_title') || '';

  const {
    data,
    isLoading,
    fetchData: fetchCourses,
  } = useFetch((params: CourseRequest) => getPublicCoursesV3(params));

  const { data: enrolled, fetchData: fetchEnrolled } = useFetch(
    getPrivateEnrolledCoursesV3
  );

  const { data: favorites, fetchData: fetchFavourite } = useFetch(
    getPrivateFavouriteCoursesV3
  );

  useEffect(() => {
    fetchCourses({
      page: Number(pageParam),
      miscellaneous_subject: true,
      language: i18n.language,
      ...(titleParam && { title: titleParam }),
      ...(tagsOrTitleParam && { tags_or_title: [tagsOrTitleParam] }),
    });
    removeEmptyParamsFromUrl();
  }, [i18n.language, pageParam, titleParam, tagsOrTitleParam]);

  useEffect(() => {
    if (user?.guid) {
      fetchEnrolled();
      fetchFavourite();
    }
  }, [t, user?.guid]);

  const handleChangePage = (newPage: number) => {
    if (newPage === 1) {
      removeParamFromUrl('page');
    } else {
      addParamToUrl('page', newPage);
    }
  };

  return (
    <>
      <SEO
        title={t('pages.courses.meta.title')}
        desc={t('pages.courses.meta.desc')}
        canonicalParams={['page', 'search', 'q']}
      />
      {data?.results?.length ? <CoursesLdJson items={data.results} /> : null}

      <div className="flex flex-col gap-4 md:gap-5">
        <h3 className="self-stretch text-base font-semibold text-secondary-grey-800 md:text-2xl md:font-bold">
          {t('course.allCourses')}
        </h3>
        {isLoading ? (
          <Loader />
        ) : !data?.results.length ? (
          <NotFound text={t('course.Coming_soon')} />
        ) : (
          <div className="grid grid-cols-1 gap-x-6 gap-y-5 md:grid-cols-3 lg:grid-cols-4">
            {data?.results?.map((course) => (
              <CourseCard
                course={course}
                key={course.id}
                status={
                  !!enrolled?.find((enroll) => enroll === course.id)
                    ? CourseCardStatus.ENROLLED
                    : CourseCardStatus.CAN_ENROLL
                }
                favorite={!!favorites?.includes(course?.id)}
                onFavorite={fetchFavourite}
              />
            ))}
          </div>
        )}
        {data?.count ? (
          <div className={'flex w-full items-center justify-center'}>
            <Pagination
              totalPages={Math.ceil(data?.count / 12)}
              currentPage={Number(pageParam)}
              showPrevNext={true}
              onPageChange={handleChangePage}
            />
          </div>
        ) : null}
      </div>
    </>
  );
};
