import { BidStatusEnum } from '../../../../models/Auction';
import { AuctionViewBidsAllModal } from './auction-view-bids-all-modal';
import { AuctionViewBidsMy } from './auction-view-bids-my';
import { AuctionViewBidsWinnerList } from './auction-view-bids-winner-list';
import { AuctionViewBidsOtherList } from './auction-view-bids-other-list';
import { Loader } from '../../../../components/Loader';
import { useAuctionContext } from '../../../../providers/AuctionProvider/AuctionProvider';

export const AuctionViewBids = () => {
  const { auctionStatus, bids, isBidsLoading } = useAuctionContext();

  return (
    <div
      className={
        'rounded-2xl p-2 !shadow-[4px_4px_24px_4px_#7090B014] lg:rounded-3xl lg:p-4'
      }
    >
      {isBidsLoading && <Loader />}
      {!isBidsLoading && (
        <div className="flex flex-col items-center justify-between gap-3">
          {!!bids?.length && (
            <>
              {auctionStatus === BidStatusEnum.COMPLETED ? (
                <AuctionViewBidsWinnerList />
              ) : (
                <AuctionViewBidsOtherList />
              )}
            </>
          )}
          <AuctionViewBidsMy />
          <AuctionViewBidsAllModal />
        </div>
      )}
    </div>
  );
};
