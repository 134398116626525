import React from 'react';
import { useTranslation } from 'react-i18next';
import { Topic } from '../../../../models/Course';
import { MCheckbox } from '../../../../horizon-components/MCheckbox/MCheckbox';

type Props = {
  topic?: Topic | null;
};

export const CoursePreviewTopicTest: React.FC<Props> = ({ topic }) => {
  const { t } = useTranslation(['translation']);

  if (!topic) return null;

  return (
    <>
      {/* Header */}
      <div className="flex items-center justify-between gap-2">
        <div className={'text-[14px] font-bold md:text-[18px]'}>
          {t('edu.Topic_test')} ({t('optional')})
        </div>
      </div>
      {/*Content*/}
      <div className={'flex flex-col gap-[32px]'}>
        {topic?.questions?.map((question, index) => (
          <div className={'flex flex-col gap-[12px]'}>
            <div
              className={
                'flex text-[14px] font-semibold text-secondary-dark-grey-900 md:text-[16px]'
              }
            >
              <span className="font-semibold">{index + 1}. </span>
              <span
                className={'font-medium'}
                dangerouslySetInnerHTML={{ __html: question.text }}
              />
            </div>
            {question.topic_answers.map((answer) => (
              <MCheckbox
                key={answer.id}
                checked={answer.correct}
                type={question.is_multiple_answers ? 'checkbox' : 'radio'}
                text={answer.text}
                id={`answer${answer.id}index${index + 1}`}
                disabled
              />
            ))}
          </div>
        ))}
      </div>
    </>
  );
};
