import { TTaskType } from '../../../../models/Tasks';

export class ValidationException extends Error {
  constructor(message: string) {
    super(message);
    this.name = this.constructor.name;
  }
}

export class TaskValidator {
  validate(task: TTaskType): void {
    throw new Error('Method not implemented');
  }
}
