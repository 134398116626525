import classNames from 'classnames';
import './style.scss';
import { DailyRewardStatusEnum } from '../../models/Reward';
import FireImg from '../../assets/icons/daily/fire.svg';
import FireActiveImg from '../../assets/icons/daily/fire-active.svg';
import CheckCircle from '../../assets/icons/daily/check-circle.svg';
import { useTranslation } from 'react-i18next';

interface QuizDayCardProps {
  className?: string;
  title?: string;
  earn?: string;
  multiplier?: string;
  status?: DailyRewardStatusEnum;
  onClick?: () => void;
}

export const DailyRewardCard = ({
  className,
  title,
  earn,
  multiplier,
  status,
  onClick,
}: QuizDayCardProps) => {
  let cardContent;

  const { t } = useTranslation(['translation']);

  switch (status) {
    case DailyRewardStatusEnum.CAN_BE_CLAIMED:
      cardContent = (
        <>
          <h3>{title}</h3>
          <div className="DailyRewardCard__image-container">
            <img src={FireActiveImg} alt="" />
          </div>
          <p>
            {earn} + {multiplier}
          </p>
        </>
      );
      break;

    case DailyRewardStatusEnum.CLAIMED:
      cardContent = (
        <>
          <h3>{title}</h3>
          <div className="DailyRewardCard__image-container">
            <img src={CheckCircle} alt="" />
          </div>
          <p>{t('buttons.Received')}</p>
        </>
      );
      break;

    case DailyRewardStatusEnum.UPCOMING:
      cardContent = (
        <>
          <h3>{title}</h3>
          <div className="DailyRewardCard__image-container">
            <img src={FireImg} alt="" />
          </div>
          <p>
            {earn} + {multiplier}
          </p>
        </>
      );
      break;
  }

  return (
    <div
      className={classNames('DailyRewardCard', className, status)}
      onClick={onClick}
    >
      {cardContent}
    </div>
  );
};
