import classNames from 'classnames';
import { ReactComponent as ArrowLeftIcon } from '../../../assets/icons/arrow-left.svg';
import { useNavigate } from '../../../hooks/useNavigate';
import { MButton } from '../../../horizon-components/MButton/ui/MButton';
import { useTitleBackContext } from '../../../providers/TitleBackProvider/TitleBackProvider';
import './TitleBack.scss';

interface TitleBackProps {
  className?: string;
}

export const TitleBack = ({ className }: TitleBackProps) => {
  const navigate = useNavigate();
  const { title, isShowTitleBack, backUrl, onClick, elements, isShowArrow } =
    useTitleBackContext();

  const goTo = () => {
    if (backUrl) {
      navigate(backUrl);
    } else {
      if (onClick) {
        onClick();
        return;
      }
      navigate(-1);
    }
  };

  if (!isShowTitleBack) {
    return null;
  }

  return (
    <div className={classNames('TitleBack', className)}>
      <div className="flex items-center gap-2">
        {isShowArrow && (
          <MButton
            color="white"
            variant="highlighted"
            onClick={goTo}
            className="!p-0"
          >
            <ArrowLeftIcon />
          </MButton>
        )}
        {title}
      </div>
      {elements}
    </div>
  );
};
