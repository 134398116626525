import classNames from 'classnames';
import { ReactNode, useRef, useState } from 'react';
import { ReactComponent as StarIcon } from '../../assets/icons/star-full.svg';
import { ProgressBar } from '../../horizon-components/ProgressBar';
import { TaskProgressStatus, TaskRewardTypeEnum } from '../../models/Tasks';
interface ProgressCardProps {
  className?: string;
  title?: string;
  total?: number;
  count?: number;
  status?: TaskProgressStatus;
  award?: string;
  executionsCount?: number;
  taskRewardType?: TaskRewardTypeEnum;
  children?: ReactNode;
  image?: string;
}

export const ProgressCard = ({
  className,
  title,
  total = 0,
  count = 0,
  children,
  image,
  status,
}: ProgressCardProps) => {
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleWheel = (e: React.WheelEvent) => {
    e.preventDefault();
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        left: e.deltaY * 1.5,
        behavior: 'smooth',
      });
    }
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    setIsDragging(true);
    setStartX(e.pageX - (scrollRef.current?.offsetLeft || 0));
    setScrollLeft(scrollRef.current?.scrollLeft || 0);
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!isDragging || !scrollRef.current) return;
    e.preventDefault();
    const x = e.pageX - scrollRef.current.offsetLeft;
    const walk = (x - startX) * 2;
    scrollRef.current.scrollLeft = scrollLeft - walk;
  };

  return (
    <div
      className={classNames(
        'grid w-full flex-col items-start justify-stretch gap-4 rounded-[20px] bg-[#e9e3ff] p-4',
        {
          'bg-[#e9e3ff]': status !== 'REWARD_RECEIVED',
          'bg-white shadow-md shadow-shadow-700': status === 'REWARD_RECEIVED',
        },
        className
      )}
    >
      <div className="inline-flex w-full items-start justify-stretch gap-2 self-stretch">
        {image ? (
          <div className="flex items-center justify-center gap-2 rounded-2xl border-2 border-[#d456ff] p-3">
            <img className="relative h-[18px] w-[18px]" src={image} alt={title} />
          </div>
        ) : null}
        <div className="inline-flex shrink grow basis-0 flex-col items-start justify-center gap-1">
          <h4 className="text-sm font-semibold leading-[18px] text-[#2b3674]">
            {title}
          </h4>
          {status !== 'REWARD_RECEIVED' ? (
            <div className="inline-flex items-center justify-start gap-2 self-stretch">
              <ProgressBar rating={(count / total) * 100} size="sm" />
              <div className="flex items-center justify-center gap-0.5 rounded-2xl">
                <span className="text-center text-[10px] font-semibold leading-3 text-[#4318ff]">
                  {count}/{total}
                </span>
              </div>
            </div>
          ) : null}
        </div>
        {status === 'REWARD_RECEIVED' ? (
          <div className="ml-auto inline-flex h-[42px] items-center justify-center gap-2 rounded-3xl bg-[#c9fbd5] p-3">
            <StarIcon className="relative h-[18px] w-[18px] fill-primary-green-500" />
          </div>
        ) : null}
      </div>
      {children ? (
        <div
          ref={scrollRef}
          onWheel={handleWheel}
          onMouseDown={handleMouseDown}
          onMouseLeave={handleMouseLeave}
          onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove}
          className="inline-flex w-full cursor-grab items-start justify-start gap-2 self-stretch overflow-x-scroll whitespace-nowrap rounded-xl lg-max:[-ms-overflow-style:none] lg-max:[scrollbar-width:none] lg-max:[&::-webkit-scrollbar]:hidden"
        >
          {children}
        </div>
      ) : null}
    </div>
  );
};
