import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getUserSomeDataStorage } from '../../../../api';
import {
  getPrivateRetrieveTaskCondition,
  getPrivateTasks,
  setPrivateClaimedTaskReward,
  setPrivateStartTask,
} from '../../../../api/tasks';
import DailyRewardIcon from '../../../../assets/icons/tasks/reward.svg';
import NoTaskImg from '../../../../assets/images/task/no-task.webp';
import { Loader } from '../../../../components/Loader';
import useFetch from '../../../../hooks/useFetch';
import { BottomSheetModal } from '../../../../horizon-components/bottom-sheet-modal';
import { TaskProgressStatus, TTaskType } from '../../../../models/Tasks';
import { useDailyReward, useUserContext } from '../../../../providers';
import { CongratulationTaskModal } from '../../../../widgets/congratulation-task-modal';
import { QuestCard } from '../../../../widgets/quest-card';
import { TaskInfoModal } from '../../../../widgets/task-info-modal';
import { VALIDATORS_MAPPING } from '../task_validators/constants';
import { toast } from 'react-toastify';
import { ValidationException } from '../task_validators/base';
import { hasDomainInUrl } from '../../../../shared/utils/hasDomainInUrl';
import { useNavigate } from '../../../../hooks/useNavigate';
import getPlatform from '../../../../utils/getPlatform';
import { PlatformsEnum } from '../../../../entities/Platforms/platforms';
import { BEHAVIOUR_MAPPING } from '../task_behaviour/constants';
import { DownloadMobileAppModal } from '../../../../components/DownloadMobileAppModal';

export const ProfileTasksQuests = () => {
  const { t } = useTranslation(['translation']);
  const [isOpen, setIsOpen] = useState(false);
  const [checkTask, setCheckTask] = useState<TTaskType>();
  const { user, updateWallet, hasClaimedReward } = useUserContext();
  const [behaviourComponentKey, setBehaviourComponentKey] = useState<null | string>(
    null
  );
  const [showDownloadMobileAppModal, setShowDownloadMobileAppModal] =
    useState(false);
  const navigate = useNavigate();

  const { hndlOpenStartModal } = useDailyReward();

  const [isClaimedReward, setIsClaimedReward] = useState(false);

  useEffect(() => {
    setIsClaimedReward(hasClaimedReward);
  }, [hasClaimedReward]);

  const dailyTaskCoin = getUserSomeDataStorage('dailyTaskCoin', user?.guid);
  const dailyTaskMultiplier = getUserSomeDataStorage(
    'dailyTaskMultiplier',
    user?.guid
  );

  const { data, isLoading, fetchData } = useFetch(getPrivateTasks);
  const {
    data: reward,
    isLoading: isRewardLoading,
    fetchData: fetchReward,
    clearData: clearReward,
  } = useFetch(setPrivateClaimedTaskReward);
  const {
    data: condition,
    isLoading: isConditionLoading,
    fetchData: fetchCondition,
    clearData: clearCondition,
  } = useFetch(getPrivateRetrieveTaskCondition);

  const handleData = useCallback(() => {
    fetchData({ execution_frequency: 'DAILY,ONE_TIME' });
  }, [fetchData]);

  useEffect(() => {
    handleData();
  }, [handleData, t]);

  const callStart = (task: TTaskType) => {
    return new Promise<void>((resolve, reject) => {
      setPrivateStartTask(task.id)
        .then((res) => {
          if (res?.status === 'success' && task.task_progress.status === 'PENDING') {
            task?.id &&
              setCheckTask({
                ...task,
                task_progress: {
                  ...task?.task_progress,
                  status: 'RUNNING',
                },
              });
          }
        })
        .finally(() => {
          handleData();
          resolve();
        });
    });
  };

  const handleStart = async (task: TTaskType) => {
    const response = await fetchCondition(task.id);
    if (response?.id && !isOpen) {
      handleToggle();
    } else {
      const customBehaviour = BEHAVIOUR_MAPPING[task.task_type];
      if (task.task_progress.status === 'PENDING') {
        await callStart(task);
      }
      if (customBehaviour) {
        setIsOpen(false);
        clearReward();
        clearCondition();
        setBehaviourComponentKey(task.task_type);
      } else {
        if (task.link) {
          const platform = getPlatform();
          const externalUrl = hasDomainInUrl(task.link);
          if (platform === PlatformsEnum.TELEGRAM && externalUrl) {
            window.Telegram!.WebApp.openTelegramLink(task.link);
          } else if (externalUrl) {
            window.open(task.link, '_blank');
          } else {
            navigate(task.link);
          }
        }
      }
    }
  };

  const handleClaimButton = async (task: TTaskType) => {
    const response = await fetchCondition(task.id);
    if (response?.id) {
      if (!isOpen) {
        handleToggle();
      }
    } else {
      handleReward(task);
    }
  };

  const clientValidation = (task: TTaskType): boolean => {
    const validator_class = VALIDATORS_MAPPING[task.task_type];
    if (validator_class) {
      const validator = new validator_class();
      try {
        validator.validate(task);
        return true;
      } catch (e) {
        if (e instanceof ValidationException) {
          // TODO: Move this piece of shit out of here
          if (task.task_type === 'DOWNLOAD_MOBILE_APP') {
            setIsOpen(false);
            clearReward();
            clearCondition();
            setShowDownloadMobileAppModal(true);
          } else {
            toast(e.message, {
              type: 'error',
            });
          }
          return false;
        }
        throw e;
      }
    }
    return true;
  };

  const handleReward = (task: TTaskType) => {
    const isValid = clientValidation(task);
    if (!isValid) {
      return;
    }
    fetchReward(task.id)
      .then((res) => {
        if (res?.status === 'success') {
          !isOpen && handleToggle();
          task?.id &&
            setCheckTask({
              ...task,
              task_progress: {
                ...task?.task_progress,
                status: 'REWARD_RECEIVED',
              },
            });
          user?.guid && updateWallet();
        }
      })
      .finally(handleData);
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
    isOpen && clearReward();
    isOpen && clearCondition();
  };

  const endedTasks = useMemo(
    () =>
      data?.results?.filter(
        (item) => item.task_progress.status === 'REWARD_RECEIVED'
      ),
    [data]
  );

  const activeTasks = useMemo(
    () =>
      data?.results?.filter(
        (item) => item.task_progress.status !== 'REWARD_RECEIVED'
      ),
    [data]
  );

  const CustomBehaviourComponent = behaviourComponentKey
    ? BEHAVIOUR_MAPPING[behaviourComponentKey]
    : null;

  return (
    <div
      className={
        'inline-flex w-full flex-col items-start justify-start gap-4 rounded-[30px] bg-white p-0 lg:p-6 lg:shadow-md lg:shadow-shadow-700'
      }
    >
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {activeTasks?.length ? (
            <>
              <div className="flex flex-col items-start justify-start gap-0.5">
                <h2 className="text-xl font-semibold leading-normal text-[#2b3674]">
                  {t('profile.Quests')}
                </h2>
                <p className="text-base font-normal leading-tight text-[#8f9bba]">
                  {t('profile.Daily_and_one_time_classes')}
                </p>
              </div>
              {!isClaimedReward ? (
                <QuestCard
                  title={t('Claim daily reward')}
                  status={'RUNNING'}
                  award={dailyTaskCoin?.toString() || '0'}
                  multiplier={dailyTaskMultiplier?.toString() || '0'}
                  onClick={hndlOpenStartModal}
                />
              ) : undefined}

              {activeTasks?.map((item) => (
                <QuestCard
                  key={item.id}
                  title={item.title}
                  award={item.award}
                  taskRewardType={item.task_reward_type}
                  onTaskStart={() => handleStart(item)}
                  taskType={item.task_type}
                  onTaskCondition={() => handleClaimButton(item)}
                  onTaskReward={() => handleReward(item)}
                  onClick={() => setCheckTask(item)}
                  status={item.task_progress.status}
                  image={item.image}
                />
              ))}
            </>
          ) : (
            <>
              <img src={NoTaskImg} alt="no task" className="mx-auto" />
              <div className="w-full text-center text-base font-semibold leading-tight text-[#7551ff]">
                {t('task.You_have_completed_all_the_quests')}
              </div>
            </>
          )}
          {isClaimedReward || endedTasks?.length ? (
            <h3 className="!text-sm font-semibold leading-[18px] text-[#2b3674]">
              {t('quest.Completed')}
            </h3>
          ) : null}
          {isClaimedReward ? (
            <QuestCard
              title={t('reward.daily.task')}
              taskType={'LOGIN_TO_APP'}
              award={dailyTaskCoin?.toString() || '0'}
              multiplier={dailyTaskMultiplier?.toString() || '0'}
              status={'REWARD_RECEIVED'}
              image={DailyRewardIcon}
            />
          ) : undefined}
          {endedTasks?.length ? (
            <>
              {endedTasks?.map((item) => (
                <QuestCard
                  key={item.id}
                  title={item.title}
                  award={item.award}
                  taskType={item.task_type}
                  taskRewardType={item.task_reward_type}
                  onTaskCondition={() => handleToggle()}
                  onClick={() => setCheckTask(item)}
                  status={item.task_progress.status}
                  image={item.image}
                />
              ))}
            </>
          ) : null}
        </>
      )}
      <BottomSheetModal close={handleToggle} opened={isOpen}>
        {checkTask?.task_progress.status === 'REWARD_RECEIVED' ? (
          <CongratulationTaskModal
            onToggle={handleToggle}
            award={checkTask?.award}
          />
        ) : (
          <TaskInfoModal
            id={checkTask?.id}
            title={condition?.title}
            notice={condition?.notice}
            description={condition?.description}
            onStart={() => checkTask?.id && handleStart(checkTask)}
            onReward={() => checkTask?.id && handleReward(checkTask)}
            error={reward?.error || condition?.error}
            loading={isRewardLoading}
            isLoading={isConditionLoading}
            status={checkTask?.task_progress.status}
            award={checkTask?.award}
            taskType={checkTask?.task_type}
            link={checkTask?.link}
          />
        )}
      </BottomSheetModal>
      {CustomBehaviourComponent && (
        <CustomBehaviourComponent
          stopBehaviour={() => setBehaviourComponentKey(null)}
        />
      )}
      <DownloadMobileAppModal
        text={t('task.mobile_app_error')}
        showModal={showDownloadMobileAppModal}
        onClose={() => setShowDownloadMobileAppModal(false)}
      />
    </div>
  );
};
