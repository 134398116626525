// @ts-nocheck

import React from 'react';

export const YandexMetrica: React.FC = () => {
  const metricaId = process.env.REACT_APP_YANDEX_METRICA_ID;

  if (!metricaId) {
    return null;
  }

    (function () {
        window["ym"] = window["ym"] || function() {(window["ym"].a = window["ym"].a || []).push(arguments)};
        window["ym"].l = 1 * new Date();
        var initYMconf = function() {
            window.ym(metricaId, "init", {
                webvisor: false,
                clickmap: false,
                trackLinks: true,
                accurateTrackBounce: true,
                ecommerce: "dataLayer"
            })
        };

    var initYMconfE = function () {
      window.ym(metricaId, 'init', {
        webvisor: false,
        clickmap: false,
        trackLinks: true,
        accurateTrackBounce: false,
        ecommerce: 'dataLayer',
      });
    };

    function loadMCScript(src, okcallback, errcallback) {
      var s, t;
      s = document.createElement('script');
      s.async = !0;
      s.src = src;
      s.onload = function () {
        if (typeof okcallback === 'function') {
          okcallback();
        }
      };
      s.onerror = function () {
        if (typeof errcallback === 'function') {
          errcallback();
        }
      };
      t = document.getElementsByTagName('script')[0];
      t.parentNode.insertBefore(s, t);
    }

    setTimeout(function () {
      var cff2 = function () {
        loadMCScript('https://cfwosp.edres.com/metrika/ymtag.js', initYMconfE);
      };
      loadMCScript(
        `${process.env.REACT_APP_API_URL}/analytics/tag`,
        initYMconf,
        cff2
      );
    }, 100);
  })();

  return (
    <div>
      <img
        src={`https://mc.yandex.ru/watch/${metricaId}`}
        style={{ position: 'absolute', left: '-9999px' }}
        alt="metrika img"
      />
    </div>
  );
};
