import { getAssesTokenStorage } from '../storages';
import { SOCKET_URL } from '../instance';

export const getChatConnect = (chatId: number): Promise<WebSocket> => {
  return new Promise((resolve, reject) => {
    const socket = new WebSocket(
      `${SOCKET_URL}/weber/ws/chat/${chatId}/?token=${getAssesTokenStorage()}`
    );

    socket.onopen = () => {
      resolve(socket);
    };

    socket.onerror = () => {
      reject();
    };
  });
};

// export const getNotificationsConnect = (): Promise<WebSocket> => {
//   return new Promise((resolve, reject) => {
//     const socket = new WebSocket(
//       `${SOCKET_URL}/auth/ws/notifications/?token=${getAssesTokenStorage()}`
//     );

//     socket.onopen = () => {
//       resolve(socket);
//     };

//     socket.onerror = () => {
//       reject();
//     };
//   });
// };

export const getNotificationsConnect = async (): Promise<WebSocket> => {
  try {
    const socket = new WebSocket(
      `${SOCKET_URL}/auth/ws/notifications/?token=${getAssesTokenStorage()}`
    );

    await new Promise<void>((resolve, reject) => {
      socket.onopen = () => {
        // console.log("WebSocket connection established.");
        resolve();
      };

      socket.onerror = (event) => {
        console.error('WebSocket connection error:', event);
        reject(new Error('Failed to connect to WebSocket.'));
      };

      socket.onclose = (event) => {
        console.log('WebSocket connection closed:', event);
      };
    });

    return socket;
  } catch (error) {
    console.error('Error during WebSocket initialization:', error);
    throw error;
  }
};

type WebSocketEvents = {
  onOpen?: () => void;
  onClose?: (event: CloseEvent) => void;
  onError?: (event: Event) => void;
  onMessage?: (message: MessageEvent) => void;
};

export class WebSocketWrapper {
  private url: string;
  private ws: WebSocket | null = null;
  private reconnectInterval: number;
  private maxReconnectRetries: number;
  private reconnectRetriesCount: number = 0;
  private pingInterval: number;
  private reconnectTimeoutId: ReturnType<typeof setTimeout> | null = null;
  private pingTimeoutId: ReturnType<typeof setTimeout> | null = null;
  private pongMissCount: number = 0;
  private maxPongMisses: number;

  public events: WebSocketEvents;

  constructor(
    url: string,
    options: {
      reconnectInterval?: number; // ms
      pingInterval?: number; // ms
      pongTimeout?: number; // ms
      maxPongMisses?: number;
      maxReconnectRetries?: number;
    } = {},
    events: WebSocketEvents = {}
  ) {
    this.url = url;
    this.reconnectInterval = options.reconnectInterval ?? 5000;
    this.pingInterval = options.pingInterval ?? 30000;
    this.maxPongMisses = options.maxPongMisses ?? 4;
    this.maxReconnectRetries = options.maxReconnectRetries ?? 3;
    this.events = events;
  }

  public async connect(tryReconnect: boolean = false) {
    const ws = new WebSocket(this.url);
    try {
      await new Promise<void>((resolve, reject) => {
        ws.onopen = () => {
          console.log('WebSocket connection established.');
          resolve();
        };

        ws.onerror = (event) => {
          console.error('WebSocket connection error:', event);
          reject(new Error('Failed to connect to WebSocket.'));
        };
      });
    } catch (e) {
      if (!tryReconnect) {
        throw e;
      } else {
        console.warn('Websocket connection failed, retrying...');
        this.scheduleReconnect();
      }
    }

    this.setupWebSocket(ws);
  }

  private setupWebSocket(ws: WebSocket) {
    this.ws = ws;
    this.pongMissCount = 0;
    this.reconnectRetriesCount = 0;
    this.startPing();
    this.events.onOpen?.();

    this.ws.onopen = () => {
      this.events.onOpen?.();
    };

    this.ws.onmessage = (event) => {
      const parsedData = JSON.parse(event.data);
      if (parsedData.type === 'pong') {
        this.pongMissCount = 0; // Reset pong misses on receiving pong
      } else {
        this.events.onMessage?.(event);
      }
    };

    this.ws.onerror = (event) => {
      this.events.onError?.(event);
    };

    this.ws.onclose = (event) => {
      this.stopPing();
      this.events.onClose?.(event);
    };
  }

  private scheduleReconnect() {
    if (this.reconnectTimeoutId) return;

    const attemptReconnect = async () => {
      try {
        await this.connect(false);
      } catch (error) {
        this.reconnectRetriesCount += 1;
        if (this.reconnectRetriesCount >= this.maxReconnectRetries) {
          console.warn('Max reconnect retries exceeded.');
          return;
        }
        console.warn('Reconnection failed, retrying...');
        this.reconnectTimeoutId = setTimeout(
          attemptReconnect,
          this.reconnectInterval
        );
      }
    };

    attemptReconnect();
  }

  private startPing() {
    if (this.pingTimeoutId) return;

    const sendPing = () => {
      if (this.ws?.readyState === WebSocket.OPEN) {
        this.ws.send(JSON.stringify({ type: 'ping' }));
        this.pongMissCount++;

        if (this.pongMissCount > this.maxPongMisses) {
          console.warn('Pong messages not received, reconnecting...');
          this.stopPing();
          this.ws?.close();
          this.scheduleReconnect();
        } else {
          this.pingTimeoutId = setTimeout(sendPing, this.pingInterval);
        }
      }
    };

    sendPing();
  }

  private stopPing() {
    if (this.pingTimeoutId) {
      clearTimeout(this.pingTimeoutId);
      this.pingTimeoutId = null;
    }
  }

  public send(data: string | ArrayBufferLike | Blob | ArrayBufferView) {
    if (this.ws?.readyState === WebSocket.OPEN) {
      this.ws.send(data);
    } else {
      console.warn('WebSocket is not open. Cannot send data.');
    }
  }

  public close() {
    this.stopPing();
    if (this.reconnectTimeoutId) {
      clearTimeout(this.reconnectTimeoutId);
      this.reconnectTimeoutId = null;
    }
    this.ws?.close();
  }
}
