import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from '../../hooks/useNavigate';
import { MButton } from '../../horizon-components/MButton/ui/MButton';
import { AuthRoutes, MainRoutes } from './Routes/types/routes';
import { ChangeLanguage } from '../../features/change-language';

interface NavbarMenuAnonymousProps {
  isMapPage: boolean;
}
const NavbarMenuAnonymous: React.FC<NavbarMenuAnonymousProps> = ({ isMapPage }) => {
  const navigate = useNavigate();
  const { t } = useTranslation(['translation']);

  const goToSignIn = () => navigate(MainRoutes.auth + AuthRoutes.signin);
  const goToSignUp = () => navigate(MainRoutes.auth + AuthRoutes.signup);

  return (
    <div
      className={`relative flex w-fit items-center gap-1 rounded-full bg-white
        ${isMapPage ? 'p-0 lg:p-1' : 'p-1'} shadow-xl shadow-shadow-500
      lg:flex-grow-0 lg:gap-[16px] lg:px-4 lg:py-3`}
    >
      <ChangeLanguage />
      <div className="flex gap-1 lg:gap-2">
        <MButton
          onClick={goToSignIn}
          color={'primary'}
          variant={'outline'}
          className="!hidden lg:!flex"
        >
          {t('profile.logIn')}
        </MButton>
        <MButton
          onClick={goToSignUp}
          color={'primary'}
          variant={'highlighted'}
          className="!hidden lg:!flex"
        >
          {t('profile.signUp')}
        </MButton>
        <MButton
          onClick={goToSignIn}
          color={'primary'}
          variant={'highlighted'}
          className="!block lg:!hidden"
        >
          {t('profile.logIn')}
        </MButton>
      </div>
    </div>
  );
};

export default NavbarMenuAnonymous;
