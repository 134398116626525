import { useEffect } from 'react';
import { useLanguage, useUserContext } from '../../providers';
import { updateServerUserLanguage } from '../../api/auth';

export const SaveUserLanguageOnServer = () => {
  const { language } = useLanguage();
  const { user } = useUserContext();
  useEffect(() => {
    if (!user?.guid) {
      return;
    }

    updateServerUserLanguage(language);
  }, [language, user?.guid]);

  return null;
};
