import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ChevronUp } from '../../../../assets/icons/chevron-up.svg';
import { ReactComponent as Collapse } from '../../../../assets/icons/collapse-icon.svg';
import { ReactComponent as DocIcon } from '../../../../assets/icons/doc.svg';
import { ReactComponent as CheckIcon } from '../../../../assets/icons/check.svg';
import { ReactComponent as FlagIcon } from '../../../../assets/icons/flag.svg';
import CircularProgressMini from '../../../../horizon-components/CircularProgressMini/CircularProgressMini';
import { Course, Unit } from '../../../../models/Course';
import { CourseAsideCard } from '../../../../widgets/CourseAsideCard';

type Props = {
  course: Course;
  currentUnit: Unit | null;
  currentUnitExam: number | null;
  setCurrentUnit: (unit: Unit) => void;
  startExam: (unitId: number) => void;
  leaveExam: () => void;
  status?: string;
};

export const CourseNavigation: React.FC<Props> = ({
  course,
  setCurrentUnit,
  currentUnit,
  startExam,
  currentUnitExam,
  leaveExam,
  status,
}) => {
  const { t } = useTranslation(['translation']);
  const [unitsIsVisible, setUnitsIsVisible] = useState(window.innerWidth > 992);
  const completedCount = course.units?.filter(
    (unit) => unit.completed?.[unit.completed?.length - 1]
  ).length;
  const totalUnits = course.units?.length;

  const progressColsNumber = course.units.length;
  const greenColsCount = Math.floor(
    (completedCount / totalUnits) * progressColsNumber
  );

  const onUnitClick = (unit: Unit) => {
    setCurrentUnit(unit);
    leaveExam();
  };

  const onExamClick = (unit: Unit) => {
    setCurrentUnit(unit);
    startExam(unit.id);
  };

  const toggleUnit = () => setUnitsIsVisible(!unitsIsVisible);

  return (
    <CourseAsideCard
      title={t('course.navigation')}
      data={
        !status && (
          <p className="text-xs font-semibold text-secondary-dark-grey-500 md:text-sm">
            {totalUnits} {t('course.units')}
          </p>
        )
      }
    >
      {!!status && (
        <>
          {course.status === 'ENROLLED' ? (
            <div className="flex items-center justify-between">
              <p className="text-xs font-semibold text-secondary-dark-grey-500 md:text-sm">
                {totalUnits} {t('course.units')}
              </p>
              {(status === 'started' || status === 'completed') && <FlagIcon />}
            </div>
          ) : (
            <>
              <div className="flex items-center justify-between">
                <p className="text-xs font-semibold text-secondary-dark-grey-500 md:text-sm">
                  {completedCount}/{totalUnits} {t('course.completed')}
                </p>
                {(status === 'started' || status === 'completed') && <FlagIcon />}
              </div>

              <div className="flex w-full gap-[5px]">
                {/* progress bar */}
                {[...Array(course.units.length)]?.map((_, index) => (
                  <div
                    key={index}
                    className={`h-2 w-full rounded-[36px] ${
                      course.status !== 'ENROLLED' && index < greenColsCount
                        ? 'bg-primary-green-500'
                        : 'bg-secondary-grey-400'
                    }`}
                  />
                ))}
              </div>
            </>
          )}
        </>
      )}
      {!unitsIsVisible && (
        <div className={'flex items-center justify-center pt-[10px] lg:hidden'}>
          <div className={'cursor-pointer'} onClick={toggleUnit}>
            <Collapse />
          </div>
        </div>
      )}
      {/* circular and texts */}
      {unitsIsVisible && (
        <div
          className={'mt-[8px] flex flex-col gap-[8px] md:mt-[16px] md:gap-[16px]'}
        >
          {course.units?.map((unit, index) => (
            <React.Fragment key={index}>
              <div className="group flex items-center justify-between gap-1 hover:cursor-pointer sm:gap-2">
                <div className="flex w-11/12 items-center gap-[8px] md:gap-[16px]">
                  <div className="w-[30px] min-w-[30px]">
                    <CircularProgressMini
                      step={(index + 1).toString()}
                      percentage={
                        course.status !== 'ENROLLED' &&
                        unit.completed?.[unit.completed?.length - 1]
                          ? 100
                          : 0
                      }
                    />
                  </div>
                  <div
                    className={`flex items-center gap-1 overflow-hidden overflow-ellipsis text-nowrap text-sm font-semibold md:text-[16px] ${
                      unit.id === currentUnit?.id && !currentUnitExam
                        ? 'text-primary-green-500'
                        : unit.completed?.[unit.completed?.length - 1]
                          ? 'text-secondary-dark-grey-900'
                          : 'text-secondary-dark-grey-400'
                    }`}
                    onClick={() => onUnitClick(unit)}
                  >
                    <p className="overflow-hidden overflow-ellipsis text-nowrap">
                      {unit.unit.title}
                    </p>
                  </div>
                </div>
                {course.status !== 'ENROLLED' &&
                  !!unit.completed?.[unit.completed?.length - 1] && (
                    <CheckIcon className="h-[18px] w-[18px] flex-none" />
                  )}
              </div>
              <div className="group flex items-center justify-between gap-1 hover:cursor-pointer sm:gap-2">
                <div className="flex w-11/12 items-center gap-[8px] md:gap-[16px]">
                  <div className="w-[30px] min-w-[30px]">
                    <CircularProgressMini
                      percentage={
                        course.status !== 'ENROLLED' && unit?.completed?.length
                          ? Number(
                              unit?.completed?.[unit?.completed?.length - 1]
                                ?.percentage_correct
                            )
                          : 0
                      }
                    >
                      <DocIcon
                        className={`h-[20px] w-[20px] ${
                          course.status !== 'ENROLLED' &&
                          unit.completed?.[unit.completed?.length - 1]
                            ? 'fill-primary-green-500'
                            : 'fill-secondary-dark-grey-500'
                        }`}
                      />
                    </CircularProgressMini>
                  </div>
                  <div
                    onClick={() => onExamClick(unit)}
                    className={`flex items-center gap-1 overflow-hidden overflow-ellipsis text-nowrap text-sm font-semibold md:text-[16px] ${
                      unit.id === currentUnit?.id && currentUnitExam
                        ? 'text-primary-green-500'
                        : unit.completed?.[unit.completed?.length - 1]
                          ? 'text-secondary-dark-grey-900'
                          : 'text-secondary-dark-grey-400'
                    }`}
                  >
                    <p className="overflow-hidden overflow-ellipsis text-nowrap">
                      {t('course.unitExam')}
                    </p>
                  </div>
                </div>
                {course.status !== 'ENROLLED' &&
                  !!unit.completed?.[unit.completed?.length - 1] && (
                    <CheckIcon className="h-[18px] w-[18px] flex-none" />
                  )}
              </div>
            </React.Fragment>
          ))}
        </div>
      )}
      {unitsIsVisible && (
        <div className={'flex items-center justify-center pt-[10px] md:hidden'}>
          <div className={'cursor-pointer'} onClick={toggleUnit}>
            <ChevronUp />
          </div>
        </div>
      )}
    </CourseAsideCard>
  );
};
