import React, { useCallback, useEffect, useRef, useState } from 'react';
import { BidActual } from './bid-actual';
import { BidPast } from './bid-past';
import { BidTabs } from '../../../../widgets/BidTabs';
import { getAuctionsV3 } from '../../../../api/bid';
import { Loader } from '../../../../components/Loader';
import { useTranslation } from 'react-i18next';
import { Auction, BidStatusEnum } from '../../../../models/Auction';
import { groupAuctionsByStatus } from '../../helpers';
import useInfiniteScroll from '../../../../hooks/useInfiniteScroll';
import { DEFAULT_LIMIT } from '../../../../shared/lib/const/limit';

const generateDefaultAuctionsByStatus = () => ({
  [BidStatusEnum.COMPLETED]: [],
  [BidStatusEnum.ACTIVE]: [],
  [BidStatusEnum.PLANNED]: [],
});

export const BidAll: React.FC = () => {
  const { t } = useTranslation(['translation']);
  const [page, setPage] = useState(1);
  const [pagesCount, setPagesCount] = useState(1);
  const [auctions, setAuctions] = useState<Auction[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [auctionsByStatus, setAuctionsByStatus] = useState<
    Record<BidStatusEnum, Auction[]>
  >(generateDefaultAuctionsByStatus());
  const targetRef = useRef<HTMLDivElement>(null);

  const fetchAuctionsPaginated = async (page: number) => {
    setIsLoading(true);
    try {
      const response = await getAuctionsV3(page);
      setPagesCount(Math.ceil(response.count / DEFAULT_LIMIT));
      setPage(page + 1);
      return response;
    } finally {
      setIsLoading(false);
    }
  };

  const fetchMoreAuctions = useCallback(() => {
    if (page > pagesCount || isLoading) {
      return;
    }
    fetchAuctionsPaginated(page).then((response) => {
      setAuctions([...auctions, ...response.results]);
    });
  }, [page, pagesCount, isLoading, auctions]);

  useEffect(() => {
    setPage(1);
    setAuctions([]);
    setAuctionsByStatus(generateDefaultAuctionsByStatus());
    fetchAuctionsPaginated(1).then((response) => {
      setAuctions(response.results);
    });
  }, [t]);

  useEffect(() => {
    setAuctionsByStatus(groupAuctionsByStatus(auctions));
  }, [auctions]);

  const onZeroTimer = useCallback(() => {
    if (auctions) {
      setAuctionsByStatus(groupAuctionsByStatus(auctions));
    }
  }, [auctions]);

  useInfiniteScroll(targetRef, fetchMoreAuctions);

  const showInfiniteScroll = page <= pagesCount || isLoading;

  return (
    <div className={'px-[8px] lg:px-0'}>
      <BidTabs />
      <BidActual
        activeAuctions={auctionsByStatus[BidStatusEnum.ACTIVE]}
        plannedAuctions={auctionsByStatus[BidStatusEnum.PLANNED]}
        onZeroTimer={onZeroTimer}
      />
      <BidPast
        completedAuctions={auctionsByStatus[BidStatusEnum.COMPLETED]}
        onZeroTimer={onZeroTimer}
      />
      {showInfiniteScroll && (
        <>
          <div ref={targetRef} className="mt-3" />
          <Loader />
        </>
      )}
    </div>
  );
};
