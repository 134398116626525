import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import * as WinterItems from '../../modules/Start/LevelMap/items/winterItems';

type ImagesProviderType = {
  isLoaded: boolean;
  getImage: (imageKey: string) => HTMLImageElement | undefined;
  images: Record<string, HTMLImageElement>;
};

const ImagesContext = createContext<ImagesProviderType>({} as ImagesProviderType);

export const ImagesEnum = {
  BlockLRBroken: 'winter_block_l_r_broken',
  BlockMRBlue: 'winter_block_m_r_blue',
  BlockMRBroken: 'winter_block_m_r_broken',
  BlockMRDouble: 'winter_block_m_r_double',
  BlockMRStones: 'winter_block_m_r_stones',
  BlockMRWhite: 'winter_block_m_r_white',
  BlockSRWhite: 'winter_block_s_r_white',
  BlockSRBlue: 'winter_block_s_r_blue',
  BridgeMRStone: 'winter_bridge_m_r_stone',
  BridgeMRWood: 'winter_bridge_m_r_wood',
  CoinsMRBag: 'winter_coins_m_r_bag',
  CoinsMRBox: 'winter_coins_m_r_box',
  FenceMRCone: 'winter_fence_m_r_cone',
  FenceMRDefault: 'winter_fence_m_r_default',
  FenceSRDefault: 'winter_fence_s_r_default',
  FenceSRCone: 'winter_fence_s_r_cone',
  LadderMRDefault: 'winter_ladder_m_r_default',
  Level1MRDefault: 'winter_level_1_m_r_default',
  Level2MRDefault: 'winter_level_2_m_r_default',
  Level3MRDefault: 'winter_level_3_m_r_default',
  Level4MRDefault: 'winter_level_4_m_r_default',
  Level5MRDefault: 'winter_level_5_m_r_default',
  Level6MRDefault: 'winter_level_6_m_r_default',
  Level7MRDefault: 'winter_level_7_m_r_default',
  Level8MRDefault: 'winter_level_8_m_r_default',
  Level9MRDefault: 'winter_level_9_m_r_default',
  Level10MRDefault: 'winter_level_10_m_r_default',
  Level11MRDefault: 'winter_level_11_m_r_default',
  Level12MRDefault: 'winter_level_12_m_r_default',
  SignMRArrow: 'winter_sign_m_r_arrow',
  SignMRArrowUp: 'winter_sign_m_r_arrow_up',
  SignMRSkull: 'winter_sign_m_r_skull',
  SkullMRDefault: 'winter_skull_m_r_default',
  StickMRDefault: 'winter_stick_m_r_default',
  StickMRSkull: 'winter_stick_m_r_skull',
  StonesMRDefault: 'winter_stones_m_r_default',
  TreeMRDefault: 'winter_tree_m_r_default',
  Cloud1MRDefault: 'winter_cloud_1_m_r_default',
  Cloud2MRDefault: 'winter_cloud_2_m_r_default',
  Cloud3MRDefault: 'winter_cloud_3_m_r_default',
  Cloud4MRDefault: 'winter_cloud_4_m_r_default',
  Character2: 'character_2',
  Character2Jump: 'character_2_jump',
  Button1: 'button_1',
  LockClosed: 'lock_closed',
  EmojiEvents: 'emoji_events',
  MiddleButton: 'en-middle',
  HighButton: 'en-high',
  Clouds: 'clouds',
  LevelButton: 'play',
};

const ImagesProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [images, setImages] = useState<Record<string, HTMLImageElement>>({});

  useEffect(() => {
    const loadImages = async () => {
      const response = await fetch(WinterItems.MapBundle);
      const svgText = await response.text();
      const parser = new DOMParser();
      const svgDoc = parser.parseFromString(svgText, 'image/svg+xml');

      const newImages: Record<string, HTMLImageElement> = {};

      for (const id of Object.values(ImagesEnum)) {
        const symbol = svgDoc.querySelector(`#${id}`);
        if (symbol) {
          const svgString = new XMLSerializer().serializeToString(symbol);
          const svgWithoutSymbol = svgString.replaceAll('symbol', 'svg');
          const svgBlob = new Blob([svgWithoutSymbol], { type: 'image/svg+xml' });
          let image = document.createElement('img');
          let url = URL.createObjectURL(svgBlob);
          image.src = url;
          await new Promise<void>((resolve, reject) => {
            image.addEventListener(
              'load',
              () => {
                URL.revokeObjectURL(url);
                resolve();
              },
              {
                once: true,
              }
            );
          });

          newImages[id] = image;
        }
      }

      setImages(newImages);
      setIsLoaded(true);
    };

    loadImages();
  }, []);

  const getImage = (imageKey: string): HTMLImageElement | undefined => {
    return images[imageKey];
  };

  return (
    <ImagesContext.Provider value={{ images, isLoaded, getImage }}>
      {children}
    </ImagesContext.Provider>
  );
};

const useImagesContext = () => useContext(ImagesContext);

export { useImagesContext, ImagesProvider };
