import React from 'react';

export function TextArea(props: {
  id: string;
  label?: string;
  extra?: string;
  placeholder: string;
  state?: string;
  disabled?: boolean;
  cols: number;
  rows: number;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  value: string;
  resize?: boolean;
  errorText?: string;
}) {
  const {
    label,
    id,
    extra,
    placeholder,
    cols,
    rows,
    state,
    disabled,
    onChange,
    value,
    resize = false,
    errorText,
  } = props;

  const autoResize = (event: React.FormEvent<HTMLTextAreaElement>) => {
    const target = event.target as HTMLTextAreaElement;
    target.style.height = 'auto'; // Сбрасываем высоту textarea
    target.style.height = `${target.scrollHeight}px`; // Устанавливаем высоту textarea в зависимости от его содержимого
  };

  return (
    <div className={`${extra}`}>
      {label ? (
        <p
          className={`mb-1 ml-4 text-sm font-medium text-gray-900 ${
            !!errorText ? 'text-red-700' : ''
          }`}
        >
          {label}&nbsp;
        </p>
      ) : null}
      <div>
        <textarea
          onChange={onChange}
          onInput={(event) => resize && autoResize(event)}
          cols={cols}
          rows={rows}
          placeholder={placeholder}
          className={`flex min-h-[46px] w-full items-center justify-center rounded-xl border-2 bg-white/0 p-[12px] text-xs font-medium text-secondary-dark-grey-900 outline-none md:px-[16px] md:text-sm ${
            disabled === true
              ? '!border-none !bg-gray-100 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)]'
              : state === 'error'
                ? '!border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400'
                : state === 'success'
                  ? '!border-green-500 text-green-500 placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400'
                  : disabled
                    ? '!border-none !bg-gray-100 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)]'
                    : 'border-secondary-grey-500 dark:!border-white/10 dark:text-white'
          }`}
          style={
            resize
              ? {
                  resize: 'none',
                  overflow: 'hidden',
                  width: '100%',
                }
              : {}
          }
          name={id}
          id={id}
          value={value}
        />
        {errorText ? <p className={'g-error mt-1 pl-4'}>{errorText}</p> : null}
      </div>
    </div>
  );
}
