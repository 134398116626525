import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useErWallet,
  useErWalletTransaction,
  useOwlWallet,
  useOwlWalletTransaction,
} from '../../../../entities/Wallet';
import { Card } from '../../../../horizon-components/Card/Card';
import { WalletCard } from '../../../../widgets/WalletCard';
import { WalletTable } from '../../../../widgets/WalletTable';
import { MyWalletMock } from './MyWalletMock.const';
import { DatePicker } from '../../../../components/DatePicker';
import { Loader } from '../../../../components/Loader';
import './styles.scss';
import dayjs from 'dayjs';


export const MyWallet: React.FC = () => {
  const [selectedDates, setSelectedDates] = useState<Date[]>([]);
  const [active, setActive] = useState(MyWalletMock[0].title);
  const { t } = useTranslation(['translation']);

  const { data: erAmount } = useErWallet();
  const { data: owlAmount } = useOwlWallet();
  const {
    data: ers,
    isLoading: ersIsLoading,
    fetchData: ersFetchData,
  } = useErWalletTransaction();
  const {
    data: owls,
    isLoading: owlsIsLoading,
    fetchData: owlsFetchData,
  } = useOwlWalletTransaction();

  const lastWeek = () => {
    // Получить текущую дату
    const currentDate = new Date();

    // Установить время текущей даты на начало дня (00:00:00)
    currentDate.setHours(0, 0, 0, 0);

    // Получить последний день (сегодня)
    const lastDay = new Date(currentDate);

    // Получить первый день (прошлая неделя)
    const firstDay = new Date(currentDate);
    firstDay.setDate(currentDate.getDate() - 6);

    // Создать массив из первого и последнего дня
    return [firstDay, lastDay];
  };

  useEffect(() => {
    setSelectedDates(lastWeek());
  }, []);

  useEffect(() => {
    if (selectedDates.length === 2) {
      const arr = JSON.parse(JSON.stringify(selectedDates));
      const dates = {
        min: dayjs(new Date(arr[0])).format('YYYY-MM-DD'),
        max: dayjs(new Date(arr[1]).setDate(new Date(arr[1]).getDate() + 1)).format(
          'YYYY-MM-DD'
        ),
      };
      ersFetchData(dates.min, dates.max);
      owlsFetchData(dates.min, dates.max);
    }
  }, [selectedDates]);

  return (
    <>
      <h1 className="g-main-title mb-4">{t('wallet.title')}</h1>
      {
        <div className="MyWallet container mx-auto">
          <Card className="MyWallet__content">
            <div className="MyWallet__wallet">
              <div className="MyWallet__wallet-cards">
                {MyWalletMock?.map((wallet) => (
                  <div key={wallet.title}>
                    <div
                      className={classNames('MyWallet__label', {
                        active: active !== wallet.title,
                      })}
                      onClick={() => setActive(wallet.title)}
                    >
                      {wallet.title}{' '}
                      <img
                        src={wallet.logoActive}
                        alt={wallet.title}
                        className={classNames('MyWallet__img', 'md:block', {
                          block: active === wallet.title,
                          hidden: active !== wallet.title,
                        })}
                      />
                      <img
                        src={wallet.logoDefault}
                        alt={wallet.title}
                        className={classNames('MyWallet__img', 'md:hidden', {
                          hidden: active === wallet.title,
                          block: active !== wallet.title,
                        })}
                      />
                    </div>
                  </div>
                ))}
              </div>
              <div className="MyWallet__wallet-cards">
                {MyWalletMock?.map((wallet) => (
                  <div
                    className={classNames({
                      'md-max:hidden': active !== wallet.title,
                      'md-max:block': active === wallet.title,
                    })}
                    key={wallet.title}
                  >
                    <WalletCard
                      text={t(wallet.text)}
                      amount={
                        wallet.title === 'OWL'
                          ? owlAmount?.[0]?.value || 0
                          : erAmount?.[0]?.value || 0
                      }
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="MyWallet__wrapper">
              <div className="mb-3 flex items-center justify-between px-[16px] md:px-6">
                <h5>{t('edu.Transactions')}</h5>
                <DatePicker
                  selectedDates={selectedDates}
                  onDateChange={setSelectedDates}
                  configs={{ dateFormat: 'dd.MM' }}
                />
              </div>
              <div className="MyWallet__table">
                {MyWalletMock?.map((wallet) => (
                  <div
                    key={wallet.title}
                    className={classNames({
                      'md-max:hidden': active !== wallet.title,
                      'md-max:block': active === wallet.title,
                    })}
                  >
                    {owlsIsLoading || ersIsLoading ? (
                      <Loader />
                    ) : (
                      <WalletTable data={wallet.title === 'OWL' ? owls : ers} />
                    )}
                  </div>
                ))}
              </div>
            </div>
          </Card>
        </div>
      }
    </>
  );
};
