import { Helmet } from 'react-helmet';
import { helmetJsonLdProp } from 'react-schemaorg';
import { Organization } from 'schema-dts';

const EMAIL = 'support@edres.com';
const TELEPHONE = '+9-999-999-9999'; // TODO

function OrganizationLdJson() {
  return (
    <Helmet
      script={[
        helmetJsonLdProp<Organization>({
          '@context': 'https://schema.org',
          '@type': 'Organization',
          name: 'EdRes',
          legalName: 'KNOWLEDGER LAB TOO',
          // description: '', // TODO
          email: EMAIL,
          // telephone: TELEPHONE, // TODO
          url: 'https://edres.com',
          logo: 'https://edres.com/icons/android-chrome-512x512.png',
          // foundingDate: '', // TODO: YYYY-MM
          contactPoint: {
            '@type': 'ContactPoint',
            // telephone: TELEPHONE, // TODO
            email: EMAIL,
          },
          address: {
            '@type': 'PostalAddress',
            addressCountry: 'KZ',
            addressLocality: 'Almaty',
            addressRegion: 'Almaty Oblast',
            postalCode: '050020',
            streetAddress: '23 Kyz Zhibek St.',
          },
        }),
      ]}
    />
  );
}

export default OrganizationLdJson;
