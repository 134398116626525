/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { saveAssesTokenStorage, saveRefreshTokenStorage } from '../../api';
import {
  authMe,
  authorizeViaTelegramApp,
  setPrivateUseReferralCode,
} from '../../api/auth';
import { sendLogin, sendSignUp, setUserID } from '../../hooks/useAnalytics';
import { useNavigate } from '../../hooks/useNavigate';
import { MAIN_PAGE_ROUTE } from '../../horizon-layout/MainLayout/Routes/types/routes';
import { AuthorizationData } from '../../models';
import { useUserContext } from '../../providers';
import getErrorMessages from '../../utils/getErrorMessages';
import { Loader } from '../Loader';
import { useTranslation } from 'react-i18next';

export type TelegramStoryWidgetLink = {
  url: string;
  name: string;
};

export type Telegram = {
  WebApp: {
    initData: string;
    initDataUnsafe: {
      start_param?: string;
      user?: {
        is_premium?: boolean;
      };
    };
    shareToStory: (
      media_url: string,
      params?: { text?: string; widget_link?: TelegramStoryWidgetLink }
    ) => void;
    setBottomBarColor: (color: string) => void;
    requestFullscreen: () => void;
  };
};

const MAX_RETRIES_COUNT = 10;

export const TelegramApp = () => {
  const telegramObject = window.Telegram?.WebApp;

  if (!telegramObject || !telegramObject.initData) return null;

  const initData = telegramObject.initData;
  const refCodeTelegram = telegramObject.initDataUnsafe.start_param;
  const navigate = useNavigate();
  const { t } = useTranslation(['translation']);
  const { saveUser, setFirstLogin } = useUserContext();
  const [isTelegramAppInitializing, setIsTelegramAppInitializing] = useState(true);
  const refCode = sessionStorage.getItem('refCode');
  const [authorizationFailed, setAuthorizationFailed] = useState(false);

  const authorize = async (initData_: string | null) => {
    if (!initData_) {
      return;
    }

    const authorizationData = await authorizeViaTelegramApp(initData_);

    if (!authorizationData) {
      setAuthorizationFailed(true);
      return;
    }

    const {
      access,
      refresh,
      is_signin: isSignin,
    } = authorizationData as AuthorizationData;

    saveAssesTokenStorage(access);
    saveRefreshTokenStorage(refresh);
    const userData = await authMe();
    saveUser(userData);
    setUserID(userData.guid);
    // if (refCodeTelegram && !isSignin) {
    //   const cleanedRefCodeTelegram = refCodeTelegram.replace('refid', '');
    //   await setPrivateUseReferralCode(cleanedRefCodeTelegram);
    // }
    if (refCodeTelegram) {
      try {
        const cleanedRefCodeTelegram = refCodeTelegram.replace('refid', '');
        await setPrivateUseReferralCode(cleanedRefCodeTelegram);
      } catch (e) {
        console.log(e);
      }
    }

    if (isSignin) {
      sendLogin('TelegramWebApp');
    } else {
      !refCode && setFirstLogin(true);
      sendSignUp('TelegramWebApp');
    }

    navigate(MAIN_PAGE_ROUTE);
  };

  const authorizeWithRetries = async (initData_: string) => {
    let retriesCount = 0;
    while (retriesCount < MAX_RETRIES_COUNT) {
      try {
        await authorize(initData_);
        break;
      } catch (e) {
        retriesCount += 1;
      }
    }
    if (retriesCount >= MAX_RETRIES_COUNT) {
      setAuthorizationFailed(true);
    } else {
      setIsTelegramAppInitializing(false);
    }
  };

  useEffect(() => {
    //
    telegramObject.disableVerticalSwipes();
    telegramObject.enableClosingConfirmation();
    try {
      telegramObject.requestFullscreen();
      telegramObject.lockOrientation();
      // const onSafeChange = (event: any) => {
      //   console.log('safe_area_changed-onevent ', event)
      //   // setup top safe area
      //   const elSafeTop = document.getElementById('top-safe-area') as HTMLElement;
      //   console.log('top-safe-area-elem', elSafeTop)
      //   elSafeTop.style.height=`${telegramObject.safeAreaInset.top + 35}px`;
      //   console.log(telegramObject.safeAreaInset.top)
      // setup bottom safe area
      // const elSafeBottom = document.getElementById('bottom-safe-area') as HTMLElement;
      // console.log('bottom-safe-area-elem', elSafeBottom)
      // elSafeBottom.style.height=`${telegramObject.safeAreaInset.bottom}px`;
      // elSafeBottom.style.backgroundColor = telegramObject.themeParams.bottom_bar_bg_color;
      // telegramObject.offEvent('safe_area_changed', (event)=>{
      //   console.log('safe_area_changed-offevent ', event);
      // })
      // }
      // setTimeout(() => onSafeChange({when: 'on start'}), 1000 );

      // telegramObject.onEvent('safe_area_changed', onSafeChange)
    } catch (e) {
      telegramObject.expand();
    }
    // workaround for correct set background color
    // telegramObject.setBottomBarColor('#ffffff');
    // telegramObject.setBottomBarColor('#000000');
    authorizeWithRetries(initData);
  }, [initData]);

  if (authorizationFailed) {
    return (
      <div
        className={
          'fixed left-0 top-0 z-[1000] flex h-full w-full items-center justify-center bg-white p-5 text-center'
        }
      >
        {t('telegram.authorization_failed')}
      </div>
    );
  }

  if (!isTelegramAppInitializing) return null;

  return (
    <div
      className={
        'fixed left-0 top-0 z-[1000] flex h-full w-full items-center justify-center bg-white'
      }
    >
      <Loader />
    </div>
  );
};
