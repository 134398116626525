import {
  Course,
  CourseCardItem,
  CourseTopicQuestion,
  CourseUnitQuestion,
  ImageItem,
  TAttachFile,
  TLicense,
  Topic,
  TopicContent,
  TQuestionItem,
  UnitItem,
} from '../models/Course';
import { ResponsePagination } from '../models/Response';
import { ApplyStep1Body, CourseRequest } from './course';
import { Languages, Subjects } from './dictionaries';
import instance from './instance';

export function getPublicCoursesV3(
  params: CourseRequest
): Promise<ResponsePagination<CourseCardItem>> {
  return instance()
    .get(`/v3/public/course/course/`, { params: { ...params } })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function createPrivateCourseV3(body: ApplyStep1Body): Promise<Course> {
  return instance()
    .post(`/v3/private/course/create/`, body)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function updatePrivateCourseV3(
  courseId: string,
  body: ApplyStep1Body
): Promise<Course> {
  if (body.cover_image instanceof File) {
    const formData = new FormData();
    formData.append('cover_image', body.cover_image);

    return instance()
      .put(`/v3/private/course/create/${courseId}/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  } else {
    return instance()
      .put(`/v3/private/course/create/${courseId}/`, body)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }
}

export function getPrivateCourseV3(courseId: string): Promise<Course> {
  return instance(true, false)
    .get(`/v3/private/course/create/${courseId}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getPublicCourseIdV3(id: number): Promise<Course> {
  return instance()
    .get(`/v3/public/course/course/${id}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getPrivateEnrolledCoursesV3(): Promise<number[]> {
  return instance()
    .get(`/v3/private/course/enrolled-courses/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getPrivateFavouriteCoursesV3(): Promise<number[]> {
  return instance()
    .get(`/v3/private/course/favourite-courses/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getPrivateCoursesV3(
  params: CourseRequest
): Promise<ResponsePagination<CourseCardItem>> {
  return instance()
    .get(`/v3/private/course/course/`, { params: { ...params } })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getPrivateStartedCoursesV3(): Promise<CourseCardItem[]> {
  return instance()
    .get(`/v3/private/course/started-courses/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getPublicTagsAutocompleteV3(page?: number): Promise<
  ResponsePagination<{
    name: string;
  }>
> {
  return instance()
    .get(`/v3/public/course-creation/tags-autocomplete/`, { params: { page } })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getPublicСourseOutcomeV3(page?: number): Promise<
  ResponsePagination<{
    id: number;
    title: string;
  }>
> {
  return instance()
    .get(`/v3/public/course/course-outcome/`, { params: { page } })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function setPublicСourseOutcomeV3(title: string): Promise<{
  id: number;
  title: string;
}> {
  return instance()
    .post(`/v3/public/course/course-outcome/`, { title })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getPublicСourseLicenseV3(): Promise<ResponsePagination<TLicense>> {
  return instance()
    .get(`/v3/public/course/course-license/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getPrivateGetCoursebyAccordingProgramV3(
  subject_id: number
): Promise<Course> {
  return instance()
    .get(`/v3/private/course/get-course/by-according-program/${subject_id}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getMaxTokenAmountByCourseV3(
  course_id: number
): Promise<{ max_token_amount: number }> {
  return instance()
    .get(`/v3/public/course/get-max-token-amount-by-course/${course_id}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getSubjectsListV3(): Promise<Subjects[]> {
  return instance()
    .get(`/v3/public/get-subjects-list/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getLanguagesListV3(): Promise<ResponsePagination<Languages>> {
  return instance()
    .get(`/v3/public/languages/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getLevelsListV3(subject: string): Promise<Subjects[]> {
  return instance()
    .get(`/v3/public/get-levels-list/`, {
      params: { subject },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getUnitsListV3(subject_level: string): Promise<Subjects[]> {
  return instance()
    .get(`/v3/public/get-units-list/`, {
      params: { subject_level },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function setFileDirectUploadV3(
  courseId: number,
  fileName: string,
  fileSize: number
): Promise<{ upload_url: string; file_name: string; file_size: number }> {
  return instance()
    .post(
      `/v3/private/course/create/${courseId}/introduction_file_generate_direct_upload_url/`,
      {
        file_name: fileName,
        file_size: fileSize,
      }
    )
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function attachFileCfDirectV3(
  topicId: number,
  videoUid: string
): Promise<TAttachFile> {
  return instance()
    .post(`/v3/private/course-topic/${topicId}/attach_file_cf_submit/`, {
      uid: videoUid,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function createTopicV3(unit: number, title: string): Promise<Topic> {
  return instance()
    .post(`/v3/private/course-topic/`, {
      unit,
      title,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function deleteTopicV3(topicId: number): Promise<Topic> {
  return instance()
    .delete(`/v3/private/course-topic/${topicId}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function changeTopicV3(data: Topic): Promise<Topic> {
  return instance()
    .put(`/v3/private/course-topic/${data.id}/`, data)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function attachContentV3(
  topicId: number,
  resourceType: 'TextContent' | 'FileContent',
  file?: File,
  text?: string
): Promise<TopicContent> {
  const body = new FormData();
  if (file) {
    body.append('file', file);
  }
  if (text) {
    body.append('text', text);
  }
  body.append('resourcetype', resourceType);
  return instance()
    .post(`/v3/private/course-topic/${topicId}/attach_content/`, body)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function updateContentV3(
  contentId: number,
  text?: string
): Promise<TopicContent> {
  const body = new FormData();
  if (text) {
    body.append('text', text);
  }
  return instance()
    .put(`/v3/private/course-topic/update-content/${contentId}/`, body)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function detachContentV3(
  topicId: number,
  content_id: number
): Promise<TopicContent> {
  return instance()
    .post(`/v3/private/course-topic/${topicId}/detach_content/`, {
      content_id,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function detachIntroductionFileV3(
  courseId: number,
  file_id: number
): Promise<Course> {
  return instance()
    .post(`/v3/private/course/create/${courseId}/detach_introduction_file/`, {
      file_id,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function sendToVerificationV3(courseId: number): Promise<Topic> {
  return instance()
    .post(`/v3/private/course/create/${courseId}/send_to_verification/`, undefined)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function createQuestionsV3(
  body: CourseUnitQuestion
): Promise<CourseUnitQuestion> {
  return instance()
    .post(`/v3/private/questions/unit/questions-answers/`, body)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function deleteUnitQuestionV3(questionId: number): Promise<void> {
  return instance()
    .delete(`/v3/private/questions/unit/${questionId}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getQuestionsV3(course_unit: number): Promise<TQuestionItem[]> {
  return instance()
    .get(`/v3/private/questions/unit/${course_unit}/questions-answers/`)
    .then((response) => response.data.results)
    .catch((error) => {
      throw error;
    });
}

export function createQuestionsTopicV3(
  body: CourseTopicQuestion
): Promise<CourseTopicQuestion> {
  return instance()
    .post(`/v3/private/questions/topic/questions-answers/`, body)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function coveredImageListV3(): Promise<ImageItem[]> {
  return instance()
    .get(`/v3/public/predefined-cover-images-list/`, {
      params: { page: 1 },
    })
    .then((response) => response.data.results)
    .catch((error) => {
      throw error;
    });
}

export function getCourseUnitV3(course: number): Promise<UnitItem[]> {
  return instance()
    .get(`/v3/private/my-course-unit/`, {
      params: { course },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}
