import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './languages/en/translation.json';
import ru from './languages/ru/translation.json';
// import kz from './languages/kz/translation.json';

export enum Languages {
  EN = 'en',
  RU = 'ru',
  // KZ = 'kz',
}

export const DEFAULT_LANG = Languages.EN;
export const LANG_KEY = 'language';

export const LANGUAGES = {
  [Languages.EN]: 'EN',
  [Languages.RU]: 'RU',
  // [Languages.KZ]: 'KZ',
};

export const isSupportedLang = (language: string) => {
  language = language.toLowerCase();
  for (const originalLang of Object.values(Languages)) {
    if (language.toLowerCase().startsWith(originalLang.toLowerCase())) {
      return originalLang;
    }
  }
  return false;
};

export const getDefaultLanguageFromBrowser = () => {
  // Try to get language from navigator.languages
  if (Array.isArray(navigator.languages)) {
    for (const lang of navigator.languages) {
      const originalLang = isSupportedLang(lang);
      if (originalLang) {
        return originalLang;
      }
    }
  }

  // Try to get language from navigator[prop]
  const props = ['language', 'browserLanguage', 'systemLanguage', 'userLanguage'];
  for (const key of props) {
    const lang = (navigator as any)[key];
    if (lang && typeof lang === 'string') {
      const originalLang = isSupportedLang(lang);
      if (originalLang) {
        return originalLang;
      }
    }
  }

  return null;
};

export const getCurrentLang = () => {
  return (
    localStorage.getItem(LANG_KEY) || getDefaultLanguageFromBrowser() || DEFAULT_LANG
  );
};

i18n.use(initReactI18next).init({
  fallbackLng: DEFAULT_LANG,
  lng: getCurrentLang(),
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: {
      translation: en,
    },
    ru: {
      translation: ru,
    },
    // kz: {
    //   translation: kz,
    // },
  },
});

export default i18n;
