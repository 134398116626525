import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
} from 'react';
import useFetch from '../../hooks/useFetch';
import { Bid, BidCommonInfo, MyBidsResponse } from '../../models/Auction';
import { getBidsCommonInfo, getMyBids } from '../../api/bid';
import { useUserContext } from '../UserProvider';
import { BIDS_PAGE_SIZE } from '../../modules/Bid/constants';

type BidServiceProvider = {
  myBidsData: Record<number, Bid>;
  isMyBidsLoading: boolean;
  fetchMyBids: () => Promise<MyBidsResponse | undefined>;
  bidsCommonInfo: Record<string, BidCommonInfo> | null;
  isBidsCommonInfoLoading: boolean;
  fetchBidsCommonInfo: () => Promise<Record<string, BidCommonInfo> | undefined>;
  getBidsPagesCount: (auction_id: number) => number;
  getMyBidForAuction: (auction_id: number) => Bid | null;
};

const BidServiceContext = createContext<BidServiceProvider>(
  {} as BidServiceProvider
);

const BidServiceProvider: React.FC<PropsWithChildren> = (props) => {
  const { children } = props;
  const { user } = useUserContext();

  const {
    data: myBidsData,
    isLoading: isMyBidsLoading,
    fetchData: fetchMyBids,
  } = useFetch(getMyBids);

  const myBidsDataSipmplified = myBidsData?.my_bids || {};

  const {
    data: bidsCommonInfo,
    isLoading: isBidsCommonInfoLoading,
    fetchData: fetchBidsCommonInfo,
  } = useFetch(getBidsCommonInfo);

  useEffect(() => {
    fetchBidsCommonInfo();
  }, []);

  useEffect(() => {
    if (user?.guid) {
      fetchMyBids();
    }
  }, [user?.guid]);

  const getBidsPagesCount = useCallback(
    (auction_id: number) => {
      if (!bidsCommonInfo) {
        return 0;
      }
      return Math.ceil(
        (bidsCommonInfo[auction_id.toString()]?.bidders_count || 0) / BIDS_PAGE_SIZE
      );
    },
    [bidsCommonInfo]
  );

  const getMyBidForAuction = useCallback(
    (auction_id: number) => {
      return myBidsData?.my_bids[auction_id] || null;
    },
    [myBidsData]
  );

  return (
    <BidServiceContext.Provider
      value={{
        myBidsData: myBidsDataSipmplified,
        isMyBidsLoading,
        fetchMyBids,
        bidsCommonInfo,
        isBidsCommonInfoLoading,
        fetchBidsCommonInfo,
        getBidsPagesCount,
        getMyBidForAuction,
      }}
    >
      {children}
    </BidServiceContext.Provider>
  );
};

const useBidServiceContext = () => useContext(BidServiceContext);

export { useBidServiceContext, BidServiceProvider };
