import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { ReactComponent as InfoIcon } from '../../assets/icons/bid/info.svg';
import { useNavigate } from '../../hooks/useNavigate';
import { MButton } from '../../horizon-components/MButton';
import {
  AuctionRoutes,
  MainRoutes,
} from '../../horizon-layout/MainLayout/Routes/types/routes';
import { useUserContext } from '../../providers';

interface BidTabsProps {
  className?: string;
}

export const BidTabs = ({ className }: BidTabsProps) => {
  const { t } = useTranslation(['translation']);
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useUserContext();

  const goTo = (url: string) => navigate(url);

  return (
    <div
      className={classNames(
        'mb-2.5 flex items-center justify-between gap-2 side:hidden',
        className
      )}
    >
      <MButton
        variant={
          location.pathname.includes(MainRoutes.bid + AuctionRoutes.actual)
            ? 'highlighted'
            : 'transparent'
        }
        color={
          location.pathname.includes(MainRoutes.bid + AuctionRoutes.actual)
            ? 'tab'
            : 'white'
        }
        onClick={() => goTo(MainRoutes.bid + AuctionRoutes.actual)}
        className={classNames('h-10 w-full', {
          '!text-primary-primary': !location.pathname.includes(
            MainRoutes.bid + AuctionRoutes.actual
          ),
        })}
      >
        {t('menu.All')}
      </MButton>
      <MButton
        disabled={!user?.guid}
        variant={
          location.pathname.includes(MainRoutes.bid + AuctionRoutes.winnings)
            ? 'highlighted'
            : 'transparent'
        }
        color={
          location.pathname.includes(MainRoutes.bid + AuctionRoutes.winnings)
            ? 'tab'
            : 'white'
        }
        onClick={() => goTo(MainRoutes.bid + AuctionRoutes.winnings)}
        className={classNames('h-10 w-full', {
          '!text-primary-primary': !location.pathname.includes(
            MainRoutes.bid + AuctionRoutes.winnings
          ),
        })}
      >
        {t('menu.My')}
      </MButton>
      <MButton
        variant={
          location.pathname.includes(MainRoutes.bid + AuctionRoutes.about)
            ? 'highlighted'
            : 'transparent'
        }
        color={
          location.pathname.includes(MainRoutes.bid + AuctionRoutes.about)
            ? 'tab'
            : 'white'
        }
        onClick={() => goTo(MainRoutes.bid + AuctionRoutes.about)}
        className={classNames('h-10 w-[52px] !p-3', {
          '!text-primary-primary': !location.pathname.includes(
            MainRoutes.bid + AuctionRoutes.about
          ),
        })}
      >
        <InfoIcon
          className={classNames('!fill-primary-primary', {
            '!fill-primary-white': location.pathname.includes(
              MainRoutes.bid + AuctionRoutes.about
            ),
          })}
        />
      </MButton>
    </div>
  );
};
