import { FC } from 'react';
import BgSchoolMapLayer from '../BgSchoolMapLayer';
import BgMainObjElSchool from './layers/BgMainObjElSchool';
import MainObjElSchool from './layers/MainObjElSchool';
import BgObjSchoolMap from '../layers/BgObjSchoolMap';

type ElementarySchoolMapProps = {
  className?: string;
};

const ElementarySchoolMap: FC<ElementarySchoolMapProps> = ({ className }) => {
  return (
    <>
      <div className={className}>
        <div className={`map-container`}>
          <BgSchoolMapLayer className="absolute-custom" />
          <div className="opacity-60">
            <BgObjSchoolMap className="absolute-custom" />
          </div>
          <BgMainObjElSchool className="absolute-custom" />
          <MainObjElSchool className="absolute-custom" />
        </div>
      </div>
    </>
  );
};

export default ElementarySchoolMap;
