import classNames from 'classnames';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { saveCurrentUrl } from '../../api/sessions';
import { getInfoEarnedTokens, submitBulkAnswers, TCA } from '../../api/wiz/wiz';
import ResultHighGif from '../../assets/images/wiz/result_high.png';
import ResultHighestGif from '../../assets/images/wiz/result_highest.png';
import ResultLowGif from '../../assets/images/wiz/result_low.png';
import ResultMediumGif from '../../assets/images/wiz/result_medium.png';
import { Loader } from '../../components/Loader';
import { Share } from '../../components/Share/Share';
import { sendEarnEDRES } from '../../hooks/useAnalytics';
import useFetch from '../../hooks/useFetch';
import { useNavigate } from '../../hooks/useNavigate';
import { useShareReferal } from '../../hooks/useShareReferal';
import { MButton } from '../../horizon-components/MButton';
import {
  AuthRoutes,
  MainRoutes,
} from '../../horizon-layout/MainLayout/Routes/types/routes';
import { useUserContext } from '../../providers';
import { useWizContext } from '../../providers/WizProvider/WizProvider';
import './style.scss';

interface QuizEndModalProps {
  className?: string;
  onStart?: () => void;
  onClick?: () => void;
}

export const QuizEndModal = ({ className, onStart, onClick }: QuizEndModalProps) => {
  const { t } = useTranslation(['translation']);
  const {
    data,
    isLoading,
    fetchData: fetchTokens,
  } = useFetch((started_quiz_id: number, answers: TCA[]) => {
    if (user?.guid) {
      return submitBulkAnswers(answers).finally(updateWallet);
    }
    return getInfoEarnedTokens(started_quiz_id, answers);
  });
  const { url, activationRewardAmount } = useShareReferal();
  const { user, updateWallet } = useUserContext();
  const navigate = useNavigate();
  const { currentTest, answers } = useWizContext();

  const resultGif = useMemo(() => {
    if (!data) return;
    const percent = data.percentage_correct;
    if (percent < 26) {
      return ResultLowGif;
    } else if (percent < 51) {
      return ResultMediumGif;
    } else if (percent < 76) {
      return ResultHighGif;
    }
    return ResultHighestGif;
  }, [data]);

  const onUnauthorizedClaimReward = () => {
    saveCurrentUrl(window.location.pathname + `?claimReward=${currentTest?.id}`);
    navigate(MainRoutes.auth + AuthRoutes.signin);
  };

  useEffect(() => {
    currentTest?.id && answers && fetchTokens(currentTest?.id, answers);
  }, [currentTest?.id]);

  useEffect(() => {
    sendEarnEDRES(data?.earned_token);
  }, [data?.earned_token]);

  if (isLoading) return <Loader />;

  return (
    <div className={classNames('QuizEndModal', className)}>
      {resultGif && <img src={resultGif} alt="result animation" />}
      <div className="QuizEndModal__content">
        <div className="flex items-start justify-between self-stretch">
          <p>
            {data?.percentage_correct}% {t('wiz.correct_answers')}
          </p>
          <p>{data?.earned_token} EdRes</p>
        </div>
        <div className="flex items-start justify-between self-stretch">
          <p>{t('wiz.Reward_Multiplier')} </p>
          <p>X{data?.multiplier_token}</p>
        </div>
        <div className="flex items-start justify-between self-stretch">
          <h3>{t('wiz.Total')}</h3>
          <h3>{data?.total_token} EdRes</h3>
        </div>
      </div>
      {!!user && (
        <>
          <Share
            url={url}
            loading={!url}
            text={t('main.share.description', {
              activationRewardAmount,
            })}
          />
          <div className="flex w-full gap-2 md:gap-6">
            <MButton
              variant="secondary"
              color="primary"
              className="modal-h w-full md:!px-8 md:!py-6 md:!text-lg"
              onClick={onClick}
            >
              {t('buttons.Go_to_courses')}
            </MButton>
            <MButton
              variant="highlighted"
              color="primary"
              className="modal-h w-full md:!px-8 md:!py-6 md:!text-lg"
              onClick={onStart}
            >
              {t('buttons.Play_more')}
            </MButton>
          </div>
        </>
      )}
      {!user && (
        <MButton
          variant="highlighted"
          color="primary"
          className="modal-h w-full md:!px-8 md:!py-6 md:!text-lg"
          onClick={onUnauthorizedClaimReward}
        >
          {t('buttons.Collect_the_reward')}
        </MButton>
      )}
    </div>
  );
};
