import React, { useEffect, useRef } from 'react';
import { generateTelegramRedirectUri } from '../SignIn/utils';
import { useLanguage, useUserContext } from '../../../providers';
import { createTelegramAuthScript } from '../TelegramAuth/TelegramAuth';

const authBotUsername = process.env.REACT_APP_TG_BOT_NAME;

export const TelegramAuthMobile: React.FC = () => {
  const { language } = useLanguage();
  const { user, logout } = useUserContext();

  const wrapperRef = useRef<HTMLDivElement>(null);
  const scriptRef = useRef<HTMLScriptElement>();

  useEffect(() => {
    if (user) {
      logout(false);
    }
  }, [user]);

  useEffect(() => {
    if (!authBotUsername) return;
    scriptRef.current = createTelegramAuthScript({
      authCallbackUrl: generateTelegramRedirectUri(true),
      botUsername: authBotUsername,
      lang: language,
    });
    wrapperRef.current?.append(scriptRef.current);
    return () => {
      scriptRef.current?.remove();
    };
  }, []);

  if (!authBotUsername) {
    console.warn('Telegram auth bot user name not found');
    return null;
  }

  return (
    <div className={'flex h-full items-center justify-center'}>
      <div ref={wrapperRef} />
    </div>
  );
};
