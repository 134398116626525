import classNames from 'classnames';
import { useCallback, useMemo, useState } from 'react';
import { MGallery } from '../../../../horizon-components/MGallery/MGallery';
import { Badge } from '../../../../horizon-components/Badge';
import { useTranslation } from 'react-i18next';
import { BidTime } from '../../../../widgets/bid-time';
import { MIN_INCREMENT } from '../../constants';
import { ReactComponent as Clock } from '../../../../assets/icons/auction/clock-fill.svg';
import { ReactComponent as Coin } from '../../../../assets/icons/auction/er.svg';
import { useAuctionContext } from '../../../../providers/AuctionProvider/AuctionProvider';
import { useBidServiceContext } from '../../../../providers/BidServiceProvider/BidServiceProvider';

interface AuctionViewPosterProps {
  className?: string;
}

export const AuctionViewPoster = ({ className }: AuctionViewPosterProps) => {
  const { t } = useTranslation(['translation']);
  const { fetchBidsCommonInfo } = useBidServiceContext();
  const { auction, auctionStatus, fetchAuction, onTimeChange } = useAuctionContext();

  const images = useMemo(
    () => auction?.images?.map((item) => item.image) || [],
    [auction]
  );

  const onZeroTimer = useCallback(async () => {
    setTimeout(() => {
      onTimeChange();
      fetchBidsCommonInfo();
    }, 1000);
  }, [fetchAuction, auction]);

  if (!auction || !auctionStatus) {
    return null;
  }

  return (
    <div className={classNames('w-full', className)}>
      <div className="absolute z-10 flex flex-col items-start gap-2 p-3">
        {!!auction?.id && (
          <Badge
            variant="primary"
            size="xl"
            className="pointer-events-none"
            styles={{ backgroundColor: '#C9FBD5', color: '#006862' }}
          >
            <Clock className="!h-[18px] !w-[18px] !flex-none !flex-none stroke-0" />
            <BidTime
              data={auction}
              status={auctionStatus}
              onZeroTimer={onZeroTimer}
            />
          </Badge>
        )}
        {auction?.step !== 0 && (
          <Badge variant="primary" active size="xl" className="pointer-events-none">
            {t('bid.increment')} {MIN_INCREMENT}-{auction?.step}
            <Coin className="!h-[18px] !w-[18px] !flex-none !flex-none stroke-0" />
          </Badge>
        )}
        <Badge variant="primary" active size="xl" className="pointer-events-none">
          {auction?.number_winners} {t('bid.winners')}
        </Badge>
      </div>
      {!!images.length && <MGallery sources={images} />}
    </div>
  );
};
