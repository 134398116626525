/* eslint-disable @typescript-eslint/no-unused-vars */
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getEdresWalletsTransactions } from '../../api/auth';
import TransactionsImg from '../../assets/images/not-found/transactions.webp';
import { DatePicker } from '../../components/DatePicker';
import { Loader } from '../../components/Loader';
import { NotFound } from '../../components/NotFound/NotFound';
import useFetch from '../../hooks/useFetch';
import { WalletTable } from '../WalletTable';
import './style.scss';
import dayjs from 'dayjs';
import useInfiniteScroll from '../../hooks/useInfiniteScroll';
import { Wallet } from '../../models/Wallet';

interface DetailTransactionModalProps {
  className?: string;
}

export const DetailTransactionModal = ({
  className,
}: DetailTransactionModalProps) => {
  const { t } = useTranslation(['translation']);
  const [selectedDates, setSelectedDates] = useState<Date[]>([]);
  const [totalPagesCount, setTotalPagesCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [transactions, setTransactions] = useState<Wallet[]>([]);

  const { isLoading, fetchData: fetchTransactions } = useFetch((page: number = 1) =>
    getEdresWalletsTransactions(page)
  );

  const loadTransactions = async () => {
    if (totalPagesCount > currentPage && !isLoading) {
      const response = await fetchTransactions(currentPage + 1);

      if (!response) return;

      setCurrentPage(currentPage + 1);
      setTransactions([...transactions, ...response.results]);
      setTotalPagesCount(Math.ceil(response.count / 12));
    }
  };

  useEffect(() => {
    if (selectedDates.length === 2) {
      const arr = JSON.parse(JSON.stringify(selectedDates));
      const dates = {
        min: dayjs(new Date(arr[0])).format('YYYY-MM-DD'),
        max: dayjs(new Date(arr[1]).setDate(new Date(arr[1]).getDate() + 1)).format(
          'YYYY-MM-DD'
        ),
      };
      // fetchTransactions(dates.min, dates.max);
    }
  }, [selectedDates]);

  useEffect(() => {
    loadTransactions();
  }, []);

  const targetRef = useRef<HTMLDivElement>(null);
  useInfiniteScroll(targetRef, loadTransactions);
  const canLoadMore = totalPagesCount > currentPage;

  return (
    <>
      <div className={classNames('DetailTransactionModal', className)}>
        {!!transactions.length || isLoading ? (
          <>
            <WalletTable data={transactions} />
            {canLoadMore && (
              <>
                <div ref={targetRef} className="py-5" />
                <Loader />
              </>
            )}
          </>
        ) : (
          <NotFound
            text={t('notifications.You_dont_have_any_transactions')}
            img={TransactionsImg}
          />
        )}
      </div>
      <div className="mt-5 flex w-full items-center justify-center">
        {/* <DatePicker
          selectedDates={selectedDates}
          onDateChange={setSelectedDates}
          configs={{ dateFormat: 'dd.MM' }}
          className="!w-full !justify-center"
          placeholder={t('profile.Show_data_for_the_period')}
          defaultIsOpen
        /> */}
      </div>
    </>
  );
};
