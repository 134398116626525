import { FC } from 'react';
import { drawObjects } from './utils/drawing';

interface BlockObjectProps {
  ctx: CanvasRenderingContext2D;
  objects: Array<
    [number, number, number, number, string, boolean?, number?, number?]
  >;
  offsetX: number;
  offsetY: number;
  images: Record<string, CanvasImageSource>;
  globalMirror?: boolean; // Параметр для глобального отражения
}

const BlockObject: FC<BlockObjectProps> = ({
  ctx,
  objects,
  offsetX,
  offsetY,
  images,
  globalMirror = false,
}) => {
  if (globalMirror) {
    ctx.save(); // Сохраняем текущее состояние контекста
    ctx.scale(-1, 1); // Отзеркаливаем по оси X
    ctx.translate(-ctx.canvas.width, 0); // Сдвигаем контекст, чтобы отобразить отзеркаленные объекты
  }

  // Смещаем координаты объектов в зависимости от offsetX и offsetY
  const adjustedObjects = objects.map((obj) => {
    const [x, y, width, height, imagePath, mirror, rotation, zIndex] = obj;

    // Применяем смещение
    return [
      x + offsetX,
      y + offsetY,
      width,
      height,
      imagePath,
      mirror,
      rotation,
      zIndex,
    ] as [number, number, number, number, string, boolean?, number?, number?];
  });

  drawObjects(ctx, adjustedObjects, images);

  if (globalMirror) {
    ctx.restore();
  }

  return null;
};

export default BlockObject;
